import React, { useEffect } from "react";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";

import { AnalyticsEventKeys } from "../../../../constants/PosthogEvent.constants";
import { useTrack } from "../../../../hooks/analytics/useTrack";
import useCharacterList from "../../../hooks/useCharacterList.hook";
import CharacterCategory from "./CharacterCategory";
import CharacterDefinition from "./CharacterDefinition";
import CharacterDescription from "./CharacterDescription";
import CharacterGreeting from "./CharacterGreeting";
import CharacterImage from "./CharacterImage";
import CharacterName from "./CharacterName";
import CharacterTags from "./CharacterTags";
import CharacterTitle from "./CharacterTitle";
import CharacterVisibility from "./CharacterVisibility";
import CreateCharacterMenu from "./CreateCharacterMenu";

const CreateCharacter: React.FC = () => {
  const { createCharacterParams, setCreateCharacterParams } =
    useCharacterList();
  const [errors] = React.useState<Record<string, object>>({});
  const track = useTrack();

  useEffect(() => {
    track({
      name: AnalyticsEventKeys.CharacterCreation.ClickedCreateCharacter,
      properties: {},
    });
  }, [track]);

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box>
          <Text color="white" variant="24-reg">
            Create a character
          </Text>
          <Box height="4px" />
          <Text color="gray.400" variant="14-reg">
            Fill out the fields below to get started
          </Text>
        </Box>
      </Box>
      <Box margin="24px 0px 32px">
        <Divider backgroundColor="gray.800" height="1px" />
      </Box>
      <CharacterName
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />
      <Flex height="24px" />
      <CharacterImage
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />
      <Flex height="24px" />
      <CharacterTitle
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />
      <Flex height="24px" />
      <CharacterDescription
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />
      <Flex height="24px" />
      <CharacterGreeting
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />

      <Flex height="24px" />
      <CharacterDefinition
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />
      <Flex height="24px" />
      <CharacterVisibility
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />
      <Flex height="24px" />
      <CharacterCategory
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />
      <Flex height="24px" />
      <CharacterTags
        errors={errors}
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
      />

      <Box margin="24px 0px 32px">
        <Divider backgroundColor="gray.800" height="1px" />
      </Box>
      <CreateCharacterMenu />
      <Box height="300px" />
    </>
  );
};

export default CreateCharacter;

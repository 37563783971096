import React from "react";
import { Flex } from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacterList from "../../../hooks/useCharacterList.hook";
import type useCharacter from "~/ui/hooks/useCharacter.hook";
import Textarea from "../../../elements/Textarea";

type CharacterDescriptionProps = {
  params: CreateCharacterParams | UpdateCharacterParams;
  setParams:
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"]
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"];
  errors: { description?: string };
};

const CharacterDescription: React.FC<CharacterDescriptionProps> = ({
  errors,
  params,
  setParams,
}) => {
  return (
    <Flex direction="column">
      <Textarea
        isInvalid={errors.description ? true : false}
        label="Description"
        onChange={(e) => {
          const description = e.currentTarget.value;
          // @ts-expect-error - need better types here.
          void setParams((params) => {
            return {
              ...params,
              description: description,
            };
          });
        }}
        placeholder="How would your character describe themselves?"
        size="lg"
        value={params.description ?? ""}
      />
    </Flex>
  );
};

export default CharacterDescription;

import { Box, Spacer, Text } from "@chakra-ui/react";

import { withSuspense } from "~/ui/hoc/withSuspense";
import MarketingLayout from "~/ui/layouts/Marketing.layout";

type TermsPageProps = {
  hideLayout?: boolean;
};

const H2 = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize="xl" fontWeight="bold" margin="32px 0 16px">
    {children}
  </Text>
);

const H3 = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize="lg" fontWeight="bold" margin="32px 0 16px">
    {children}
  </Text>
);

const H4 = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize="md" fontWeight="bold" margin="16px 0">
    {children}
  </Text>
);

const TermsPage = ({ hideLayout = false }: TermsPageProps) => {
  const Content = (
    <Box>
      <Spacer h={2} />
      <Text fontSize="2xl" fontWeight="bold" margin="32px 0 16px">
        TERMS OF SERVICE
      </Text>
      <Text marginBottom="16px">
        These Terms of Service (these "Terms") describe the terms and conditions
        by which you may access and/or use the website(s), including
        https://charry.ai and any successor URL(s), and any and all related
        software, documentation, and online, mobile-enabled, and/or digital
        services (collectively, the "Service") provided by Open Rates, LCC,
        sometimes doing business as Charry (including its successors and
        assigns, "Open Rates," "Charry," "we," "our," or "us"). By accessing
        and/or using the Service, or by clicking a button or checking a box
        marked "I Agree" (or something similar), you signify that you have read,
        understood, and agree to be bound by these Terms, and you acknowledge
        that you have read and understood our Privacy Policy, as further
        described in Section 9.1 (Privacy). We reserve the right to modify these
        Terms, and we will provide notice of material changes as described
        below. These Terms apply to all visitors and users of the Service, and
        to all others who access the Service (collectively, "Users," and, as
        applicable to you, "you" or "your").
      </Text>
      <Text marginBottom="16px">
        PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH
        PROVISION. THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION
        PROVISION IN SECTION 16.2 (THE "ARBITRATION AGREEMENT") AND A CLASS
        ACTION/JURY TRIAL WAIVER PROVISION IN SECTION 16.3 (THE "CLASS
        ACTION/JURY TRIAL WAIVER") THAT REQUIRE, UNLESS YOU OPT OUT PURSUANT TO
        THE INSTRUCTIONS IN THE ARBITRATION AGREEMENT, THE EXCLUSIVE USE OF
        FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES
        BETWEEN YOU AND US, INCLUDING ANY CLAIMS THAT AROSE OR WERE ASSERTED
        BEFORE YOU AGREED TO THESE TERMS. TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW (AS DEFINED BELOW), YOU EXPRESSLY WAIVE YOUR RIGHT TO
        SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS,
        AS WELL AS YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN
        ANY CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL, OR REPRESENTATIVE
        ACTION OR PROCEEDING.
      </Text>
      <H2>1. How We Administer the Service</H2>
      <H3>1.1 Eligibility</H3>
      <Text marginBottom="16px">
        This is a contract between you and Charry. You must read and agree to
        these Terms before using the Service. If you do not agree, you may not
        use the Service. You may use the Service only if you can form a legally
        binding contract with us, and only in compliance with these Terms and
        all applicable local, state, national, and international laws, rules,
        and regulations ("Applicable Law"). Without limiting the generality of
        the foregoing, any access to, or use of, the Service by anyone who is a
        minor (which is under the age of 18 in most jurisdictions) in any
        applicable jurisdiction (a "Minor") is strictly prohibited and in
        violation of these Terms, unless such access and/or use is supervised by
        a parent or legal guardian ("Parent") who: (a) has read and understands
        these Terms; (b) approves all rights granted, and all obligations
        undertaken, by the Minor hereunder; and (c) agrees to be bound by these
        Terms. If you are a Parent of a User who is a Minor, then, by allowing
        such Minor to access and/or use the Service, you are subject to these
        Terms and responsible for such Minor's activity on the Service. With
        respect to the Parent of a User who is a Minor, "you," as used in these
        Terms in the context of a license grant, assignment, restriction,
        obligation, acknowledgment, representation, warranty, or covenant, or in
        any similar context, means "the Parent, on behalf of the Parent and the
        Minor," and "your" has the corresponding meaning. The Service is not
        available to any Users we previously removed from the Service.
      </Text>
      <H3>1.2 User Accounts</H3>
      <H4>a) Your User Account</H4>
      <Text marginBottom="16px">
        Your account on the Service (your "User Account") gives you access to
        certain services and functionalities that we may, in our sole
        discretion, establish and maintain as part of the Service from time to
        time. You acknowledge that, notwithstanding anything to the contrary
        herein, you do not own your User Account, nor do you possess any rights
        to data stored by or on behalf of Charry on the servers running the
        Service. We may maintain different types of User Accounts for different
        types of Users.
      </Text>
      <H4>b) Organizational Accounts</H4>
      <Text marginBottom="16px">
        An individual may access and/or use the Service on behalf of a company
        or other entity, such as that individual's employer (such entity, an
        "Organization"). In such cases, notwithstanding anything to the contrary
        herein: (a) these Terms are an agreement between (i) us and such
        individual and (ii) us and that Organization; (b) "you," as used in
        these Terms in the context of a license grant, assignment, restriction,
        obligation, acknowledgment, representation, warranty, or covenant, or in
        any similar context, means (i) such individual and (ii) "the
        Organization, on behalf of the Organization and its subsidiaries and
        affiliates, and its and their respective directors, officers, employees,
        contractors, agents, and other representatives who access and/or use the
        Service (collectively, "Org Users")"; and "your" has the corresponding
        meanings; (c) such individual represents and warrants to having the
        authority to bind that Organization to these Terms (and, in the absence
        of such authority, such individual may not access, nor use, the
        Service); (d) such individual's acceptance of these Terms will bind that
        Organization to these Terms; (e) we may disclose information regarding
        such individual and such individual's access to and use of the Service
        to that Organization; (f) such individual's right to access and use the
        Service may be suspended or terminated (and the administration of the
        applicable User Account may be transferred) if such individual ceases to
        be associated with, or ceases to use an email address associated with or
        provisioned by, that Organization; (g) that Organization will make all
        Org Users aware of these Terms' provisions, as applicable to such Org
        Users, and will cause each Org User to comply with such provisions; and
        (h) that Organization will be solely responsible and liable for all acts
        and omissions of the Org Users, and any act or omission by any Org User
        that would constitute a breach of these Terms had it been taken by that
        Organization will be deemed a breach of these Terms by that
        Organization. Without limiting the generality of the foregoing, if an
        individual opens a User Account using an email address associated with
        or provisioned by an Organization, or if an Organization pays fees due
        in connection with such individual's access to or use of the Service (or
        reimburses such individual for payment of such fees), then we may, in
        our sole discretion, deem such individual to be accessing and using the
        Service on behalf of that Organization.
      </Text>
      <H4>c) Connecting Via Third-Party Services</H4>
      <Text marginBottom="16px">
        By connecting to the Service via a third-party service, you give us
        permission to access and use your information from that service, as
        permitted by that service, and to store your log-in credentials and/or
        access tokens for that service.
      </Text>
      <H4>d) Account Security</H4>
      <Text marginBottom="16px">
        You may never use another User's User Account without such User's
        permission. When creating your User Account, you must provide accurate
        and complete information, and you must keep this information up to date.
        You are solely responsible for the activity that occurs on your User
        Account, you will keep your User Account password(s) and/or any other
        authentication credentials secure, and you will not share your
        password(s) and/or any other authentication credentials with anyone
        else. We encourage you to use "strong" passwords (passwords that use a
        combination of upper- and lower-case letters, numbers, and symbols) to
        protect your User Account. We will not be liable for, and expressly
        disclaim liability for, any losses caused by any unauthorized use of
        your User Account and/or any changes to your User Account, including,
        without limitation, changes made by any Org User with
        administrator-level access to your User Account. You will notify us
        immediately of any breach of security or unauthorized use of your User
        Account.
      </Text>
      <H4>e) Notifications; Emails</H4>
      <Text marginBottom="16px">
        By providing us with your email address, you consent to our using that
        email address to send you Service-related notices, including any notices
        required by Applicable Law, in lieu of communication by postal mail.
      </Text>
      <H3>1.3 Changes, Suspension, and Termination</H3>
      <Text marginBottom="16px">
        You may de-activate your User Account at any time. We may, with or
        without prior notice, change the Service, stop providing the Service or
        features of the Service to you or to Users generally, or create usage
        limits for the Service. We may, with or without prior notice,
        permanently terminate or temporarily suspend your access to your User
        Account and/or the Service without liability, with or without cause, and
        for any or no reason, including if, in our sole determination, you
        violate any provision of these Terms. Upon their termination for any
        reason or no reason, you continue to be bound by these Terms.
      </Text>
      <H3>1.4 Your Interactions with Other Users</H3>
      <Text marginBottom="16px">
        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS, INCLUDING SHARING OF
        INFORMATION, WITH OTHER USERS. WE RESERVE THE RIGHT, BUT HAVE NO
        OBLIGATION, TO MONITOR DISPUTES BETWEEN YOU AND OTHER USERS. WE
        EXPRESSLY DISCLAIM ALL LIABILITY ARISING FROM YOUR INTERACTIONS WITH
        OTHER USERS, AND FOR ANY USER'S ACTION OR INACTION, INCLUDING RELATING
        TO USER CONTENT (AS DEFINED BELOW).
      </Text>
      <H2>2. Access to the Service; Service Restrictions</H2>
      <H3>2.1 Access to the Service</H3>
      <Text marginBottom="16px">
        Subject to your compliance with these Terms and any documentation we may
        make available to you, you are hereby granted a non-exclusive, limited,
        non-transferable, and freely revocable right to access and use the
        Service, solely for your personal use or internal business purposes, as
        applicable, strictly as permitted by the features of the Service. We
        reserve all rights not expressly granted herein in and to the Service.
        Notwithstanding anything to the contrary herein, certain portions of the
        Service may be available only during the Subscription Term(s) (as
        defined below), as further described in Section 8.4 (Subscription
        Plans).
      </Text>
      <H3>2.2 Restrictions and Acceptable Use</H3>
      <Text marginBottom="16px">
        Except to the extent a restriction is prohibited by Applicable Law, you
        will not do, and will not assist, permit, or enable any third party to
        do, any of the following:
      </Text>
      <Text marginBottom="16px">
        a) disassemble, reverse engineer, decode, or decompile any part of the
        Service;
      </Text>
      <Text marginBottom="16px">
        b) use any robot, spider, scraper, off-line reader, data mining tool,
        data gathering or extraction tool, or any other automated means to
        access the Service in a manner that sends more request messages to the
        servers running the Service than a human can reasonably produce in the
        same period of time by using a conventional on-line web browser (except
        that Charry grants the operators of public search engines revocable
        permission to use spiders to copy publicly available materials from the
        Service for the sole purpose of, and solely to the extent necessary for,
        creating publicly available searchable indices of, but not caches or
        archives of, such materials);
      </Text>
      <Text marginBottom="16px">
        c) use any content available on or via the Service (including any
        caption information, keywords, or other metadata) for any machine
        learning and/or artificial intelligence training or development
        purposes, or for any technologies designed or intended for the
        identification of natural persons;
      </Text>
      <Text marginBottom="16px">
        d) buy, sell or transfer API keys without our prior written consent in
        each case;
      </Text>
      <Text marginBottom="16px">
        e) copy, rent, lease, sell, loan, transfer, assign, license or purport
        to sublicense, resell, distribute, modify, alter, or create derivative
        works of any part of the Service or any of our Intellectual Property (as
        defined below), including, without limitation by any automated or
        non-automated "scraping";
      </Text>
      <Text marginBottom="16px">
        f) use the Service in any manner that impacts (i) the stability of the
        servers running the Service, (ii) the operation or performance of the
        Service or any User's use of the Service, or (iii) the behavior of other
        applications that use the Service;
      </Text>
      <Text marginBottom="16px">
        g) take any action that imposes, or may impose (as determined by us, in
        our sole discretion), an unreasonable or disproportionately large load
        on our infrastructure;
      </Text>
      <Text marginBottom="16px">
        h) use the Service in any manner or for any purpose that (i) violates,
        or promotes the violation of, any Applicable Law, contractual
        obligation, or right of any person, including, but not limited to,
        Intellectual Property Rights (as defined below), privacy rights, and/or
        rights of personality, (ii) is fraudulent, false, deceptive, or
        defamatory, (iii) promotes hatred, violence, or harm against any
        individual or group, or (iv) otherwise may be harmful or objectionable
        (in our sole discretion) to us or to our providers, our suppliers,
        Users, or any other third party;
      </Text>
      <Text marginBottom="16px">
        i) use or display the Service in competition with us, to develop
        competing products or services, for benchmarking or competitive analysis
        of the Service, or otherwise to our detriment or disadvantage;
      </Text>
      <Text marginBottom="16px">
        j) access any content available on or via the Service through any
        technology or means other than those provided by the Service or
        authorized by us;
      </Text>
      <Text marginBottom="16px">
        k) bypass the measures we may use to prevent or restrict access to the
        Service, including, without limitation, features that prevent or
        restrict use or copying of any content or that enforce limitations on
        use of the Service or any portion thereof;
      </Text>
      <Text marginBottom="16px">
        l) attempt to interfere with, compromise the system integrity or
        security of, or decipher any transmissions to or from, the servers
        running the Service;
      </Text>
      <Text marginBottom="16px">
        m) use the Service to transmit spam, chain letters, or other unsolicited
        email;
      </Text>
      <Text marginBottom="16px">
        n) use the Service for any commercial solicitation purposes;
      </Text>
      <Text marginBottom="16px">
        o) transmit invalid data, viruses, worms, or other software agents
        through the Service;
      </Text>
      <Text marginBottom="16px">
        p) impersonate another person or entity, misrepresent your affiliation
        with a person or entity, hide or attempt to hide your identity, or
        otherwise use the Service for any invasive or fraudulent purpose;
      </Text>
      <Text marginBottom="16px">
        q) collect or harvest any personal information, including Users' names,
        from the Service; or
      </Text>
      <Text marginBottom="16px">
        r) identify or refer to us or to the Service in a manner that could
        reasonably imply a relationship that involves endorsement, affiliation,
        or sponsorship between you (or a third party) and us without our prior
        express written consent.
      </Text>
      <H2>3. User Content</H2>
      <H3>3.1 General</H3>
      <Text marginBottom="16px">
        The Service may allow Users to submit, post, display, provide, share, or
        otherwise make available on or via the Service content such as profile
        information, videos, images, music, comments, questions, and other
        content, data, and/or information (collectively, excluding Usage Data
        and Feedback (as each of those terms is defined below), "User Content").
        If you are an Org User, then we may, in our sole discretion, assume that
        all of your User Content belongs to the applicable Organization.
      </Text>
      <H3>3.2 Rights to User Content</H3>
      <Text marginBottom="16px">
        WE CLAIM NO OWNERSHIP RIGHTS OVER USER CONTENT. As between you and us,
        all User Content that is submitted, posted, displayed, provided, shared,
        or otherwise made available on or via the Service by you is and will
        remain yours. The Service may provide mechanisms for users to share User
        Content (such as models and deployments) and Output (as defined below)
        so other Users may use them. By default, this sharing is off and must be
        explicitly turned on if so desired. If you do turn on User Content and
        Output sharing within the Service, you understand that, per the below
        license grant to other Users, certain functionalities of the Service may
        allow other Users to view, edit, share, and/or otherwise interact with
        your User Content and/or your Output. We have the right (but not the
        obligation) to remove any User Content or Output, at our sole
        discretion. By submitting, posting, displaying, providing, sharing, or
        otherwise making available any User Content or Output on or through the
        Service, you hereby expressly grant, and you represent and warrant that
        you have all rights necessary to grant, to Charry a fully paid,
        royalty-free, transferable, perpetual, irrevocable, non-exclusive, and
        worldwide license, with the right to grant and authorize sublicenses, to
        use, copy, reproduce, store, modify, publish, list information
        regarding, edit, translate, distribute, syndicate, publicly perform,
        publicly display, and make derivative works of all such User Content and
        Output and your name, voice, and likeness as contained in your User
        Content, in whole or in part, and in any form, media, or technology,
        whether now known or hereafter developed, for use in connection with the
        Service and Charry's (and its subsidiaries' and affiliates') business,
        including, without limitation, for promoting and redistributing part or
        all of the Service (and derivative works thereof) in any media formats
        and through any media channels, and to perform such other actions as
        described in our Privacy Notice or as authorized by you in connection
        with your use of the Service. If you enable sharing within the Service,
        you also hereby grant each other User a non-exclusive license to access
        your User Content and Output through the Service, and to use, reproduce,
        distribute, display, edit, perform, and otherwise interact with such
        User Content and Output, in each case in accordance with the Service's
        functionalities and these Terms.
      </Text>
      <H3>3.3 User Content Representations and Warranties</H3>
      <Text marginBottom="16px">
        In connection with your User Content, you affirm, represent, and warrant
        the following:
      </Text>
      <Text marginBottom="16px">
        a) you have the written consent of each and every identifiable natural
        person referred to or mentioned in the User Content, if any, to use such
        person's name, voice, and likeness in the manner contemplated by the
        Service and these Terms, and each such person has released you from any
        liability that may arise in relation to such use;
      </Text>
      <Text marginBottom="16px">
        b) you have obtained, and are solely responsible for obtaining, all
        consents required by Applicable Law to provide User Content relating to
        third parties;
      </Text>
      <Text marginBottom="16px">
        c) your User Content and Output and our use thereof as contemplated by
        these Terms and the Service will not violate any Applicable Law or
        infringe any rights of any third party, including, but not limited to,
        any Intellectual Property Rights and privacy rights;
      </Text>
      <Text marginBottom="16px">
        d) your User Content does not include any information or material that a
        governmental body deems to be sensitive or classified information, and
        your provision of User Content in connection with the Service is not
        violative of any confidentiality rights of any third party;
      </Text>
      <Text marginBottom="16px">
        e) we may exercise the rights to your User Content granted to us under
        these Terms without liability for payment of any guild or other fees,
        residuals, payments, or royalties payable under any collective
        bargaining agreement or otherwise;
      </Text>
      <Text marginBottom="16px">
        f) You will not upload or make available through the Service, either
        directly or by other means: any personal information of children under
        13 or the applicable age of digital consent;
      </Text>
      <Text marginBottom="16px">
        g) your User Content does not include nudity or other sexually
        suggestive content; hate speech, threats, or direct attacks on an
        individual or group; content that is abusive, harassing, tortious,
        defamatory, vulgar, obscene, libelous, or invasive of another's privacy;
        sexist or racially, ethnically, or otherwise discriminatory content;
        content that contains self-harm or excessive violence; fake or impostor
        profiles; illegal content or content in furtherance of harmful or
        illegal activities; malicious programs or code; any person's personal
        information without such person's consent; spam, machine-generated
        content, or unsolicited messages; and/or otherwise objectionable
        content; and
      </Text>
      <Text marginBottom="16px">
        h) to the best of your knowledge, all User Content and other information
        that you provide to us is truthful and accurate.
      </Text>
      <H3>3.4 Disclaimer</H3>
      <Text marginBottom="16px">
        WE TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY USER CONTENT.
        YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USER CONTENT AND THE
        CONSEQUENCES OF SUBMITTING, POSTING, DISPLAYING, PROVIDING, SHARING, OR
        OTHERWISE MAKING IT AVAILABLE ON OR THROUGH THE SERVICE, AND YOU
        UNDERSTAND AND ACKNOWLEDGE THAT WE ARE ACTING ONLY AS A PASSIVE CONDUIT
        FOR YOUR ONLINE DISTRIBUTION AND PUBLICATION OF YOUR USER CONTENT.
      </Text>
      <H2>4. Our Intellectual Property</H2>
      <H3>4.1 Intellectual Property Rights Definition</H3>
      <Text marginBottom="16px">
        For the purposes of these Terms, "Intellectual Property Rights" means
        all patent rights, copyright rights, mask work rights, moral rights,
        rights of personality, trademark, trade dress and service mark rights,
        goodwill, trade secret rights, and any and all other intellectual
        property rights and proprietary rights as may now exist or hereafter
        come into existence, and all applications therefor and registrations,
        renewals, and extensions thereof, under Applicable Law.
      </Text>
      <H3>4.2 Charry Intellectual Property</H3>
      <Text marginBottom="16px">
        You understand and acknowledge that we (or our licensors (including
        other Users), as applicable) own and will continue to own all rights
        (including Intellectual Property Rights), title, and interest in and to
        the Service, all materials and content displayed or otherwise made
        available on and/or through the Service (including, without limitation,
        images, text, graphics, illustrations, logos, photographs, audio,
        videos, music, and User Content belonging to other Users; but, excluding
        your User Content), and all software, algorithms, code, technology, and
        intellectual property underlying and/or included in or with the Service
        (collectively and individually, "Intellectual Property"). Except as may
        be explicitly provided herein, nothing in these Terms will be deemed to
        create a license in or under any such Intellectual Property Rights, and
        you will not access, sell, license, rent, modify, distribute, copy,
        reproduce, transmit, display, perform, publish, adapt, edit, or create
        derivative works of any Intellectual Property. Use of any Intellectual
        Property for any purpose not expressly permitted by these Terms is
        strictly prohibited.
      </Text>
      <H3>4.3 Output</H3>
      <Text marginBottom="16px">
        Subject to your compliance with these Terms, you may use your output of
        the Service ("Output") for any lawful purpose (except as described
        below), on a royalty-free basis, provided that you acknowledge and
        agree: (i) that your use of the Service and the Output does not transfer
        to you ownership of any Intellectual Property Rights in the Service and
        that (ii) we may, by notice to you at any time, limit your use of the
        Output or require you to cease using them (and delete any copies of
        them) if we form the view, in our sole and absolute discretion, that
        your use of the Output may infringe the rights of any third party. You
        shall not (i) represent that Output was human-generated or (ii) violate
        any of the model provider's license and usage restrictions.
      </Text>
      <Text marginBottom="16px">
        DUE TO THE NATURE OF AI AND MACHINE LEARNING, THE OUTPUT MAY NOT BE
        UNIQUE ACROSS USERS AND THE SERVICE MAY GENERATE THE SAME OR SIMILAR
        OUTPUT FOR OTHER USERS. USE OF THE SERVICE MAY RESULT IN INCORRECT
        OUTPUT THAT DOES NOT ACCURATELY REFLECT REALITY. YOU MUST EVALUATE THE
        ACCURACY OF ANY OUTPUT AS APPROPRIATE FOR YOUR USE CASE, INCLUDING BY
        USING HUMAN REVIEW OF THE OUTPUT. YOU UNDERSTAND AND AGREE THAT THE
        OUTPUT MAY CONTAIN "HALLUCINATIONS" AND MAY BE INACCURATE,
        OBJECTIONABLE, INAPPROPRIATE, OR OTHERWISE UNSUITED TO YOUR PURPOSE, AND
        YOU AGREE THAT WE SHALL NOT BE LIABLE FOR ANY DAMAGES YOU OR ANY THIRD
        PARTY ALLEGES TO INCUR AS A RESULT OF OR RELATING TO ANY OUTPUT OR OTHER
        CONTENT GENERATED BY OR ACCESSED ON OR THROUGH OUR SERVICE.
      </Text>
      <H3>4.4 Usage Data</H3>
      <Text marginBottom="16px">
        We may collect, or you may provide to us, diagnostic, technical, usage,
        and/or related information, including information about your computers,
        mobile devices, systems, and software (collectively, "Usage Data"). All
        Usage Data is and will be owned solely and exclusively by us, and, to
        the extent any ownership rights in or to the Usage Data vest in you, you
        hereby assign to us all rights (including Intellectual Property Rights),
        title, and interest in and to the same. Accordingly, we may use,
        maintain, and/or process the Usage Data or any portion thereof for any
        lawful purpose, including, without limitation: (a) to provide and
        maintain the Service; (b) to improve our products and services
        (including the Service), and to develop new products, services, and/or
        features; (c) to monitor your usage of the Service; (d) for research and
        analytics, including, without limitation, data analysis, identifying
        usage trends, and/or customer research; and (e) to share analytics and
        other derived Usage Data with third parties, solely in de-identified or
        aggregated form. The Service may contain technological measures designed
        to prevent unauthorized or illegal use of the Service; you understand
        and acknowledge that we may use these and other lawful measures to
        verify your compliance with these Terms and to enforce our rights,
        including Intellectual Property Rights, in and to the Service.
      </Text>
      <H3>4.5 Feedback</H3>
      <Text marginBottom="16px">
        To the extent you provide us any suggestions, recommendations, or other
        feedback relating to the Service or to any other Charry products or
        services (collectively, "Feedback"), you hereby assign to us all rights
        (including Intellectual Property Rights), title, and interest in and to
        the Feedback. Accordingly, we are free to use the Feedback and any
        ideas, know-how, concepts, techniques, and/or other intellectual
        property contained in the Feedback, without providing any attribution or
        compensation to you or to any third party, for any purpose whatsoever,
        although we are not required to use any Feedback. Feedback is deemed our
        Confidential Information (as defined below). You acknowledge that, by
        acceptance of your submission of Feedback, we do not waive any rights to
        use similar or related ideas previously known to us, or developed by our
        employees, or obtained from sources other than you.
      </Text>
      <H2>5. Confidential Information</H2>
      <Text marginBottom="16px">
        The Service may include non-public, proprietary, or confidential
        information of Charry and/or of other Users ("Confidential
        Information"). Confidential Information includes any information that
        should reasonably be understood to be confidential given the nature of
        the information and the circumstances of disclosure, including
        non-public business, product, technology, and marketing information. You
        will: (a) protect and safeguard the confidentiality of all Confidential
        Information with at least the same degree of care as you would use
        protect your own highly sensitive confidential information, but in no
        event with less than a reasonable degree of care; (b) not use any
        Confidential Information for any purpose other than to exercise your
        rights, or to perform your obligations, under these Terms; and (c) not
        disclose any Confidential Information to any person or entity, except
        your service providers or financial or legal advisors who/that (i) need
        to know the Confidential Information and (ii) are bound by non-use and
        non-disclosure restrictions at least as restrictive as those set forth
        in this Section.
      </Text>
      <H2>6. DMCA Notice</H2>
      <Text marginBottom="16px">
        We respect artists and content owner rights, and it is our policy to
        respond to alleged infringement notices that comply with the Digital
        Millennium Copyright Act of 1998 (as it may be amended, "DMCA").
      </Text>
      <Text marginBottom="16px">
        If you believe that your copyrighted work has been copied in a way that
        constitutes copyright infringement and is accessible via the Service,
        please notify our copyright agent as set forth in the DMCA. For your
        complaint to be valid under the DMCA, you must provide all of the
        following information in writing:
      </Text>
      <Text marginBottom="16px">
        a) an electronic or physical signature of a person authorized to act on
        behalf of the copyright owner;
      </Text>
      <Text marginBottom="16px">
        b) identification of the copyrighted work that you claim has been
        infringed;
      </Text>
      <Text marginBottom="16px">
        c) identification of the material that is claimed to be infringing and
        its location on the Service;
      </Text>
      <Text marginBottom="16px">
        d) information reasonably sufficient to permit us to contact you, such
        as your address, telephone number, and email address;
      </Text>
      <Text marginBottom="16px">
        e) a statement that you have a good faith belief that use of the
        material in the manner complained of is not authorized by the copyright
        owner, its agent, or law; and
      </Text>
      <Text marginBottom="16px">
        f) a statement, made under penalty of perjury, that the above
        information is accurate, and that you are the copyright owner or are
        authorized to act on behalf of the owner.
      </Text>
      <Text marginBottom="16px">
        The above information must be submitted to our DMCA Agent, using the
        following contact information:
      </Text>
      <Text marginBottom="16px">
        Attn:
        <br />
        DMCA Notice
        <br />
        Open Rates, LCC
        <br />
        Address:
        <br />
        8 California Street, Suite 200
        <br />
        San Francisco, CA 94111
        <br />
        Email: legal@charry.ai
      </Text>
      <Text marginBottom="16px">
        Under United States federal law, if you knowingly misrepresent that
        online material is infringing, you may be subject to criminal
        prosecution for perjury and civil penalties, including monetary damages,
        court costs, and attorneys' fees.
      </Text>
      <Text marginBottom="16px">
        Please note that the procedure outlined herein is exclusively for
        notifying Charry and its affiliates that your copyrighted material has
        been infringed. The preceding requirements are intended to comply with
        Charry's rights and obligations under the DMCA, including 17 U.S.C.
        §512(c), but do not constitute legal advice. It may be advisable to
        contact an attorney regarding your rights and obligations under the DMCA
        and other Applicable Law.
      </Text>
      <Text marginBottom="16px">
        In accordance with the DMCA and other Applicable Law, we have adopted a
        policy of terminating, in appropriate circumstances, Users who are
        deemed to be repeat infringers. We may also, at our sole discretion,
        limit access to the Service and/or terminate the User Accounts of any
        Users who infringe any Intellectual Property Rights of others, whether
        or not there is any repeat infringement.
      </Text>
      <H2>7. Our Publicity Rights</H2>
      <Text marginBottom="16px">
        We may identify you as a User in our promotional materials. We will
        promptly stop doing so upon receipt of your request sent to
        legal@charry.ai.
      </Text>
      <H2>8. Payments, Billing, and Subscription Plans</H2>
      <H3>8.1 Billing Policies; Taxes</H3>
      <Text marginBottom="16px">
        Certain aspects of the Service may be provided for free, while certain
        other aspects of the Service and/or products available on the Service
        may be provided for a fee or other charge ("Fee"). Each Fee (including
        each Subscription Fee (as defined below)) is the sum of the applicable
        Charry Fee (as defined below) and any applicable Third-Party Fees (as
        defined below). By electing to use non-free aspects of the Service,
        including enrolling in Subscription(s) (as defined below), you agree to
        the pricing and payment terms applicable to you and available at
        https://charry.ai/pricing (as we may update them from time to time, the
        "Pricing and Payment Terms"). The Pricing and Payment Terms are hereby
        incorporated by reference herein. We may add new products and/or
        services for additional Fees, add or amend Fees for existing products
        and/or services, and/or discontinue offering any Subscriptions at any
        time, in our sole discretion; provided, however, that if we have agreed
        to a specific Subscription Term and a corresponding Subscription Fee,
        then that Subscription will remain in force for that Subscription Fee
        during that Subscription Term. Any change to the Pricing and Payment
        Terms will become effective in the billing cycle following our provision
        of notice of such change. Except as may be expressly stated in these
        Terms or in the Pricing and Payment Terms, all Fees must be paid in
        advance, payment obligations are non-cancelable once incurred (subject
        to any cancellation rights set forth in these Terms), and Fees paid are
        non-refundable. Fees are stated exclusive of any taxes, levies, duties,
        or similar governmental assessments of any nature, including, for
        example, value-added, sales, use, and withholding taxes, assessable by
        any jurisdiction (collectively, but, for clarity, excluding taxes based
        on our net income, "Taxes"). You will be responsible for paying all
        Taxes associated with your purchases and/or Subscriptions in connection
        with the Service.
      </Text>
      <H3>8.2 Definitions</H3>
      <Text marginBottom="16px">
        a) "Subscription" means a particular portion of the Service that is
        available on an automatically renewing subscription basis, and your
        access thereto, as applicable.
      </Text>
      <Text marginBottom="16px">
        b) "Subscription Fee" means the recurring amount due as consideration
        for a Subscription; a Subscription Fee is one type of Fee.
      </Text>
      <Text marginBottom="16px">
        c) "Charry Fee" means the portion of the Fee (including any Subscription
        Fee) that Charry may retain as consideration for providing the Service
        or any portion thereof (including any particular Subscription), as
        applicable.
      </Text>
      <Text marginBottom="16px">
        d) "Third-Party Fees" means the portion of the Fee (including any
        Subscription Fee) retained by one (1) or more third parties, including
        Payment Processor, that we may engage from time to time, in our sole
        discretion.
      </Text>
      <Text marginBottom="16px">
        e) "Payment Processor" means the third-party payment processor,
        currently Stripe, which we engage to process payments Users make in
        connection with the Service.
      </Text>
      <H3>8.3 Your Payment Method</H3>
      <H4>a) General</H4>
      <Text marginBottom="16px">
        To use non-free aspects of the Service, you must provide us with at
        least one (1) current, valid payment card that is accepted by us and
        Payment Processor (each such card, a "Payment Method"). By providing a
        Payment Method, you authorize each of Charry and Payment Processor to
        charge that Payment Method the applicable Fees and Taxes, including, if
        applicable, on a recurring basis until you cancel your Subscription
        (including any notice period specified in Section 8.4(c) (Cancellation
        Procedures)). Fees and Taxes will be charged to your Payment Method on
        the specific payment date indicated in your User Account. In some cases,
        your payment date may change, for example, if your Payment Method has
        not successfully settled, if you changed your Subscription plan, or if
        your Subscription began on a date not contained in a subsequent month.
        The length of your billing cycle will depend on the type of Subscription
        in which you are enrolled, if applicable. Fees are fully earned upon
        payment. We may authorize your Payment Method in anticipation of
        Service-related charges through various methods, including authorizing
        it up to one (1) month of service as soon as you register for the
        Service.
      </Text>
      <H4>b) Third-Party Payment Processor</H4>
      <Text marginBottom="16px">
        We or Payment Processor will attempt to verify your Payment Method(s),
        and may do so by processing an authorization hold, which is standard
        practice. To the extent Payment Processor processes payments made by
        you, you will be subject to terms and conditions governing the use of
        Payment Processor's service. Please review such terms and conditions as
        well as Payment Processor's privacy notice (each of which is available
        on Payment Processor's website). You acknowledge and understand that
        Payment Processor may collect and retain Third-Party Fees whenever you
        pay Fees (including Subscription Fees). Payment must be received by
        Payment Processor before our acceptance of an order. We do not view or
        store your full credit card or other Payment Method information. For all
        payments, Payment Processor will collect your Payment Method details and
        charge your chosen Payment Method in connection with an order. If any of
        your account, order, or Payment Method information changes, you will
        promptly update such information, so that we or Payment Processor may
        complete your transaction(s) and/or contact you, as needed.
      </Text>
      <H4>c) Payment Representations and Warranties</H4>
      <Text marginBottom="16px">
        You represent and warrant that: (i) the account, order, and Payment
        Method information you supply to us and/or to Payment Processor, as
        applicable, is true, accurate, correct, and complete; (ii) you are duly
        authorized to use the Payment Method(s); (iii) you will pay any and all
        charges incurred by users of your Payment Method in connection with the
        Service, including any applicable Fees (at the prices in effect when
        such charges are incurred) and Taxes; (iv) charges incurred by you will
        be honored by your Payment Method company; (v) you will not allow or
        enable anyone else to use your Subscription (including, without
        limitation, by sharing your password(s) or any other authentication
        credentials with anyone else, or by attempting to transfer your
        Subscription to anyone else); and (vi) you will report to us any
        unauthorized or prohibited access to or use of your Subscription and/or
        password(s) or other authentication credentials.
      </Text>
      <H4>d) Disclaimer</H4>
      <Text marginBottom="16px">
        WE DISCLAIM ANY AND ALL LIABILITY WITH RESPECT TO, AND YOU UNDERSTAND
        AND ACKNOWLEDGE THAT WE ARE NOT RESPONSIBLE FOR: (I) ANY SECURITY OR
        PRIVACY BREACHES RELATED TO YOUR CREDIT CARD OR OTHER PAYMENT METHOD,
        (II) ANY FEES THAT MAY BE CHARGED TO YOU BY YOUR BANK IN CONNECTION WITH
        THE COLLECTION OF FEES, AND/OR (III) ANY UNAUTHORIZED USE OF YOUR CREDIT
        CARD, DEBIT CARD, OR OTHER PAYMENT METHOD BY A THIRD PARTY.
      </Text>
      <H3>8.4 Subscription Plans</H3>
      <H4>a) Automatic Renewals</H4>
      <Text marginBottom="16px">
        Subscriptions are available on an automatically renewing subscription
        basis and entail payment of Subscription Fees. YOUR SUBSCRIPTION WILL
        AUTOMATICALLY RENEW AT THE END OF EACH SUBSCRIPTION TERM IDENTIFIED IN
        YOUR SUBSCRIPTION ORDER FOR SUBSEQUENT TERMS EQUAL IN LENGTH TO THAT
        INITIAL SUBSCRIPTION TERM (EACH SUCH PERIOD, A "SUBSCRIPTION TERM")
        UNLESS AND UNTIL YOU CANCEL THE APPLICABLE SUBSCRIPTION IN ACCORDANCE
        WITH THE CANCELLATION PROCEDURES IDENTIFIED IN SECTION 8.4(C) (INCLUDING
        ANY NOTICE PERIOD SPECIFIED IN SECTION 8.4(C) (CANCELLATION
        PROCEDURES)).
      </Text>
      <H4>b) Automatic Billing and Policies</H4>
      <Text marginBottom="16px">
        When you enroll in a Subscription, you expressly acknowledge and agree
        that: (i) each of Charry and Payment Processor is authorized to charge
        you, at the beginning of each Subscription Term, the Subscription Fee
        for the applicable Subscription, any applicable Taxes, and any other
        charges you may incur in connection with such Subscription, subject to
        adjustment in accordance with these Terms; and (ii) your Subscription is
        continuous until the earlier of: (A) your cancellation of such
        Subscription (including any notice period specified in Section 8.4(c)
        (Cancellation Procedures)) and (B) the suspension, discontinuation, or
        termination of your access to such Subscription or to the Service in
        accordance with these Terms. You understand and acknowledge that the
        amounts billed may vary due to Promotional Offers (as defined below),
        changes to the Subscription Fee in accordance with the Pricing and
        Payment Terms, and/or changes in applicable Taxes, and you authorize
        each of Charry and Payment Processor to charge your Payment Method the
        changed amounts.
      </Text>
      <H4>c) Cancellation Procedures</H4>
      <Text marginBottom="16px">
        To cancel any Subscription, you must notify us at least three (3) days
        before the start of the next Subscription Term by using the appropriate
        functionalities of the Service or by contacting us at legal@charry.ai.
        You will continue to have access to the Subscription through the end of
        the then-current Subscription Term. YOU UNDERSTAND THAT UNLESS AND UNTIL
        YOU NOTIFY US OF YOUR INTENT TO CANCEL, YOUR SUBSCRIPTION AND THE
        CORRESPONDING SUBSCRIPTION FEE WILL AUTOMATICALLY RENEW, AND YOU
        AUTHORIZE EACH OF Charry AND PAYMENT PROCESSOR (WITHOUT NOTICE TO YOU,
        UNLESS REQUIRED BY APPLICABLE LAW) TO CHARGE YOU THE APPLICABLE
        SUBSCRIPTION FEE AND ANY APPLICABLE TAXES, USING ANY OF YOUR PAYMENT
        METHODS.
      </Text>
      <H4>d) Cancellation; Refunds</H4>
      <Text marginBottom="16px">
        You may de-activate your User Account or any Subscription at any time,
        in your sole discretion, and we may, subject to Section 8.1 (Billing
        Policies; Taxes), suspend or terminate your Subscription, your User
        Account, or the Service at any time, in our sole discretion. HOWEVER,
        YOU UNDERSTAND AND ACKNOWLEDGE THAT, UNLESS REQUIRED BY APPLICABLE LAW,
        YOU WILL NOT BE ENTITLED TO RECEIVE ANY REFUND OR CREDIT FOR ANY SUCH
        CANCELLATION, SUSPENSION, OR TERMINATION, NOR FOR ANY UNUSED TIME ON
        YOUR SUBSCRIPTION, ANY PRE-PAYMENTS MADE IN CONNECTION WITH YOUR
        SUBSCRIPTION, ANY USAGE OR SUBSCRIPTION FEES FOR ANY PORTION OF THE
        SERVICE, ANY CONTENT OR DATA ASSOCIATED WITH YOUR USER ACCOUNT, OR
        ANYTHING ELSE, AND THAT ANY SUCH REFUNDS OR CREDITS MAY BE GRANTED AT
        OUR SOLE OPTION AND IN OUR SOLE DISCRETION. If you believe you have been
        improperly charged and would like to request a refund, please contact us
        at legal@charry.ai.
      </Text>
      <H3>8.5 Promotional Offers</H3>
      <Text marginBottom="16px">
        We may from time to time offer special promotional offers, plans, or
        memberships ("Promotional Offers"). Promotional Offer eligibility is
        determined by us in our sole discretion, and we reserve the right to
        revoke a Promotional Offer in the event that we determine you are not
        eligible. We may use information such as device ID, method of payment,
        and/or an email address used in connection with your User Account to
        determine eligibility. The eligibility requirements and other
        limitations and conditions will be disclosed when you sign-up for the
        Promotional Offer or in other communications made available to you. You
        understand and acknowledge that any Promotional Offers, including,
        without limitation, relating to Subscriptions, are subject to change at
        any time and from time to time.
      </Text>
      <H2>9. Privacy; Data Security</H2>

      <H3>9.1 Privacy</H3>
      <Text marginBottom="16px">
        We care about your privacy. By using the Service, you acknowledge that
        we may collect and use your personal information and aggregated and/or
        anonymized data as set forth in our Privacy Notice, and that your
        personal information may be transferred to, and/or processed in, the
        United States. We will not share your personal data except as set forth
        in our Privacy Policy.
      </Text>

      <H3>9.2 Security</H3>
      <Text marginBottom="16px">
        We care about the integrity and security of your personal information.
        However, we cannot guarantee that unauthorized third parties will never
        be able to defeat our security measures or to use your data for improper
        purposes. You acknowledge that you provide your data at your own risk.
      </Text>

      <H2>10. Your Use of Third-Party Services</H2>
      <Text marginBottom="16px">
        The service may contain links to third-party sites, materials, and/or
        services (collectively, "Third-Party Services") that are not owned or
        controlled by us, and certain functionalities of the service may require
        your use of third-party services. If you use a third-party service in
        connection with the service, you are subject to and agree to, and must
        comply with, the third party's terms and conditions made available via,
        or agreed in connection with, its services. We do not endorse or assume
        any responsibility for any third-party services. If you access a
        third-party service from the service or share your user content on or
        through any third-party service, you do so at your own risk, and you
        understand that these terms and our privacy notice do not apply to your
        use of any third-party service. You expressly relieve us from any and
        all liability arising from your access to and/or use of any third-party
        service.
      </Text>

      <H2>11. Release</H2>
      <Text marginBottom="16px">
        You hereby release us from all claims, damages (whether direct,
        indirect, incidental, consequential, or otherwise), obligations, losses,
        liabilities, costs, debts, and expenses, in each case of every kind and
        nature, known and unknown, arising out of a dispute between you and a
        third party (including any other User) in connection with the Service.
        In addition, you waive any Applicable Law that says, in substance: "A
        GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE RELEASING PARTY DOES
        NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
        EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM OR HER, WOULD HAVE
        MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE RELEASED PARTY."
      </Text>

      <H2>12. Indemnity</H2>
      <Text marginBottom="16px">
        You will defend, indemnify, and hold us and our subsidiaries and
        affiliates, and our and their respective agents, suppliers, licensors,
        employees, contractors, officers, and directors (collectively, including
        Charry, the "Charry Indemnitees") harmless from and against any and all
        claims, damages (whether direct, indirect, incidental, consequential, or
        otherwise), obligations, losses, liabilities, costs, debts, and expenses
        (including, but not limited to, legal fees) arising from: (a) your
        access to and/or use of the Service, including your use of any Output;
        (b) your violation of any term of these Terms, including, without
        limitation, your breach of any of your representations and warranties
        set forth in these Terms; (c) your violation of any third-party right,
        including, without limitation, any privacy right or Intellectual
        Property Right; (d) your violation of any Applicable Law; (e) User
        Content or any content that is submitted via your User Account,
        including, without limitation, any misleading, false, or inaccurate
        information; (f) your willful misconduct; or (g) any third party's
        access to and/or use of the Service with your username(s), password(s),
        or other authentication credential(s).
      </Text>

      <H2>13. No Warranty; Disclaimers</H2>
      <Text marginBottom="16px">
        The service is provided on an "as is" and "as available" basis. Your use
        of the service is at your own risk. To the maximum extent permitted by
        applicable law, the service, the intellectual property, and any other
        information available on or through the service are provided without
        warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, and/or non-infringement. No advice or information,
        whether oral or written, obtained by you from us or through the service
        will create any warranty not expressly stated herein. Without limiting
        the generality of the foregoing, none of the Charry indemnitees warrants
        that any content or any other information contained in, or available
        via, the service is accurate, comprehensive, reliable, useful, or
        correct; that the service will meet your requirements; that the service
        will be available at any particular time or location, uninterrupted, or
        secure; that any defects or errors in the service will be corrected; or
        that the service is free of viruses or other harmful components. Any
        content downloaded or otherwise obtained through the use of the service
        is so obtained at your own risk, and you will be solely responsible for
        any damage to your computer system(s) or mobile device(s) and/or for
        loss of data that results from same or from your access to and/or use of
        the service. You may have other statutory rights, but the duration of
        statutorily required warranties, if any, will be limited to the shortest
        period permitted by applicable law.
      </Text>

      <Text marginBottom="16px">
        Further, Charry does not warrant, endorse, guarantee, recommend, or
        assume responsibility for any product or service advertised or offered
        by any third party through the service or any hyperlinked website or
        service, and Charry will not be a party to, or in any way monitor, any
        transaction between you and third-party providers of products or
        services.
      </Text>

      <Text marginBottom="16px">
        United States federal law and some states, provinces, and other
        jurisdictions do not allow the exclusion of and/or limitations on
        certain implied warranties, so the above exclusions and/or limitations
        may not apply to you. These terms give you specific legal rights, and
        you may also have other rights, which vary from jurisdiction to
        jurisdiction. The disclaimers, exclusions, and limitations under these
        terms will not apply to the extent prohibited by applicable law.
      </Text>

      <H2>14. Limitation of Liability</H2>
      <Text marginBottom="16px">
        To the maximum extent permitted by applicable law, in no event will any
        Charry indemnitee be liable for any indirect, punitive, incidental,
        special, consequential, or exemplary damages, including, without
        limitation, damages for loss of profits, goodwill, use, or data, or
        other intangible losses, arising out of or relating to the use of, or
        inability to use, the service or any portion thereof. Under no
        circumstances will we be responsible for any damage, loss, or injury
        resulting from hacking, tampering, or other unauthorized access to or
        use of the service or your user account or the information contained
        therein.
      </Text>

      <Text marginBottom="16px">
        To the maximum extent permitted by applicable law, we assume no
        liability or responsibility for any (a) errors, mistakes, or
        inaccuracies of content; (b) personal injury or property damage, of any
        nature whatsoever, resulting from your access to or use of the service;
        (c) any unauthorized access to or use of the servers running the service
        and/or any and all personal information stored therein; (d) any
        interruption or cessation of transmission to or from the service; (e)
        any bugs, viruses, trojan horses, or the like that may be transmitted to
        or through the service by any third party; (f) any errors or omissions
        in any content, or any loss or damage incurred as a result of the use of
        any content posted, emailed, transmitted, or otherwise made available
        through the service; and/or (g) your data, any user content, or the
        defamatory, offensive, or illegal conduct of any third party.
      </Text>

      <Text marginBottom="16px">
        In no event will any Charry indemnitee be liable to you for any claims,
        proceedings, liabilities, obligations, damages, losses, or costs in an
        amount exceeding the amount you paid to us hereunder or one hundred U.S.
        Dollars ($100.00), whichever is greater. This limitation of liability
        section applies whether the alleged liability is based on contract,
        tort, negligence, strict liability, or any other basis, even if we have
        been advised of the possibility of such damage.
      </Text>

      <Text marginBottom="16px">
        Some jurisdictions do not allow the exclusion of and/or limitations on
        incidental or consequential damages, so the above exclusions and/or
        limitations may not apply to you. These terms give you specific legal
        rights, and you may also have other rights, which vary from jurisdiction
        to jurisdiction. The disclaimers, exclusions, and limitations of
        liability under these terms will not apply to the extent prohibited by
        applicable law.
      </Text>

      <H2>
        15. Governing Law, Arbitration, and Class Action/Jury Trial Waiver
      </H2>

      <H3>15.1 Governing Law</H3>
      <Text marginBottom="16px">
        You agree that: (a) the Service will be deemed solely based in the State
        of Delaware; and (b) the Service will be deemed a passive one that does
        not give rise to personal jurisdiction over us, either specific or
        general, in jurisdictions other than Delaware. These Terms will be
        governed by the internal substantive laws of the State of Delaware,
        without respect to its conflict of laws principles. The parties
        acknowledge that these Terms evidence a transaction involving interstate
        commerce. Notwithstanding the preceding sentences with respect to the
        substantive law governing these Terms, the Federal Arbitration Act (9
        U.S.C. §§ 1-16) (as it may be amended, "FAA") governs the interpretation
        and enforcement of the Arbitration Agreement below and preempts all
        state laws (and laws of other jurisdictions) to the fullest extent
        permitted by Applicable Law. If the FAA is found to not apply to any
        issue that arises from or relates to the Arbitration Agreement, then
        that issue will be resolved under and governed by the law of the U.S.
        state where you live (if applicable) or the jurisdiction mutually agreed
        upon in writing by you and us. The application of the United Nations
        Convention on Contracts for the International Sale of Goods is expressly
        excluded. You agree to submit to the exclusive personal jurisdiction of
        the federal and state courts located in Delaware for any actions for
        which we retain the right to seek injunctive or other equitable relief
        in a court of competent jurisdiction to prevent the actual or threatened
        infringement, misappropriation, or violation of our data security,
        Confidential Information, or Intellectual Property Rights, as set forth
        in the Arbitration Agreement below, including any provisional relief
        required to prevent irreparable harm. You agree that Delaware is the
        proper and exclusive forum for any appeals of an arbitration award, or
        for trial court proceedings in the event that the Arbitration Agreement
        below is found to be unenforceable. These Terms were drafted in the
        English language and this English language version of the Terms is the
        original, governing instrument of the understanding between you and us.
        In the event of any conflict between the English version of these Terms
        and any translation, the English version will prevail.
      </Text>

      <H3>15.2 Arbitration Agreement</H3>
      <H4>a) General</H4>
      <Text marginBottom="16px">
        READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE
        THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM
        US. This Arbitration Agreement applies to and governs any dispute,
        controversy, or claim between you and us that arises out of or relates
        to, directly or indirectly: (i) these Terms, including the formation,
        existence, breach, termination, enforcement, interpretation, validity,
        and enforceability thereof; (ii) access to or use of the Service,
        including receipt of any advertising or marketing communications; (iii)
        any transactions through, by, or using the Service; or (iv) any other
        aspect of your relationship or transactions with us, directly or
        indirectly, as a User or consumer (each, a "Claim," and, collectively,
        "Claims"). This Arbitration Agreement will apply, without limitation, to
        all Claims that arose or were asserted before or after your consent to
        these Terms.
      </Text>

      <H4>b) Opting Out of Arbitration Agreement</H4>
      <Text marginBottom="16px">
        If you are a new User, you can reject and opt out of this Arbitration
        Agreement within thirty (30) days of accepting these Terms by emailing
        us at legal@charry.ai with your full, legal name and stating your intent
        to opt out of this Arbitration Agreement. Opting out of this Arbitration
        Agreement does not affect the binding nature of any other part of these
        Terms, including the provisions regarding controlling law or the courts
        in which any disputes must be brought.
      </Text>

      <H4>c) Dispute-Resolution Process</H4>
      <Text marginBottom="16px">
        For any Claim, you will first contact us at legal@charry.ai and attempt
        to resolve the Claim with us informally. In the unlikely event that we
        have not been able to resolve a Claim after sixty (60) days, we each
        agree to resolve such Claim exclusively through binding arbitration by
        JAMS before a single arbitrator (the "Arbitrator"), under the Optional
        Expedited Arbitration Procedures then in effect for JAMS (the "Rules"),
        except as provided herein. JAMS may be contacted at www.jamsadr.com,
        where the Rules are available. In the event of any conflict between the
        Rules and this Arbitration Agreement, this Arbitration Agreement will
        control. The arbitration will be conducted in the U.S. county where you
        live (if applicable) or Sussex County, Delaware, unless you and Charry
        agree otherwise. If you are using the Service for commercial purposes,
        each party will be responsible for paying any JAMS filing and
        administrative fees and Arbitrator fees in accordance with the Rules,
        and the award rendered by the Arbitrator will include costs of
        arbitration, reasonable attorneys' fees, and reasonable costs for expert
        and other witnesses. If you are an individual using the Service for
        non-commercial purposes: (i) JAMS may require you to pay a fee for the
        initiation of your case, unless you apply for and successfully obtain a
        fee waiver from JAMS; (ii) the award rendered by the Arbitrator may
        include your costs of arbitration, your reasonable attorneys' fees, and
        your reasonable costs for expert and other witnesses; and (iii) you may
        sue in a small claims court of competent jurisdiction without first
        engaging in arbitration, but this would not absolve you of your
        commitment to engage in the informal dispute resolution process. Any
        judgment on the award rendered by the Arbitrator may be entered in any
        court of competent jurisdiction. You and we agree that the Arbitrator,
        and not any federal, state, or local court or agency, will have
        exclusive authority to resolve any disputes relating to the scope,
        interpretation, applicability, enforceability, or formation of this
        Arbitration Agreement, including any claim that all or any part of this
        Arbitration Agreement is void or voidable. The Arbitrator will also be
        responsible for determining all threshold arbitrability issues,
        including issues relating to whether these Terms are, or whether any
        provision of these Terms is, unconscionable or illusory, and any defense
        to arbitration, including waiver, delay, laches, unconscionability,
        and/or estoppel.
      </Text>

      <H4>d) Equitable Relief</H4>
      <Text marginBottom="16px">
        NOTHING IN THIS ARBITRATION AGREEMENT WILL BE DEEMED AS: PREVENTING US
        FROM SEEKING INJUNCTIVE OR OTHER EQUITABLE RELIEF FROM THE COURTS AS
        NECESSARY TO PREVENT THE ACTUAL OR THREATENED INFRINGEMENT,
        MISAPPROPRIATION, OR VIOLATION OF OUR DATA SECURITY, CONFIDENTIAL
        INFORMATION, OR INTELLECTUAL PROPERTY RIGHTS; OR PREVENTING YOU FROM
        ASSERTING CLAIMS IN A SMALL CLAIMS COURT, PROVIDED THAT YOUR CLAIMS
        QUALIFY AND SO LONG AS THE MATTER REMAINS IN SUCH COURT AND ADVANCES ON
        ONLY AN INDIVIDUAL (NON-CLASS, NON-COLLECTIVE, AND NON-REPRESENTATIVE)
        BASIS.
      </Text>

      <H4>e) Severability</H4>
      <Text marginBottom="16px">
        If this Arbitration Agreement is found to be void, unenforceable, or
        unlawful, in whole or in part, the void, unenforceable, or unlawful
        provision, in whole or in part, will be severed. Severance of the void,
        unenforceable, or unlawful provision, in whole or in part, will have no
        impact on the remaining provisions of this Arbitration Agreement, which
        will remain in force, or on the parties' ability to compel arbitration
        of any remaining Claims on an individual basis pursuant to this
        Arbitration Agreement. Notwithstanding the foregoing, if the Class
        Action/Jury Trial Waiver below is found to be void, unenforceable, or
        unlawful, in whole or in part, because it would prevent you from seeking
        public injunctive relief, then any dispute regarding the entitlement to
        such relief (and only that relief) must be severed from arbitration and
        may be litigated in a civil court of competent jurisdiction. All other
        claims for relief subject to arbitration under this Arbitration
        Agreement will be arbitrated under its terms, and the parties agree that
        litigation of any dispute regarding the entitlement to public injunctive
        relief will be stayed pending the outcome of any individual claims in
        arbitration.
      </Text>

      <H3>15.3 Class Action/Jury Trial Waiver</H3>
      <Text marginBottom="16px">
        BY ENTERING INTO THESE TERMS, YOU AND Charry ARE EACH WAIVING THE RIGHT
        TO A TRIAL BY JURY OR TO BRING, JOIN, OR PARTICIPATE IN ANY PURPORTED
        CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR
        OTHER REPRESENTATIVE PROCEEDING OF ANY KIND AS A PLAINTIFF OR CLASS
        MEMBER. THE FOREGOING APPLIES TO ALL USERS (BOTH NATURAL PERSONS AND
        ENTITIES), REGARDLESS OF WHETHER YOU HAVE OBTAINED OR USED THE SERVICE
        FOR PERSONAL, COMMERCIAL, OR OTHER PURPOSES. THIS CLASS ACTION/JURY
        TRIAL WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE
        OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S OR
        ENTITY'S CLAIMS. YOU AND Charry AGREE THAT THE ARBITRATOR MAY AWARD
        RELIEF ONLY TO AN INDIVIDUAL CLAIMANT AND ONLY TO THE EXTENT NECESSARY
        TO PROVIDE RELIEF ON YOUR INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED MAY
        NOT AFFECT OTHER USERS.
      </Text>

      <H2>16. U.S. Government Restricted Rights</H2>
      <Text marginBottom="16px">
        To the extent the Service is being used by or on behalf of the U.S.
        Government, the Service will be deemed commercial computer software or
        commercial computer software documentation (as applicable). Accordingly,
        if you are an agency of the U.S. Government or any contractor therefor,
        you receive only those rights with respect to the Service as are granted
        to all other Users hereunder, in accordance with 48 C.F.R. §227.7202 and
        48 C.F.R. §12.212, as applicable.
      </Text>

      <H2>17. Export Controls</H2>
      <Text marginBottom="16px">
        You understand and acknowledge that the Service may be subject to export
        control laws and regulations. You will comply with all applicable import
        and export and re-export control and trade and economic sanctions laws
        and regulations, including the Export Administration Regulations
        maintained by the U.S. Department of Commerce, trade and economic
        sanctions maintained by the U.S. Treasury Department's Office of Foreign
        Assets Control ("OFAC"), and the International Traffic in Arms
        Regulations maintained by the U.S. State Department. You represent and
        warrant that you are not, and that no person to whom you make the
        Service available or that is acting on your behalf, or, if you are an
        Organization, that no person or entity owning 50% or more of your equity
        securities or other equivalent voting interests, is (a) listed on the
        List of Specially Designated Nationals and Blocked Persons or on any
        other list of sanctioned, prohibited, or restricted parties administered
        by OFAC or by any other governmental entity, or (b) located in, a
        national or resident of, or a segment of the government of, any country
        or territory for which the United States maintains trade or economic
        sanctions or embargoes or that has been designated by the U.S.
        Government as a "terrorist supporting" region.
      </Text>

      <H2>18. General Provisions</H2>

      <H3>18.1 Assignment</H3>
      <Text marginBottom="16px">
        These Terms, and any rights and licenses granted hereunder, may not be
        transferred or assigned by you without our prior express written
        consent, but may be assigned by us without restriction. Any attempted
        transfer or assignment in violation hereof will be null and void.
      </Text>

      <H3>18.2 Notification Procedures and Changes to these Terms</H3>
      <Text marginBottom="16px">
        We may provide notifications, whether such notifications are required by
        Applicable Law or are for marketing or other business-related purposes,
        to you via email notice or written or hard copy notice, or through
        posting of such notice on the Service, as we determine, at our sole
        discretion. We reserve the right to determine the form and means of
        providing notifications to Users, provided that you may opt out of
        certain means of notification, as required under Applicable Law or as
        described in these Terms. We are not responsible for any automatic
        filtering you or your network provider may apply to email notifications
        we send to the email address you provide us. We may, in our sole
        discretion, modify or update these Terms from time to time, and so you
        should review this page periodically. When we change these Terms in a
        material manner, we will update the 'last modified' date at the top of
        this page and notify you that material changes have been made to these
        Terms. These Terms apply to and govern your access to and use of the
        Service effective as of the start of your access to the Service, even if
        such access began before publication of these Terms. Your continued use
        of the Service after any change to these Terms constitutes your
        acceptance of the new Terms of Service. If you do not agree to any part
        of these Terms or to any future Terms of Service, do not access or use
        (or continue to access or use) the Service.
      </Text>

      <H3>18.3 Entire Agreement; Severability</H3>
      <Text marginBottom="16px">
        These Terms, together with any amendments and any additional agreements
        you may enter into with us in connection with the Service, will
        constitute the entire agreement between you and us concerning the
        Service. Any statements or comments made between you and any of our
        employees or representatives are expressly excluded from these Terms and
        will not apply to you or us, or to your access to or use of the Service.
        Except as otherwise stated in the Arbitration Agreement, if any
        provision of these Terms is deemed invalid by a court of competent
        jurisdiction, the invalidity of such provision will not affect the
        validity of the remaining provisions of these Terms, which will remain
        in full force and effect.
      </Text>

      <H3>18.4 No Waiver</H3>
      <Text marginBottom="16px">
        No waiver of any term of these Terms will be deemed a further or
        continuing waiver of such term or of any other term, and our failure to
        assert any right or provision under these Terms will not constitute a
        waiver of such right or provision.
      </Text>

      <H3>18.5 California Residents</H3>
      <Text marginBottom="16px">
        The provider of the Service is set forth herein. If you are a California
        resident, in accordance with Cal. Civ. Code §1789.3, you may report
        complaints to the Complaint Assistance Unit of the Division of Consumer
        Services of the California Department of Consumer Affairs by contacting
        it in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA
        95834, or by telephone at (800) 952-5210 or (916) 445-1254.
      </Text>

      <H3>18.6 Contact</H3>
      <Text marginBottom="16px">
        If you have any questions about these Terms and/or the Service, please
        contact us at legal@charry.ai.
      </Text>
    </Box>
  );

  if (hideLayout) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        overflow="auto"
        width="100%"
      >
        <Box
          maxWidth="100%"
          paddingBottom={24}
          paddingLeft={12}
          paddingRight={12}
          width={{ md: "1000px", sm: "100%" }}
        >
          {Content}
        </Box>
      </Box>
    );
  }

  return (
    <MarketingLayout>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        {Content}
      </Box>
    </MarketingLayout>
  );
};

export default withSuspense(TermsPage);

import z from "zod";

export const zAnalyticsEvents = z.enum([
  // Character
  "CreateCharacter",
  "UpdateCharacter",
  // Chat
  "CreateChat",
  "UpdateChat",
  // Turn
  "CreateTurn",
  // Download
  "DownloadCLI",
  "DownloadConsole",
]);

export type AnalyticsEvents = z.infer<typeof zAnalyticsEvents>;

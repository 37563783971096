import posthog from "posthog-js";

import type PosthogEvent from "~/constants/PosthogEvent.constants";

export default function useTracking() {
  const track = <T>({
    event,
    properties,
  }: {
    event: PosthogEvent;
    properties?: Record<string, T>;
  }) => {
    posthog.capture(event, properties);
  };

  return {
    track,
  };
}

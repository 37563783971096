import type { z } from "zod";
import { useMemo } from "react";

import type { Character, CharacterCategoryEnum } from "@charry/models";

const categoryStarters: Record<
  z.infer<typeof CharacterCategoryEnum>,
  string[]
> = {
  Animals: [
    "If you could communicate with any animal species, which would you choose?",
    "What's the most surprising animal behavior you've learned about?",
    "How do you think climate change will affect animal migration patterns?",
    "If you could have any animal as a safely domesticated pet, what would it be?",
    "What's an endangered species you think deserves more attention?",
    "What steps do you take to help protect wildlife?",
    "How do animals inspire you in your daily life?",
    "What's your favorite documentary about animals?",
    "If you could witness any animal in its natural habitat, which would it be?",
    "How do you feel about zoos and animal sanctuaries?",
  ],
  Anime: [
    "If you could be transported into any anime world, which one would you choose and why?",
    "What's an underappreciated anime that more people should watch?",
    "How do you think anime has influenced global pop culture?",
    "If you could have any anime character's abilities, whose would you choose?",
    "What anime trope do you think needs to be retired?",
    "Who is your favorite anime antagonist and why?",
    "What was the first anime you ever watched?",
    "How do you feel about live-action adaptations of anime?",
    "If you could create your own anime series, what would it be about?",
    "Which anime character do you relate to the most?",
  ],
  "Anime Game Characters": [
    "What's the most epic battle you've been in?",
    "If you could change one thing about your game world, what would it be?",
    "What's a secret about your character that players might not know?",
    "How do you feel about the players who control your actions?",
    "If you could visit another game universe, which one would you choose?",
    "Which of your abilities are you most proud of, and why?",
    "What's your relationship like with your fellow characters?",
    "How do you prepare for the challenges you face in your game?",
    "What advice would you give to new players starting your game?",
    "How do you unwind after a long day of adventuring?",
  ],
  Books: [
    "If you could enter the world of any book, which one would it be?",
    "What's a book that changed your perspective on life?",
    "If you could have dinner with any author, dead or alive, who would it be?",
    "What's your take on the debate between physical books and e-books?",
    "What book do you think everyone should read at least once in their life?",
    "How do you decide what book to read next?",
    "What's your favorite quote from a book, and why?",
    "If you could rewrite the ending of any book, which would it be?",
    "How do you organize your personal library?",
    "What genre do you think is underrated, and why?",
  ],
  Celebrities: [
    "If you could switch lives with another celebrity for a day, who would it be?",
    "What's a common misconception about celebrity life you'd like to clear up?",
    "If you could use your fame to champion any cause, what would it be?",
    "What's the strangest fan encounter you've ever had?",
    "If you weren't famous, what career do you think you'd pursue?",
    "How do you maintain privacy in the public eye?",
    "What's the most rewarding project you've worked on?",
    "How do you handle criticism or negative press?",
    "What's one thing you wish your fans knew about you?",
    "How do you stay grounded amidst fame and success?",
  ],
  Comedy: [
    "What's the most embarrassing situation you've found yourself in?",
    "If you could roast any historical figure, who would it be and why?",
    "What's your go-to method for cheering someone up?",
    "If you had to describe your sense of humor in three words, what would they be?",
    "What's the funniest misunderstanding you've ever been involved in?",
    "Who is your favorite comedian, and what makes them stand out to you?",
    "What's a joke that always makes you laugh?",
    "How do you come up with your comedic material?",
    "What's the funniest prank you've ever pulled or seen?",
    "If humor were a currency, how rich would you be?",
  ],
  Discussion: [
    "If you could have a debate with any historical figure, who would it be and what topic?",
    "What's a controversial opinion you hold that you'd like to discuss?",
    "How do you approach conversations with people who have vastly different views?",
    "What topic do you think deserves more public discourse?",
    "If you could instantly solve one global issue through discussion, which would it be?",
    "How do you ensure productive and respectful debates?",
    "What is a topic you're passionate about that others might find surprising?",
    "How do you stay informed on current events and issues?",
    "What's the most enlightening conversation you've ever had?",
    "How can we improve dialogue in polarized societies?",
  ],
  "Game Characters": [
    "If you could team up with any other game character, who would it be?",
    "What's the most difficult decision you've had to make in your game?",
    "How do you feel about player choices affecting your storyline?",
    "If you could change your character class or abilities, what would you choose?",
    "What's your opinion on the fourth wall breaks in games?",
    "What motivates you to keep going on your quests?",
    "How do you handle defeats or setbacks in your journey?",
    "What's your favorite in-game item or weapon?",
    "If you could retire from adventuring, what would you do?",
    "What advice would you give to new characters just starting out?",
  ],
  Games: [
    "What's the most underrated game you've played recently?",
    "How do you think AI will change the future of gaming?",
    "What's your opinion on the debate between story-driven games and gameplay-focused ones?",
    "If you could design a new game genre, what would it be?",
    "What's the most memorable side quest you've encountered in a game?",
    "Which game character do you most identify with, and why?",
    "What game has the best soundtrack, in your opinion?",
    "How do you feel about microtransactions in games?",
    "If you could bring a game element into the real world, what would it be?",
    "What's the longest gaming session you've ever had?",
  ],
  Helpers: [
    "What's the most challenging task you've helped with?",
    "How do you approach problem-solving?",
    "Can you share a productivity tip that most people don't know about?",
    "What's your process for breaking down complex tasks?",
    "How do you stay updated with the latest information in your field?",
    "What's the most innovative solution you've come up with?",
    "How do you handle setbacks or failures when assisting others?",
    "Can you share a memorable success story from your experience?",
    "What motivates you to help others?",
    "How do you prioritize tasks when everything seems urgent?",
  ],
  History: [
    "If you could witness any historical event firsthand, which would you choose?",
    "What's an overlooked historical figure you think more people should know about?",
    "How do you think social media would have changed a major historical event?",
    "If you could bring back one lost piece of historical knowledge, what would it be?",
    "What historical misconception do you wish you could correct for everyone?",
    "Which period in history do you find most fascinating, and why?",
    "How do you think future historians will view our current era?",
    "What lessons from history do you think are most important today?",
    "If you could interview any historical figure, who would it be?",
    "How does history influence your daily life?",
  ],
  "Image Generating": [
    "If you could bring any imaginary creature to life through art, what would it be?",
    "How do you think AI-generated art will impact traditional artists?",
    "What's the most challenging subject you've had to depict?",
    "If you could combine two art styles to create something new, which would you choose?",
    "What's an unconventional source of inspiration for your creations?",
    "How do you overcome creative block when generating images?",
    "What do you think is the future of digital art?",
    "If you could illustrate a book or story, which one would it be?",
    "What's your favorite medium to work with, and why?",
    "How do you incorporate feedback into your artistic process?",
  ],
  "Language Learning": [
    "What's the funniest misunderstanding you've had due to a language barrier?",
    "If you could instantly become fluent in any language, which would you choose?",
    "What's an untranslatable word or phrase you find fascinating?",
    "How has learning multiple languages changed your perspective on the world?",
    "What's your most effective tip for maintaining language skills?",
    "How do you immerse yourself in a new language outside of classes?",
    "What's the most challenging language you've tried to learn?",
    "How do cultural nuances affect language learning?",
    "What language do you think will be most important in the future?",
    "Have you ever dreamed in a different language?",
  ],
  Other: [
    "If you could master any skill instantly, what would it be?",
    "What's the most thought-provoking question someone has ever asked you?",
    "If you could send a message to the entire world, what would you say?",
    "What's a unique experience you've had that you'd like to share?",
    "What is something you've always wanted to try but haven't yet?",
    "How do you define success for yourself?",
    "What's the best piece of advice you've ever received?",
    "If you could live in any fictional universe, which would it be?",
    "What's a hobby you're passionate about?",
    "How do you stay motivated during challenging times?",
  ],
  Philosophy: [
    "If you could have a philosophical discussion with any AI, what would you ask?",
    "How do you think virtual reality will impact our understanding of consciousness?",
    "What philosophical concept do you find most challenging to grasp?",
    "If you could add a new branch to philosophy, what would it explore?",
    "How do you apply philosophical concepts to your everyday life?",
    "Do you believe free will exists, or is everything predetermined?",
    "What is the meaning of life, in your opinion?",
    "How do you define truth?",
    "What role does ethics play in technological advancement?",
    "Can happiness be a permanent state, or is it fleeting?",
  ],
  Politics: [
    "If you could implement one policy worldwide, what would it be?",
    "How do you think AI and automation will change the political landscape?",
    "What's a political issue you've changed your mind about, and why?",
    "If you could redesign the voting system, what changes would you make?",
    "How do you think space exploration will impact international politics?",
    "What role should education play in politics?",
    "How do you stay informed about political issues?",
    "What do you think is the biggest challenge facing democracy today?",
    "How can individuals make a difference in politics without holding office?",
    "What are your thoughts on term limits for politicians?",
  ],
  Religion: [
    "How do you think technology will impact religious practices in the future?",
    "If you could ask any religious figure one question, who and what would you ask?",
    "What's a common misconception about your beliefs you'd like to address?",
    "How do you balance faith with scientific understanding?",
    "If you could create a new religious holiday, what would it celebrate?",
    "How does your faith influence your daily decisions?",
    "What role does community play in your religious life?",
    "How do you handle discussions with those of different faiths?",
    "What is a sacred text or teaching that resonates with you deeply?",
    "How do you see the role of religion in promoting global peace?",
  ],
  Vtuber: [
    "What inspired you to become a Vtuber?",
    "If you could collaborate with any other Vtuber or streamer, who would it be?",
    "What's the most challenging aspect of being a Vtuber that viewers might not realize?",
    "If you could design a new feature for streaming platforms, what would it be?",
    "How do you think Vtubing will evolve in the next few years?",
    "What is your favorite part about interacting with your audience?",
    "How do you come up with ideas for your streams?",
    "What advice would you give to someone looking to start Vtubing?",
    "How do you balance your virtual persona with your real life?",
    "What's the most memorable moment you've had on stream?",
  ],
};

function generateChatStarters(character: Character): string[] {
  const categorystarters = categoryStarters[character.category ?? "Other"];
  const genericStarters = [
    "Tell me about yourself.",
    "What's your backstory?",
    "What are your main interests?",
    "What's the most fascinating thing about you?",
    "If you could change one thing about yourself, what would it be and why?",
    "What's a unique perspective you can offer on current events or trends?",
    "If you could travel anywhere in time or space, where would you go and why?",
    "What's the most valuable lesson you've learned that you'd like to share?",
    "What's something people often misunderstand about you?",
    "What do you enjoy doing in your free time?",
    "If you could have any superpower, what would it be?",
    "What's a goal you're currently working towards?",
    "Who has had the most significant impact on your life?",
  ];

  // Shuffle the category starters using Fisher-Yates algorithm
  const shuffledStarters = [...categorystarters].sort(
    () => Math.random() - 0.5,
  );

  // If the category is "Other", include generic starters
  if (character.category === "Other") {
    const shuffledGenericStarters = [...genericStarters].sort(
      () => Math.random() - 0.5,
    );
    return [...shuffledStarters, ...shuffledGenericStarters].slice(0, 4);
  }

  // For other categories, only use category-specific starters
  return shuffledStarters.slice(0, 4);
}

export function useChatStarters(character: Character | null | undefined) {
  const chatStarters = useMemo(() => {
    if (!character) return [];
    return generateChatStarters(character);
  }, [character]);

  return chatStarters;
}

import React, { Suspense, useRef } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
} from "@chakra-ui/react";
import { FiAperture } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import adventureBackground from "~/assets/char.png";
import CategoryButtonList from "~/ui/components/character/CategoryButtonList";
import CharacterCard from "~/ui/components/character/CharacterCard";
import CharacterCardList from "~/ui/components/character/CharacterCardList";
import LoginRegisterModal from "~/ui/components/dashboard/LoginRegisterModal";
import NoContentCard from "~/ui/components/NoContentCard";
import SearchInput from "~/ui/elements/SearchInput";
import useCharacterList from "~/ui/hooks/useCharacterList.hook";
import useIsAuthenticated from "~/ui/hooks/useIsAuthenticated.hook";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useUser from "~/ui/hooks/useUser.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import CharacterLayoutSuspense from "~/ui/suspense/CharacterLayout.suspense";

const categories = [
  "Helpers",
  "Anime Game Characters",
  "Games",
  "Anime",
  "Game Characters",
  "Books",
  "Comedy",
  "Image Generating",
  "Celebrities",
  "Vtuber",
  "Language Learning",
  "Discussion",
  "History",
  "Religion",
  "Animals",
  "Philosophy",
  "Politics",
  "Other",
];

const ExplorePageComponent = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { isAuthenticated } = useIsAuthenticated();
  const { user } = useUser();
  const [search, setSearch] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState<string>("");
  const [selectedListCategory, setSelectedListCategory] =
    React.useState<string>("All");

  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const { characterList, getTrendingCharacters, isLoading } =
    useCharacterList();

  const filtered = React.useMemo(() => {
    return characterList.filter((character) => {
      const matchesName = character.name
        .toLowerCase()
        .includes(search.toLowerCase());
      const matchesCategory = selectedCategory
        ? character.category === selectedCategory
        : true;
      const isPublic = character.visibility === "Public";
      return matchesName && matchesCategory && isPublic;
    });
  }, [characterList, search, selectedCategory]);

  const forYouRef = useRef<HTMLDivElement>(null);
  const featuredRef = useRef<HTMLDivElement>(null);
  const trendingRef = useRef<HTMLDivElement>(null);

  const categoryCharactersRef = useRef<HTMLDivElement>(null);
  const categoryListRef = useRef<HTMLDivElement>(null);

  const getFilteredTrendingCharacters = React.useCallback(() => {
    const trendingCharacters = getTrendingCharacters();
    return trendingCharacters.filter((character) => {
      const matchesName = character.name
        .toLowerCase()
        .includes(search.toLowerCase());
      const matchesCategory = selectedCategory
        ? character.category === selectedCategory
        : true;
      const isPublic = character.visibility === "Public";
      return matchesName && matchesCategory && isPublic;
    });
  }, [getTrendingCharacters, search, selectedCategory]);

  const getFeaturedCharacters = React.useCallback(() => {
    if (search || selectedCategory) {
      // If searching or filtering, return all matching characters
      return filtered;
    } else {
      // If not searching or filtering, return characters 10-20
      return filtered.slice(10, 20);
    }
  }, [filtered, search, selectedCategory]);

  const handleCharacterClick = (characterId: string) => {
    navigate(`/character/${characterId}/`);
  };

  const getCategoryCharacters = React.useCallback(() => {
    if (selectedListCategory === "All") {
      return filtered;
    }
    return filtered.filter(
      (character) => character.category === selectedListCategory,
    );
  }, [filtered, selectedListCategory]);

  const scroll = (
    ref: React.RefObject<HTMLDivElement>,
    scrollOffset: number,
  ) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <>
      <Header>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="center">
            <Title>{isMobile ? "" : "Explore Characters"}</Title>
          </Flex>
          <Flex alignItems="center" gap={4}>
            <InputGroup width={{ base: "100%", md: "300px" }}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <SearchInput
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search characters"
                value={search}
              />
            </InputGroup>
            <Select
              onChange={(e) => setSelectedCategory(e.target.value)}
              placeholder="All Categories"
              style={{ paddingBottom: "0px", paddingTop: "0px" }}
              value={selectedCategory}
              width={{ base: "100%", md: "200px" }}
            >
              {categories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
      </Header>
      <Content shouldSuspendContent={false}>
        {isLoading ? (
          <CharacterLayoutSuspense />
        ) : (
          <>
            {filtered.length === 0 ? (
              <NoContentCard
                Button={() =>
                  isAuthenticated ? (
                    <Link style={{ width: "400px" }} to="/characters/create">
                      <Button variant="primary" width="100%">
                        Create Character
                      </Button>
                    </Link>
                  ) : null
                }
                Icon={FiAperture}
                Text={() => (
                  <Text>
                    No characters found for your search criteria.
                    {isAuthenticated && (
                      <>
                        <br />
                        <br />
                        You can create a new character below.
                      </>
                    )}
                  </Text>
                )}
                mt={4}
              />
            ) : (
              <>
                <Box mb={4} mt={4}>
                  {isAuthenticated && user && (
                    <Flex alignItems="flex-start" flexDirection="column" mb={4}>
                      <Text color="gray.400" fontSize="lg">
                        Welcome back,
                      </Text>
                      <Heading as="h2" mt={1} size="lg">
                        {user.username}
                      </Heading>
                    </Flex>
                  )}
                  <Grid
                    gap={2}
                    templateColumns={{ base: "2fr", md: "60% 40%" }}
                  >
                    <Box
                      backgroundImage={`url(${adventureBackground})`}
                      backgroundPosition="center"
                      backgroundSize="cover"
                      borderRadius="3xl"
                      p={6}
                    >
                      <Text
                        fontSize={{ base: "xl", md: "2xl" }}
                        fontWeight="bold"
                        mb={2}
                      >
                        What do you want to do?
                      </Text>
                      <Text
                        color="blue.400"
                        fontSize={{ base: "2xl", md: "3xl" }}
                        fontWeight="bold"
                      >
                        Go on an adventure!
                      </Text>
                    </Box>
                    <Flex
                      direction={{ base: "row", md: "column" }}
                      gap={{ base: 4, md: 2 }}
                      overflowX={{ base: "auto", md: "visible" }}
                      pb={{ base: 4, md: 0 }}
                    >
                      {filtered[0] && (
                        <Box
                          flexShrink={0}
                          position="relative"
                          width={{ base: "80%", md: "100%" }}
                        >
                          <CharacterCard
                            character={filtered[0]}
                            onClick={() =>
                              filtered[0] &&
                              handleCharacterClick(filtered[0].id)
                            }
                          />
                        </Box>
                      )}
                      {filtered[1] && (
                        <Box
                          flexShrink={0}
                          position="relative"
                          width={{ base: "80%", md: "100%" }}
                        >
                          <CharacterCard
                            character={filtered[1]}
                            onClick={() =>
                              filtered[1] &&
                              handleCharacterClick(filtered[1].id)
                            }
                          />
                        </Box>
                      )}
                    </Flex>
                  </Grid>
                </Box>

                <Box mb={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      For you
                    </Heading>
                    <Flex>
                      <IconButton
                        aria-label="Scroll left"
                        icon={<ChevronLeftIcon />}
                        mr={2}
                        onClick={() => scroll(forYouRef, -300)}
                      />
                      <IconButton
                        aria-label="Scroll right"
                        icon={<ChevronRightIcon />}
                        onClick={() => scroll(forYouRef, 300)}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    css={{
                      "&::-webkit-scrollbar": { display: "none" },
                      scrollbarWidth: "none",
                    }}
                    overflowX="auto"
                    ref={forYouRef}
                  >
                    <CharacterCardList
                      characters={filtered.slice(0, 10)}
                      horizontal
                      onCharacterClick={handleCharacterClick}
                    />
                  </Flex>
                </Box>

                <Box mb={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      Featured
                    </Heading>
                    {getFeaturedCharacters().length > 0 && (
                      <Flex>
                        <IconButton
                          aria-label="Scroll left"
                          icon={<ChevronLeftIcon />}
                          mr={2}
                          onClick={() => scroll(featuredRef, -300)}
                        />
                        <IconButton
                          aria-label="Scroll right"
                          icon={<ChevronRightIcon />}
                          onClick={() => scroll(featuredRef, 300)}
                        />
                      </Flex>
                    )}
                  </Flex>
                  {getFeaturedCharacters().length > 0 ? (
                    <Flex
                      css={{
                        "&::-webkit-scrollbar": { display: "none" },
                        scrollbarWidth: "none",
                      }}
                      overflowX="auto"
                      ref={featuredRef}
                    >
                      <CharacterCardList
                        characters={getFeaturedCharacters()}
                        horizontal
                        onCharacterClick={handleCharacterClick}
                      />
                    </Flex>
                  ) : (
                    <Text color="gray.400">
                      No featured characters available.
                    </Text>
                  )}
                </Box>

                <Box mb={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      Trending
                    </Heading>
                    {getFilteredTrendingCharacters().length > 0 && (
                      <Flex>
                        <IconButton
                          aria-label="Scroll left"
                          icon={<ChevronLeftIcon />}
                          mr={2}
                          onClick={() => scroll(trendingRef, -300)}
                        />
                        <IconButton
                          aria-label="Scroll right"
                          icon={<ChevronRightIcon />}
                          onClick={() => scroll(trendingRef, 300)}
                        />
                      </Flex>
                    )}
                  </Flex>
                  {getFilteredTrendingCharacters().length > 0 ? (
                    <Flex
                      css={{
                        "&::-webkit-scrollbar": { display: "none" },
                        scrollbarWidth: "none",
                      }}
                      overflowX="auto"
                      ref={trendingRef}
                    >
                      <CharacterCardList
                        characters={getFilteredTrendingCharacters()}
                        horizontal
                        onCharacterClick={handleCharacterClick}
                      />
                    </Flex>
                  ) : (
                    <Text color="gray.400">
                      No trending characters available.
                    </Text>
                  )}
                </Box>

                <Box mb={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      Categories
                    </Heading>
                    <Flex>
                      <IconButton
                        aria-label="Scroll categories left"
                        icon={<ChevronLeftIcon />}
                        mr={2}
                        onClick={() => scroll(categoryListRef, -200)}
                      />
                      <IconButton
                        aria-label="Scroll categories right"
                        icon={<ChevronRightIcon />}
                        onClick={() => scroll(categoryListRef, 200)}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    css={{
                      "&::-webkit-scrollbar": { display: "none" },
                      scrollbarWidth: "none",
                    }}
                    overflowX="auto"
                    ref={categoryListRef}
                  >
                    <CategoryButtonList
                      categories={["All", ...categories]}
                      onCategorySelect={(category) =>
                        setSelectedListCategory(category)
                      }
                      selectedCategory={selectedListCategory}
                    />
                  </Flex>
                </Box>

                <Box mb={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      {selectedListCategory === "All"
                        ? "All Characters"
                        : selectedListCategory}
                    </Heading>
                    <Flex>
                      <IconButton
                        aria-label="Scroll left"
                        icon={<ChevronLeftIcon />}
                        mr={2}
                        onClick={() => scroll(categoryCharactersRef, -300)}
                      />
                      <IconButton
                        aria-label="Scroll right"
                        icon={<ChevronRightIcon />}
                        onClick={() => scroll(categoryCharactersRef, 300)}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    css={{
                      "&::-webkit-scrollbar": { display: "none" },
                      scrollbarWidth: "none",
                    }}
                    overflowX="auto"
                    ref={categoryCharactersRef}
                  >
                    <CharacterCardList
                      characters={getCategoryCharacters()}
                      horizontal
                      onCharacterClick={handleCharacterClick}
                    />
                  </Flex>
                  {getCategoryCharacters().length === 0 && (
                    <Text color="gray.400" mt={2}>
                      No characters available in this category.
                    </Text>
                  )}
                </Box>
                {/* <Box mb={4}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={4}
                  >
                    <Heading as="h2" size="lg">
                      All Characters
                    </Heading>
                    <Flex>
                      <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={() => scroll(allCharactersRef, -300)}
                        aria-label="Scroll left"
                        mr={2}
                      />
                      <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={() => scroll(allCharactersRef, 300)}
                        aria-label="Scroll right"
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    ref={allCharactersRef}
                    overflowX="auto"
                    css={{
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    <Grid templateColumns="repeat(5, 308px)" gap={4} mb={4}>
                      {filtered
                        .slice(0, visibleRows * 5)
                        .map((character, index) => (
                          <Box
                            key={`${character.id}-${index}`}
                            minWidth="300px"
                            mr={4}
                          >
                            <CharacterCard
                              character={character}
                              onClick={() => handleCharacterClick(character.id)}
                            />
                          </Box>
                        ))}
                    </Grid>
                  </Flex>
                  {filtered.length > visibleRows * 5 && (
                    <Button mt={4} variant="outline" onClick={handleViewMore}>
                      View More Characters
                    </Button>
                  )}
                </Box> */}
              </>
            )}
          </>
        )}
      </Content>
      <LoginRegisterModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default function ExplorePage() {
  return (
    <Suspense fallback={<CharacterLayoutSuspense />}>
      <ExplorePageComponent />
    </Suspense>
  );
}

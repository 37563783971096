import { useEffect, useState } from "react";
import { Box, Button, Stack, Text } from "@chakra-ui/react";

import { time, utils } from "@charry/utils";

function getSecondsUntilResend(lastEmailRequestTimestamp: Date | null): number {
  const canResendDatetime = utils.getCanResendEmailVerificationDatetime(
    lastEmailRequestTimestamp,
  );
  if (canResendDatetime == null) {
    return 0;
  }
  return canResendDatetime.diff(time.currentDate(), "seconds").seconds;
}

type ResendEmailVerificationComponentProps = {
  disabled?: boolean;
  handleRequestEmailVerification: () => void;
  lastEmailRequestTimestamp: Date | null;
};

export default function ResendEmailVerificationComponent({
  disabled = false,
  handleRequestEmailVerification,
  lastEmailRequestTimestamp,
}: ResendEmailVerificationComponentProps) {
  const [secondsLeft, setSecondsLeft] = useState(0);

  useEffect(() => {
    if (lastEmailRequestTimestamp == null) {
      return;
    }

    const intervalId = setInterval(() => {
      const seconds = Math.ceil(
        getSecondsUntilResend(lastEmailRequestTimestamp),
      );
      if (seconds < 0) {
        setSecondsLeft(0);
        clearInterval(intervalId);
      } else {
        setSecondsLeft(seconds);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [lastEmailRequestTimestamp]);

  const canRequestEmailVerification = utils.canResendEmailVerification(
    lastEmailRequestTimestamp,
  );

  return (
    <Stack spacing="0" width="100%">
      <Box height="24px" />
      <Button
        isDisabled={disabled || !canRequestEmailVerification}
        onClick={handleRequestEmailVerification}
        size="lg"
        variant="primary"
        width="100%"
      >
        Send verification email
      </Button>
      {secondsLeft > 0 && (
        <Text
          color="gray.400"
          marginTop="16px"
          textAlign="center"
          variant="16-reg"
        >
          Please wait {secondsLeft} seconds before requesting another email.
        </Text>
      )}
    </Stack>
  );
}

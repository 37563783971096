import React from "react";
import { Box } from "@chakra-ui/react";

import CharacterAvatar from "~/ui/components/character/CharacterAvatar";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import { NavButton } from "./NavButton";

type RecentChatNavButtonProps = {
  link: string;
  isCollapsed: boolean;
  characterName: string;
  characterImageUrl: string;
};

const RecentChatNavButton: React.FC<RecentChatNavButtonProps> = ({
  characterImageUrl,
  characterName,
  isCollapsed,
  link,
}) => {
  const isMobile = useIsMobile();

  return (
    <NavButton
      isCollapsed={isCollapsed}
      label={characterName}
      leftElement={
        <Box flexShrink={0} mr={isMobile ? 0 : 2}>
          <CharacterAvatar
            characterImageUrl={characterImageUrl}
            characterName={characterName}
            size={isMobile ? "2xs" : "xs"}
          />
        </Box>
      }
      link={link}
    />
  );
};

export default RecentChatNavButton;

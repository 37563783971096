import { Avatar, Box, HStack, Text } from "@chakra-ui/react";

type UserProfileProps = {
  name?: string;
  email?: string;
  image?: string;
};

const UserProfile = (props: UserProfileProps) => {
  const { email, image, name } = props;
  return (
    <HStack ps="2" spacing="3">
      <Avatar boxSize="10" name={name} src={image} />
      <Box>
        {name ? (
          <Text color="white" fontSize="sm" fontWeight="medium">
            {name}
          </Text>
        ) : null}
        {email ? (
          <Text color="white" fontSize="sm">
            {email}
          </Text>
        ) : null}
      </Box>
    </HStack>
  );
};

export default UserProfile;

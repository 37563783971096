import { useEffect } from "react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

import type RouteErrorType from "../types/RouteErrorType";
import SideNavigation from "~/ui/components/dashboard/SideNavigation";
import { Page } from "~/ui/layouts/Main.layout";
import SentryConsoleEvent from "../types/SentryConsoleEvent";
import DebugButton from "../ui/components/DebugButton";
import ErrorPageContent from "../ui/components/ErrorPageContent";
import { captureConsoleException } from "../utils/captureConsoleException";
import getMessageFromRouteError from "../utils/getMessageFromRouteError";

export default function ErrorPage() {
  const error = useRouteError() as RouteErrorType;

  useEffect(() => {
    console.error("[ErrorPage] Uncaught error:", error);
    captureConsoleException(SentryConsoleEvent.ErrorPageHit, error);
  }, [error]);

  return (
    <Page>
      <Flex height="100%" width="100%">
        <SideNavigation />
        <Box
          backgroundColor="gray.1100"
          height="100vh"
          overflow="scroll"
          p={4}
          position="relative"
          width="fill-available"
        >
          <Flex
            alignItems="center"
            flexDir={"column"}
            gridGap={"64px"}
            height="100%"
            justifyContent={{
              base: "unset",
              md: "center",
            }}
            mt={{ base: "0", md: "-96px" }}
            width="100%"
          >
            <ErrorPageContent />
            <Flex flexDir="column" maxW="900px" w="100%">
              <Alert status="error">
                <Flex flex={1} flexDir={"column"} gridGap={"18px"} w="100%">
                  <AlertTitle>Debug Information</AlertTitle>
                  <DebugButton
                    buttonText="Click here to copy debug info that can be shared with support."
                    error={error}
                  />
                  <AlertDescription>
                    <code>{getMessageFromRouteError(error)}</code>
                  </AlertDescription>
                </Flex>
              </Alert>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Page>
  );
}

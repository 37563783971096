import type { ErrorInfo, ReactNode } from "react";
import { Component } from "react";
import { Flex } from "@chakra-ui/react";

import SentryConsoleEvent from "../../types/SentryConsoleEvent";
import { captureConsoleException } from "../../utils/captureConsoleException";
import ErrorPageContent from "./ErrorPageContent";

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  error: Error | null;
};

class GlobalErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null,
    hasError: false,
  };

  static getDerivedStateFromError(error: Error): State {
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("[GlobalErrorBoundary] Uncaught error:", error, errorInfo);
    captureConsoleException(
      SentryConsoleEvent.GlobalErrorBoundaryHit,
      error,
      errorInfo,
    );
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          marginTop="-20px"
          minHeight="100vh"
          width="100%"
        >
          <ErrorPageContent />
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;

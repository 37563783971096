import { Suspense } from "react";
import { useToast } from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { useCopyToClipboard } from "usehooks-ts";

import type RouteErrorType from "../../types/RouteErrorType";
import getMessageFromRouteError from "../../utils/getMessageFromRouteError";
import useCharacterList from "../hooks/useCharacterList.hook";
import useUser from "../hooks/useUser.hook";
import HelpButton from "./HelpButton";

type DebugButtonComponentProps = {
  error?: RouteErrorType;
  buttonText?: string;
};

function DebugButtonComponent({
  buttonText = "Debug Account",
  error,
}: DebugButtonComponentProps) {
  const { user } = useUser();
  const toast = useToast();
  const [_, copy] = useCopyToClipboard();
  const { characterList } = useCharacterList();
  if (user == null || characterList.length === 0) {
    return null;
  }

  const errorInfo =
    error == null ? "No error info" : getMessageFromRouteError(error);

  return (
    <HelpButton
      Icon={FiCopy}
      onClick={async () => {
        await copy(
          JSON.stringify(
            {
              characterCount: characterList.length,
              currentPath: window.location.pathname,
              errorInfo,
              userId: user._id,
            },
            null,
            2,
          ),
        );
        toast({
          duration: 3000,
          isClosable: true,
          status: "success",
          title: "Debug info copied.",
        });
      }}
      text={buttonText}
    />
  );
}

export default function DebugButton(props: DebugButtonComponentProps) {
  return (
    <Suspense>
      <DebugButtonComponent {...props} />
    </Suspense>
  );
}

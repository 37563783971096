import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FiMessageCircle, FiThumbsUp } from "react-icons/fi";
import { Link } from "react-router-dom";

import type { Character } from "@charry/models";

// import useChatCount from "~/ui/hooks/useChatCount.hook";
import { useUsername } from "~/ui/hooks/useUsername.hook";
import CharacterAvatar from "./CharacterAvatar";

type CharacterCardProps = {
  character: Character;
  onClick?: () => void;
  columns?: number;
};

const CharacterCard: React.FC<CharacterCardProps> = ({
  character,
  onClick,
}) => {
  const linkProps = onClick
    ? { onClick, to: "" }
    : { to: `/character/${character.id}` };

  const { data: username } = useUsername(character.userId);

  return (
    <Link {...linkProps}>
      <Box
        backgroundColor="gray.900"
        borderRadius="16px"
        minWidth="312px"
        padding="4"
      >
        <Flex>
          <CharacterAvatar
            borderRadius="16px"
            character={character}
            height="114px"
            size="lg"
            width="90px"
          />
          <Box width="12px" />
          <Flex flexDirection="column" justifyContent="space-between">
            <Box>
              <Text color="gray.100" variant="14-semi">
                {character.name}
              </Text>
              <Text color="gray.400" mb="1" variant="12-reg">
                By @{username ? username.username : character.userId}
              </Text>
              <Text color="white" variant="12-reg">
                {character.title}
              </Text>
            </Box>
            <Box>
              <Flex alignItems="center">
                <FiMessageCircle className="text-gray-300" />
                {/* {character.turnCount ? (
                  <Skeleton ml="1" height="16px" width="42px" />
                ) : ( */}
                <Text color="gray.300" ml="1" mr="4" variant="12-reg">
                  {character.turnCount}
                </Text>
                {/* )} */}

                <FiThumbsUp className="text-gray-300" />
                {/* {character.upvotes ? (
                  <Skeleton ml="1" height="16px" width="42px" />
                ) : ( */}
                <Text color="gray.300" ml="1" variant="12-reg">
                  {character.upvotes}
                </Text>
                {/* )} */}
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Link>
  );
};

export default CharacterCard;

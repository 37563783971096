import { Box, Flex, Skeleton } from "@chakra-ui/react";

import { Header } from "../layouts/Page.layout";

export default function ChatDetailsPageSuspense() {
  return (
    <Flex flexDirection="column" height="100vh" overflow="hidden" width="100%">
      <Header
        backgroundColor="gray.1100"
        borderBottom="0px"
        height="96px"
        padding={{ base: "0 16px", md: "0 80px" }}
        position="sticky"
        top="0"
        zIndex="3"
      >
        <Box alignItems="center" display="flex">
          <Skeleton borderRadius="full" height="40px" mr={3} width="40px" />
          <Box>
            <Skeleton height="20px" mb={1} width="150px" />
            <Skeleton height="16px" width="100px" />
          </Box>
        </Box>
      </Header>
      <Flex
        flex="1"
        flexDirection="column"
        overflowY="auto"
        position="relative"
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          marginTop="32px"
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Skeleton borderRadius="full" height="80px" mb={3} width="80px" />
            <Skeleton height="20px" mb={1} width="150px" />
            <Skeleton height="16px" width="100px" />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          margin="0 auto"
          maxWidth="704px"
          padding={{ base: "16px", md: "32px" }}
          width="100%"
        >
          {[...Array(5)].map((_, index) => (
            <Flex
              flexDirection="column"
              key={index}
              padding="8px 4px"
              width="100%"
            >
              <Flex
                justifyContent={index % 2 === 0 ? "flex-start" : "flex-end"}
                width="100%"
              >
                <Flex
                  alignItems={index % 2 === 0 ? "flex-start" : "flex-end"}
                  flexDirection="column"
                  maxWidth="70%"
                >
                  <Flex
                    alignItems="center"
                    flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
                    mb={2}
                  >
                    <Skeleton
                      borderRadius="full"
                      height="28px"
                      ml={index % 2 === 0 ? 0 : 2}
                      mr={index % 2 === 0 ? 2 : 0}
                      width="28px"
                    />
                    <Skeleton height="16px" width="100px" />
                  </Flex>
                  <Skeleton borderRadius="md" height="60px" width="250px" />
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
      <Box
        backgroundColor="gray.1100"
        bottom="0"
        display="flex"
        justifyContent="center"
        padding={{ base: "16px", md: "32px" }}
        position="sticky"
        width="100%"
      >
        <Box maxWidth="660px" position="relative" width="100%">
          <Skeleton borderRadius="full" height="50px" width="100%" />
        </Box>
      </Box>
    </Flex>
  );
}

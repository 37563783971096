/* eslint-disable sort-keys-fix/sort-keys-fix */
import type { ThemeConfig } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import * as Polished from "polished";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const breakpoints = {
  sm: "30em",
  md: "48em",
  mlg: "49em",
  lg: "80em",
  xl: "96em",
};

const colors = {
  // white: 'black',
  // black: 'white',
  gray: {
    // Gray Normal
    // 25: '#FCFCFD',
    // 50: '#F9FAFB',
    // 100: '#F2F4F7',
    // 200: '#EAECF0',
    // 300: '#D0D5DD',
    // 400: '#98A2B3',
    // 500: '#667085',
    // 600: '#475467',
    // 700: '#344054',
    // 800: '#1D2939',
    // 900: '#101828',
    // 1000: '#0C121D',
    // 1100: '#070B12',

    // Gray Nuetral
    // 25: '#FCFCFD',
    // 50: '#F9FAFB',
    // 100: '#F3F4F6',
    // 200: '#E5E7EB',
    // 300: '#D2D6DB',
    // 400: '#9DA4AE',
    // 500: '#6C737F',
    // 600: '#4D5761',
    // 700: '#384250',
    // 800: '#1F2A37',
    // 900: '#111927',
    // 1000: '#0A1122',
    // 1100: '#0A1122',

    //Gray Iron
    25: "#FCFCFC",
    50: "#FAFAFA",
    100: "#F4F4F5",
    200: "#E4E4E7",
    300: "#D1D1D6",
    400: "#A0A0AB",
    500: "#70707B",
    600: "#51525C",
    700: "#3F3F46",
    800: "#26272B",
    900: "#18181B",
    1000: "#0F0F0F",
    1100: "#0A0A0A",

    // 1100: '#FCFCFC',
    // 1000: '#FAFAFA',
    // 900: '#F4F4F5',
    // 800: '#E4E4E7',
    // 700: '#D1D1D6',
    // 600: '#A0A0AB',
    // 500: '#70707B',
    // 400: '#51525C',
    // 300: '#3F3F46',
    // 200: '#26272B',
    // 100: '#18181B',
    // 50: '#0F0F0F',
    // 25: '#0A0A0A',
  },
  blue: {
    25: "#f5f8ff",
    50: "#eff4ff",
    100: "#d1eoff",
    200: "#b2ccff",
    300: "#84adff",
    400: "#528bff",
    500: "#2970ff",
    600: "#155eef",
    700: "#004eeb",
    800: "#0040c1",
    900: "#00359e",
  },

  green: {
    50: "#F0FFF4",
    100: "#C6F6D5",
    200: "#9AE6B4",
    300: "#68D391",
    400: "#48BB78",
    500: "#38A169",
    600: "#2F855A",
    700: "#276749",
    800: "#22543D",
    900: "#1C4532",
  },

  red: {
    50: "#FFF5F5",
    100: "#FED7D7",
    200: "#FEB2B2",
    300: "#FC8181",
    400: "#F56565",
    500: "#E53E3E",
    600: "#C53030",
    700: "#9B2C2C",
    800: "#822727",
    900: "#63171B",
  },
};

const theme = extendTheme({
  config,
  colors,
  breakpoints,
  styles: {
    global: {
      "html, body": {
        backgroundColor: colors.gray[1000],
        overflow: "hidden",
      },
    },
  },
  components: {
    Button: {
      variants: {
        primary: {
          color: "#fff",
          colorScheme: "blue",
          backgroundColor: "blue.600",
          borderRadius: "8px",
          _hover: {
            backgroundColor: Polished.lighten(0.05, colors.blue[600]),
          },
          _focus: {
            backgroundColor: Polished.darken(0.05, colors.blue[600]),
          },
          _disabled: {
            backgroundColor: `${colors.gray[700]} !important`,
            cursor: "not-allowed",
          },
        },
        error: {
          color: "#fff",
          colorScheme: "red",
          backgroundColor: "red.600",
          borderRadius: "8px",
          _hover: {
            backgroundColor: Polished.lighten(0.05, colors.red[600]),
          },
          _focus: {
            backgroundColor: Polished.darken(0.05, colors.red[600]),
          },
          _disabled: {
            backgroundColor: `${colors.gray[700]} !important`,
            cursor: "not-allowed",
          },
        },
        secondary: {
          color: "white",
          backgroundColor: "gray.1000",
          border: "1px solid",
          borderColor: "gray.800",
          borderRadius: "8px",
          _hover: {
            backgroundColor: "gray.800",
            borderColor: "gray.600",
          },
          _focus: {
            backgroundColor: "gray.900",
            border: "1px solid",
            borderColor: "gray.600",
          },
        },
        ghost: {
          color: "white",
          backgroundColor: "transparent",
          borderRadius: "8px",
          _hover: {
            backgroundColor: Polished.lighten(0.05, colors.gray[900]),
            borderColor: Polished.lighten(0.05, colors.gray[800]),
          },
        },
      },
      sizes: {
        lg: {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          height: "44px",
        },
      },
    },
    ModalContent: {
      baseStyle: {
        backgroundColor: "black",
      },
    },
    Text: {
      variants: {
        "10-reg": {
          fontSize: "10px",
          lineHeight: "16px",
          fontWeight: "400",
        },
        "12-reg": {
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: "400",
        },
        "14-reg": {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "400",
        },
        "16-reg": {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "400",
        },
        "18-reg": {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "400",
        },
        "20-reg": {
          fontSize: "20px",
          lineHeight: "30px",
          fontWeight: "400",
        },
        "24-reg": {
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: "400",
        },
        "30-reg": {
          fontSize: "30px",
          lineHeight: "32px",
          fontWeight: "400",
        },
        "36-reg": {
          fontSize: "36px",
          lineHeight: "44px",
          fontWeight: "400",
        },
        "10-med": {
          fontSize: "10px",
          lineHeight: "16px",
          fontWeight: "500",
        },
        "12-med": {
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: "500",
        },
        "14-med": {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "500",
        },
        "16-med": {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "500",
        },
        "18-med": {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "500",
        },
        "20-med": {
          fontSize: "20px",
          lineHeight: "30px",
          fontWeight: "500",
        },
        "24-med": {
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: "500",
        },
        "30-med": {
          fontSize: "30px",
          lineHeight: "32px",
          fontWeight: "500",
        },
        "36-med": {
          fontSize: "36px",
          lineHeight: "44px",
          fontWeight: "500",
        },
        "10-semi": {
          fontSize: "10px",
          lineHeight: "16px",
          fontWeight: "600",
        },
        "12-semi": {
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: "600",
        },
        "14-semi": {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "600",
        },
        "16-semi": {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
        },
        "18-semi": {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "600",
        },
        "20-semi": {
          fontSize: "20px",
          lineHeight: "30px",
          fontWeight: "600",
        },
        "24-semi": {
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: "600",
        },
        "30-semi": {
          fontSize: "30px",
          lineHeight: "32px",
          fontWeight: "600",
        },
        "36-semi": {
          fontSize: "36px",
          lineHeight: "44px",
          fontWeight: "600",
        },
        "10-bold": {
          fontSize: "10px",
          lineHeight: "16px",
          fontWeight: "700",
        },
        "12-bold": {
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: "700",
        },
        "14-bold": {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "700",
        },
        "16-bold": {
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "700",
        },
        "18-bold": {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "700",
        },
        "20-bold": {
          fontSize: "20px",
          lineHeight: "30px",
          fontWeight: "700",
        },
        "24-bold": {
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: "700",
        },
        "30-bold": {
          fontSize: "30px",
          lineHeight: "32px",
          fontWeight: "700",
        },
        "36-bold": {
          fontSize: "36px",
          lineHeight: "44px",
          fontWeight: "700",
        },
      },
      defaultProps: {
        variant: "16-reg",
      },
    },
  },
});

export { theme };

import { z } from "zod";

import { AvatarSchema_CAI } from "./other.cai";

// Promts schema
export const PromptsSchema_CAI = z.object({
  phrases: z.array(z.unknown()),
});

const CharacterCategoryEnum = z.enum([
  "Helpers",
  "Anime Game Characters",
  "Games",
  "Anime",
  "Game Characters",
  "Books",
  "Comedy",
  "Image Generating",
  "Celebrities",
  "Vtuber",
  "Language Learning",
  "Discussion",
  "History",
  "Religion",
  "Animals",
  "Philosophy",
  "Politics",
  "Other",
]);

// Character schema
export const CharacterSchema_CAI = AvatarSchema_CAI.extend({
  avatar_file_name: z.string().nullable(),
  base_img_prompt: z.string().nullable(),
  comments_enabled: z.boolean(),
  copyable: z.boolean(),
  default_voice_id: z.string().nullable(),
  definition: z.string().optional(),
  description: z.string().nullable(),
  external_id: z.string(),
  greeting: z.string(),
  identifier: z.string(),
  img_gen_enabled: z.boolean(),
  img_prompt_regex: z.string().nullable(),
  name: z.string(),
  participant__name: z.string(),
  participant__num_interactions: z.number().optional(),
  participant__user__username: z.string(),
  songs: z.array(z.unknown()),
  starter_prompts: PromptsSchema_CAI.nullable(),
  strip_img_prompt_from_msg: z.boolean(),
  title: z.string().nullable(),
  upvotes: z.number().optional(),
  usage: z.string().optional(),
  user__username: z.string(),
  visibility: z.string(),
  voice_id: z.string().nullable(),
});

// CharShort schema
export const CharShortSchema_CAI = AvatarSchema_CAI.extend({
  avatar_file_name: z.string().nullable(),
  category: CharacterCategoryEnum,
  copyable: z.union([z.string(), z.boolean()]).optional(),
  default_voice_id: z.string().optional(),
  description: z.string().nullable().optional(),
  external_id: z.string(),
  greeting: z.string(),
  img_gen_enabled: z.boolean(),
  max_last_interaction: z.date().optional(),
  participant__name: z.string(),
  participant__num_interactions: z.number().optional(),
  tags: z.array(z.string()).optional(),
  title: z.string().nullable(),
  upvotes: z.number().optional(),
  user__id: z.number().optional(),
  user__username: z.string(),
  visibility: z.string().optional(),
});

// Categories schema
export const CategoriesSchema_CAI = z.object({
  animals: z.array(CharShortSchema_CAI),
  anime: z.array(CharShortSchema_CAI),
  anime_game: z.array(CharShortSchema_CAI),
  books: z.array(CharShortSchema_CAI),
  chinese: z.array(CharShortSchema_CAI),
  comedy: z.array(CharShortSchema_CAI),
  discussion: z.array(CharShortSchema_CAI),
  famous: z.array(CharShortSchema_CAI),
  game: z.array(CharShortSchema_CAI),
  games: z.array(CharShortSchema_CAI),
  helpers: z.array(CharShortSchema_CAI),
  image: z.array(CharShortSchema_CAI),
  movies: z.array(CharShortSchema_CAI),
  philosophy: z.array(CharShortSchema_CAI),
  politics: z.array(CharShortSchema_CAI),
  religion: z.array(CharShortSchema_CAI),
  vtuber: z.array(CharShortSchema_CAI),
});

// Type definitions
export type Prompts_CAI = z.infer<typeof PromptsSchema_CAI>;
export type Character_CAI = z.infer<typeof CharacterSchema_CAI>;
export type CharShort_CAI = z.infer<typeof CharShortSchema_CAI>;
export type Categories_CAI = z.infer<typeof CategoriesSchema_CAI>;

import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import posthog from "posthog-js";

import type {
  ApiResponseUserWithSubscription,
  LoginUserParams,
  RegisterUserParams,
} from "@charry/models";
import { utils } from "@charry/utils";

import { trpc } from "~/lib/trpc";

const registerUserParams = atomWithDefault<RegisterUserParams>((get) =>
  get(
    atom<RegisterUserParams>({
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      registrationMethod: "PASSWORD",
    }),
  ),
);

const loginUserParams = atomWithDefault<LoginUserParams>((get) =>
  get(
    atom<LoginUserParams>({
      email: "",
      password: "",
    }),
  ),
);

const user = atomWithDefault<ApiResponseUserWithSubscription | null>((get) =>
  get(
    atom(async () => {
      try {
        const { user } = await trpc.user.get.query();
        if (user != null) {
          const properties = utils.getPosthogUserProperties(user);
          posthog.identify(user._id, properties);
        }
        return user ?? null;
      } catch (e) {
        console.error("Failed to fetch user: ", e);
        return null;
      }
    }),
  ),
);

const UserState = {
  loginUserParams,
  registerUserParams,
  user,
};

export default UserState;

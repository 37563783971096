import React from "react";
import { useAtom } from "jotai";

import type { ModalConfig } from "../components/modal/Modal";
import { AppState } from "../jotai/app.jotai";

export default function useModal() {
  const [modals, setModals] = useAtom(AppState.modals);

  const push = React.useCallback(
    (config: ModalConfig) => {
      setModals((modals) => [...modals, config]);
    },
    [setModals],
  );

  const pop = React.useCallback(() => {
    setModals((modals) => {
      const newModals = [...modals];
      newModals.pop();
      return newModals;
    });
  }, [setModals]);

  const replace = React.useCallback(
    (config: ModalConfig) => {
      setModals((modals) => {
        const newModals = [...modals];
        newModals.pop();
        newModals.push(config);
        return newModals;
      });
    },
    [setModals],
  );

  return {
    modals,
    pop,
    push,
    replace,
  };
}

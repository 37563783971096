import React from "react";
import { useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { useAtom } from "jotai";
import posthog from "posthog-js";

import type { AuthenticationResponse } from "@charry/models";
import { isSuperUser, utils } from "@charry/utils";

import PosthogEvent from "~/constants/PosthogEvent.constants";
import AuthUtil from "~/lib/auth";
import useTracking from "~/ui/hooks/useTracking.hook";
import { api, trpc } from "../../lib/trpc";
import UserState from "../jotai/user.jotai";
import useIsAuthenticated from "./useIsAuthenticated.hook";

export default function useUser() {
  const toast = useToast();
  const [user, setUser] = useAtom(UserState.user);
  const { isAuthenticated } = useIsAuthenticated();
  const isSuper = isSuperUser(user?.email);
  const { track } = useTracking();

  const registerUser = api.user.register.useMutation();
  const [registerUserParams, setRegisterUserParams] = useAtom(
    UserState.registerUserParams,
  );

  const loginUser = api.user.login.useMutation();
  const [loginUserParams, setLoginUserParams] = useAtom(
    UserState.loginUserParams,
  );

  const refreshUser = React.useCallback(async () => {
    try {
      const { user } = await trpc.user.get.query();
      if (user != null) {
        void setUser(user);
      }
      return user;
    } catch (err: unknown) {
      console.error(err);
      return null;
    }
  }, [setUser]);

  const authenticate = React.useCallback(
    ({ token, user }: Pick<AuthenticationResponse, "token" | "user">) => {
      if (!user || !token) {
        toast({
          description:
            "There was an error signing you in. Please notify support.",
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Authentication Error",
        });
        return;
      }

      AuthUtil.setToken(token);
      void setUser(user);

      const properties = utils.getPosthogUserProperties(user);
      posthog.identify(user._id, properties);

      Sentry.setUser({ email: user.email, id: user._id });
    },
    [setUser, toast],
  );

  const logout = React.useCallback(() => {
    track({
      event: PosthogEvent.AuthLogout,
    });
    posthog.reset();
    Sentry.setUser(null);
    AuthUtil.clearToken();
    window.location.href = "/";
  }, [track]);

  const refresh = React.useCallback(() => {
    window.location.reload();
  }, []);

  const superImpersonate = api.user.register.useMutation({
    onSuccess: (data) => {
      authenticate(data);
      window.location.href = "/dashboard/reports";
    },
  });

  return {
    authenticate,
    isAuthenticated,
    isSuper,
    login: loginUser,
    loginUserParams,
    logout,
    refresh,
    refreshUser,
    register: registerUser,
    registerUserParams,
    setLoginUserParams,
    setRegisterUserParams,
    superImpersonate,
    user,
  };
}

import util from "util";
import { Duration } from "luxon";

import type { ApiResponseUser } from "@charry/models";

import { DateTime, time } from "./time";

// NOTE: These utils are also imported on the client so it's a bit more complicated
// to make this value an environment variable.
const MIN_MINUTES_TO_RESEND_EMAIL_VERIFICATION = 1;

function canResendEmailVerification(
  emailVerificationTokenRequestedAt: Date | null,
): boolean {
  if (emailVerificationTokenRequestedAt == null) {
    return true;
  }

  const diffMinutes = time
    .currentDate()
    .diff(DateTime.fromJSDate(emailVerificationTokenRequestedAt), "minutes");
  return diffMinutes.minutes > MIN_MINUTES_TO_RESEND_EMAIL_VERIFICATION;
}

function getCanResendEmailVerificationDatetime(
  emailVerificationTokenRequestedAt: Date | null,
): DateTime | null {
  if (emailVerificationTokenRequestedAt == null) {
    return null;
  }

  const duration = Duration.fromObject({
    minutes: MIN_MINUTES_TO_RESEND_EMAIL_VERIFICATION,
  });
  return DateTime.fromJSDate(emailVerificationTokenRequestedAt).plus(duration);
}

type PosthogUserProperties = {
  email: string;
  emailVerificationRequired: boolean;
  createdAt: Date | null;
  isEmailVerified: boolean;
  name: string;
};

function getPosthogUserProperties(
  user: ApiResponseUser,
): PosthogUserProperties {
  const {
    createdAt,
    email,
    emailVerificationRequired,
    emailVerifiedAt,
    firstName,
    lastName,
  } = user;
  const name = `${firstName} ${lastName}`;
  const properties: PosthogUserProperties = {
    createdAt: createdAt == null ? null : new Date(createdAt),
    email,
    emailVerificationRequired,
    isEmailVerified: emailVerifiedAt != null,
    name,
  };
  return properties;
}

function logEntireObject(obj: unknown): void {
  console.info(
    util.inspect(obj, {
      colors: true,
      depth: null,
      showHidden: false,
    }),
  );
}

const MAX_USERNAME_LENGTH = 25;

function cleanUsername(username: string): string {
  // Remove any non-alphanumeric characters except underscores
  let cleaned = username.replace(/[^a-zA-Z0-9_]/g, "");

  // Ensure the username starts with a letter
  cleaned = cleaned.replace(/^[^a-zA-Z]+/, "");

  // Truncate to the maximum length
  cleaned = cleaned.slice(0, MAX_USERNAME_LENGTH);

  return cleaned;
}

export const utils = {
  canResendEmailVerification,
  cleanUsername,
  getCanResendEmailVerificationDatetime,
  getPosthogUserProperties,
  logEntireObject,
};

import { Box, Flex, Heading, Link } from "@chakra-ui/react";

import { withSuspense } from "~/ui/hoc/withSuspense";
import MarketingLayout from "~/ui/layouts/Marketing.layout";
import { DISCORD_INVITE_LINK } from "../../lib/const";

type SupportPageProps = {
  hideLayout?: boolean;
};

const SupportPage = ({ hideLayout = false }: SupportPageProps) => {
  const email = "support@charry.ai";
  const subject = "Charry Support Request";
  const body = `Please describe your issue or question here:%0D%0A%0D%0A

  I'm having problems with ...%0D%0A%0D%0A
  
  The email for my Charry account is:%0D%0APLEASE_FILL_IN.%0D%0A%0D%0A
  My username is:%0D%0APLEASE_FILL_IN.%0D%0A`;

  const Content = (
    <Flex flexDirection="column" gridGap={4} pb={8}>
      <Heading fontWeight="bold" size="xl">
        Charry Support
      </Heading>

      <Box>
        <Link
          href={`mailto:${email}?subject=${subject}&body=${body}`}
          isExternal
        >
          If you need help with Charry, please click here to compose an email to
          our support team at {email}.
        </Link>
      </Box>

      <Box>
        <Link href={DISCORD_INVITE_LINK} isExternal>
          If you'd like more realtime support, please join our Discord server by
          clicking here.
        </Link>
      </Box>
    </Flex>
  );

  if (hideLayout) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        overflow="auto"
        width="100%"
      >
        <Box
          maxWidth="100%"
          paddingBottom={24}
          paddingLeft={12}
          paddingRight={12}
          width={{ md: "1000px", sm: "100%" }}
        >
          {Content}
        </Box>
      </Box>
    );
  }

  return (
    <MarketingLayout>
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        overflow="auto"
        width="100%"
      >
        <Box
          maxWidth="100%"
          paddingBottom={32}
          px={4}
          width={{ md: "1000px", sm: "100%" }}
        >
          {Content}
        </Box>
      </Box>
    </MarketingLayout>
  );
};

export default withSuspense(SupportPage);

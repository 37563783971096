import type RouteErrorType from "../types/RouteErrorType";

export default function getMessageFromRouteError(
  error: RouteErrorType,
): string {
  if (error instanceof Error) {
    return error.message;
  }

  if (error.statusText != null) {
    return `${error.statusText} - ${error.message}`;
  }

  try {
    return JSON.stringify(error);
  } catch (_) {
    // No action.
  }

  return "Unknown error.";
}

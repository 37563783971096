import React, { Suspense } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { FiMessageCircle } from "react-icons/fi";
import { Link, Outlet, useParams } from "react-router-dom";

import SubNavigationButton from "~/ui/elements/SubNavigationButton";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import useCharacter from "../hooks/useCharacter.hook";
import useChat from "../hooks/useChat.hook";
import CharacterDetailsPageSuspense from "../suspense/CharacterDetailsPage.suspense";

const CharacterActionButtons = ({ characterId }: { characterId: string }) => {
  const { character } = useCharacter(characterId);
  const { createChat } = useChat();

  if (!character) return null;

  const buttons = [];

  buttons.push(
    <Button
      isLoading={createChat.isPending}
      leftIcon={<FiMessageCircle />}
      onClick={async () => {
        await createChat.mutateAsync({
          characterId,
        });
      }}
      size="sm"
      variant="primary"
    >
      New Chat
    </Button>,
  );

  return <Flex gap={1}>{buttons}</Flex>;
};

const CharacterLayoutComponent: React.FC = () => {
  const isMobile = useIsMobile();
  const { characterId } = useParams();
  const { character } = useCharacter(characterId ?? "");

  if (!character) {
    return (
      <Flex
        alignItems="center"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <Spinner color="blue.500" size="xl" />
      </Flex>
    );
  }

  return (
    <>
      <Header>
        <Box alignItems="center" display="flex">
          <Link to="/dashboard/characters">
            <Text color="gray.300" variant="16-med">
              Characters
            </Text>
          </Link>
          <Box width="8px" />
          <ChevronRightIcon color="gray.300" fontSize="18px" />
          <Box width="8px" />
          <Text variant="16-med">{character.name}</Text>
        </Box>
        <CharacterActionButtons characterId={character.id} />
      </Header>
      <Content>
        <Box alignItems="center" display="flex" marginTop="32px">
          <Box>
            <Title>{character.name}</Title>
            <Text color="gray.400" variant="14-reg"></Text>
          </Box>
        </Box>
        <Box height="24px" />
        <Box
          borderBottom="1px solid"
          borderColor="gray.800"
          display="flex"
          paddingBottom="8px"
          width="100%"
        >
          {isMobile ? (
            <>
              <Box display="flex" width="min-content">
                <SubNavigationButton
                  label="Main"
                  link={`/character/${character.id}`}
                />
                <SubNavigationButton
                  label="Instances"
                  link={`/dashboard/characters/instances/${character.id}`}
                />
                <SubNavigationButton
                  label="Logs"
                  link={`/dashboard/characters/logs/${character.id}`}
                />
                <SubNavigationButton
                  label="Gens"
                  link={`/dashboard/characters/generations/${character.id}`}
                />
                <SubNavigationButton
                  label="Benchmarks"
                  link={`/dashboard/characters/benchmarks/${character.id}`}
                />
                <SubNavigationButton
                  label="Settings"
                  link={`/dashboard/characters/settings/${character.id}`}
                />
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" width="min-content">
                <SubNavigationButton
                  label="Overview"
                  link={`/dashboard/characters/details/${character.id}`}
                />
                <SubNavigationButton
                  label="Instances"
                  link={`/dashboard/characters/instances/${character.id}`}
                />
                <SubNavigationButton
                  label="Logs"
                  link={`/dashboard/characters/logs/${character.id}`}
                />
                <SubNavigationButton
                  label="Generations"
                  link={`/dashboard/characters/generations/${character.id}`}
                />
                <SubNavigationButton
                  label="Benchmarks"
                  link={`/dashboard/characters/benchmarks/${character.id}`}
                />
                <SubNavigationButton
                  label="Settings"
                  link={`/dashboard/characters/settings/${character.id}`}
                />
              </Box>
            </>
          )}
        </Box>
      </Content>
      <Content>
        <Outlet />
        <Box height="200px" />
      </Content>
    </>
  );
};

export default function CharacterLayout() {
  return (
    <Suspense fallback={<CharacterDetailsPageSuspense />}>
      <CharacterLayoutComponent />
    </Suspense>
  );
}

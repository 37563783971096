import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../../lib/trpc";
import useIsAuthenticated from "./useIsAuthenticated.hook";

export default function useLoginSuccessRedirect() {
  const navigate = useNavigate();
  const { isAuthenticated } = useIsAuthenticated();
  const workersQuery = api.character.explore.useQuery(undefined, {
    enabled: isAuthenticated,
  });

  const loginSuccessRedirect = useCallback(async () => {
    try {
      const { data } = await workersQuery.refetch();
      // always navigate home for now
      if (data?.characters != null && data.characters.length === 0) {
        navigate("/");
      } else {
        navigate("/");
      }
    } catch (e) {
      console.error(`Failed to redirect after login`, e);
      navigate("/dashboard/overview");
    }
  }, [navigate, workersQuery]);

  return { loginSuccessRedirect };
}

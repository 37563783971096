import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";

type CategoryButtonListProps = {
  categories: string[];
  selectedCategory: string;
  onCategorySelect: (category: string) => void;
};

const CategoryButtonList: React.FC<CategoryButtonListProps> = ({
  categories,
  onCategorySelect,
  selectedCategory,
}) => {
  return (
    <Flex alignItems="center" mb={4}>
      <Flex
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
        overflowX="auto"
      >
        <Flex>
          {categories.map((category) => (
            <Box key={category} minWidth="auto" mr={2}>
              <Button
                colorScheme={selectedCategory === category ? "blue" : "gray"}
                onClick={() => onCategorySelect(category)}
                size="lg"
                variant={selectedCategory === category ? "solid" : "outline"}
                whiteSpace="nowrap"
              >
                {category}
              </Button>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CategoryButtonList;

import React from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useLockedBody } from "usehooks-ts";

import CharryLogo from "~/assets/charry.svg";
import DiscordLogo from "~/assets/discord.svg";
import TwitterLogo from "~/assets/twitter.svg";
import { DISCORD_INVITE_LINK, TWITTER_LINK } from "~/lib/const";
import useIsAuthenticated from "~/ui/hooks/useIsAuthenticated.hook";
import { Header } from "~/ui/layouts/Page.layout";

const nav = [
  {
    image: <Image height="24px" src={DiscordLogo} width="24px" />,
    isExternal: true,
    link: DISCORD_INVITE_LINK,
    title: "Discord",
  },
  {
    image: <Image height="24px" src={TwitterLogo} width="24px" />,
    isExternal: true,
    link: TWITTER_LINK,
    title: "Twitter",
  },
];

const Desktop: React.FC = () => {
  const { isAuthenticated } = useIsAuthenticated();

  return (
    <Flex
      alignItems="center"
      display={{ base: "none", md: "flex" }}
      justifyContent="flex-end"
    >
      <Grid
        display={{ base: "none", md: "flex" }}
        gap={6}
        justifyContent="center"
        marginRight="32px"
        templateColumns={`repeat(${3}, 1fr)`}
      >
        {nav.map((item, index) => {
          return (
            <GridItem key={index}>
              <Link to={item.link}>
                <Box fontSize="14px">{item.title}</Box>
              </Link>
            </GridItem>
          );
        })}
      </Grid>
      {isAuthenticated ? (
        <Link to="/dashboard/overview">
          <Button
            fontSize="14px"
            fontWeight="500"
            size="sm"
            variant="secondary"
          >
            Login / Register
          </Button>
        </Link>
      ) : (
        <>
          <Link to="/login">
            <Button fontSize="14px" fontWeight="500" size="sm" variant="ghost">
              Login
            </Button>
          </Link>
          <Box width="8px" />
          <Link to="/register">
            <Button
              fontSize="14px"
              fontWeight="500"
              size="sm"
              variant="secondary"
            >
              Register
            </Button>
          </Link>
        </>
      )}
    </Flex>
  );
};

const Mobile: React.FC = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const [locked, setLocked] = useLockedBody(false, "root");

  return (
    <Box display={{ base: "block", md: "none" }}>
      {locked ? (
        <FiX fontSize="24px" onClick={() => setLocked(false)} />
      ) : (
        <FiMenu fontSize="24px" onClick={() => setLocked(true)} />
      )}
      {locked ? (
        <Box
          backgroundColor="black"
          display="border-box"
          height="100vh"
          left="0px"
          padding="24px"
          position="absolute"
          top="160px"
          width="100vw"
          zIndex="100"
        >
          <Grid gap={8} templateColumns="repeat(1, auto)">
            {nav.map((item, index) => {
              return (
                <GridItem key={index}>
                  <Link onClick={() => setLocked(false)} to={item.link}>
                    <Text
                      _hover={{
                        color: "white",
                        cursor: "pointer",
                        transition: "0.1s",
                      }}
                      color="gray.300"
                      key={index}
                      variant="24-reg"
                    >
                      {item.title}
                    </Text>
                  </Link>
                </GridItem>
              );
            })}
            {isAuthenticated ? (
              <GridItem>
                <Link to="/dashboard/overview">
                  <Text
                    _hover={{
                      color: "white",
                      cursor: "pointer",
                      transition: "0.1s",
                    }}
                    color="gray.300"
                    variant="24-reg"
                  >
                    Login / Register
                  </Text>
                </Link>
              </GridItem>
            ) : (
              <>
                <GridItem>
                  <Link to="/login">
                    <Text
                      _hover={{
                        color: "white",
                        cursor: "pointer",
                        transition: "0.1s",
                      }}
                      color="gray.300"
                      variant="24-reg"
                    >
                      Login
                    </Text>
                  </Link>
                </GridItem>
                <GridItem>
                  <Link to="/register">
                    <Text
                      _hover={{
                        color: "white",
                        cursor: "pointer",
                        transition: "0.1s",
                      }}
                      color="gray.300"
                      variant="24-reg"
                    >
                      Register
                    </Text>
                  </Link>
                </GridItem>
              </>
            )}
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};

const MarketingNavigation: React.FC = () => {
  return (
    <Header height="80px" width={{ base: "100%", md: "1280px" }}>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Link to="/">
          <Flex alignItems="center">
            <Image
              alt="Charry Logo"
              height="32px"
              src={CharryLogo}
              width="32px"
            />
            <Box width="12px" />
            <Text variant="24-bold">Charry.ai</Text>
          </Flex>
        </Link>
        <Mobile />
        <Desktop />
      </Flex>
    </Header>
  );
};

export default MarketingNavigation;

import { Box, Skeleton } from "@chakra-ui/react";

import { Content } from "../layouts/Page.layout";
import CharactersDetailContent from "./CharactersDetailContent.suspense";
import PageLayoutSuspense from "./PageLayout.suspense";

export default function CharacterDetailsPageSuspense() {
  return (
    <>
      <PageLayoutSuspense pageTitle="Characters">
        <Content>
          <Box height="32px" />
          <Skeleton borderRadius="lg" height="22px" width="20%" />
          <Box height="32px" />
          <Skeleton borderRadius="lg" height="22px" width="50%" />
          <Box height="32px" />
          <CharactersDetailContent />
        </Content>
      </PageLayoutSuspense>
    </>
  );
}

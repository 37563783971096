function range(len: number): number[] {
  return [...new Array(len).keys()];
}

function filterNulls<T>(arr: (T | null)[]): T[] {
  return arr.filter((item) => item != null) as T[];
}

function groupBy<T>(arr: T[], property: keyof T): Record<string, T[]> {
  return arr.reduce(
    (groups, el) => {
      const key = String(el[property]);
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(el);
      return groups;
    },
    {} as Record<string, T[]>,
  );
}

function groupByUniqueValues<T>(
  arr: T[],
  property: keyof T,
  disallowNullOrUndefined = false,
): Record<string, T> {
  return arr.reduce(
    (groups, el) => {
      const value = el[property];
      if (disallowNullOrUndefined) {
        const stringValue = String(value);
        const stringProperty = String(property);
        if (value == null) {
          throw new Error(
            `Values must not be null or undefined, received: ${stringValue} for key: ${stringProperty}.`,
          );
        }
      }
      const key = String(value);
      if (key in groups) {
        throw new Error(`Duplicate key: ${key} found in array.`);
      }
      groups[key] = el;
      return groups;
    },
    {} as Record<string, T>,
  );
}

function shuffle<T>(arr: T[]): T[] {
  return arr.sort(() => Math.random() - 0.5);
}

export const arrays = {
  filterNulls,
  groupBy,
  groupByUniqueValues,
  range,
  shuffle,
};

import type { ReactNode } from "react";
import { Box, Text } from "@chakra-ui/react";

export const Header = ({ children }: { children: ReactNode }) => (
  <Box borderBottom="1px solid " borderColor="gray.800" height="56px" w="100%">
    {children}
  </Box>
);

export const Title = ({ children }: { children: ReactNode }) => (
  <Text color="white" variant="20-bold">
    {children}
  </Text>
);

export const Page = ({ children }: { children: ReactNode }) => (
  <Box
    alignItems="center"
    color="white"
    display="flex"
    flexDirection="column"
    minHeight={{
      base: "calc(100% - 64px)",
      md: "calc(100% - 80px)",
    }}
    w="100%"
  >
    {children}
  </Box>
);

export const Document = ({
  backgroundColor,
  children,
  minHeight,
  padding,
}: {
  children: ReactNode;
  backgroundColor?: string | { base: string; md: string };
  padding?: string | { base?: string; md?: string; lg?: string };
  minHeight?: string;
}) => (
  <Box
    alignItems="center"
    backgroundColor={backgroundColor}
    display="flex"
    flexDirection="column"
    height="fill-available"
    minHeight={minHeight ?? "100vh"}
    width="100%"
  >
    <Box
      padding={padding ?? { base: "0 16px", md: "0 32px" }}
      width={{ base: "100%", lg: "800px" }}
    >
      {children}
    </Box>
  </Box>
);

type MainLayoutProps = {
  children: ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return <>{children}</>;
};

import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";

import type { CreateChatParams } from "@charry/models";

const createChatParams = atomWithDefault<CreateChatParams>((get) => {
  return get(
    atom<CreateChatParams>({
      characterId: "",
    }),
  );
});

// const updateChatParams = atomFamily((chatId: string) => {
//   const chatState: UpdateChatParams = {
//     id: chatId,
//   };

//   return atomWithDefault<UpdateChatParams>(async () => {
//     try {
//       const { chats } = await trpc.chat.list.query();
//       const chat = chats?.find((c) => c.id === chatId);

//       if (!chat) {
//         return chatState;
//       }

//       return chat;
//     } catch (e) {
//       return chatState;
//     }
//   });
// });

export const ChatState = {
  createChatParams,
  // updateChatParams,
};

import React from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import parseMutationError from "~/lib/parseMutationError";
import { api } from "~/lib/trpc";
import Input from "~/ui/elements/Input";

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [email, setEmail] = React.useState<string>("");
  const [errors, setErrors] = React.useState<Record<string, unknown>>({});
  const [loading, setLoading] = React.useState(false);

  const startResetUserPassword = api.user.startReset.useMutation();

  const startResetPassword = async () => {
    setLoading(true);
    try {
      const res = await startResetUserPassword.mutateAsync({ email });

      if (res.success) {
        toast({
          description:
            "Follow the instructions in the email to reset your password.",
          duration: 5000,
          isClosable: true,
          status: "success",
          title: "Reset Email Sent",
        });

        navigate("/login");
      }
    } catch (e1) {
      setLoading(false);
      try {
        const parsedError = parseMutationError(e1);
        setErrors(parsedError);
        toast({
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Invalid Fields",
        });
      } catch (e2) {
        toast({
          description: (e1 as Error).message,
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Error Logging In",
        });
      }
    }
  };

  return (
    <Box
      // onClick={() => toggleColorMode()}
      backgroundColor="gray.1000"
      display="flex"
      height="100vh"
      justifyContent="center"
      width="100vw"
    >
      <Flex
        alignItems="center"
        borderRadius="8px"
        boxSizing="border-box"
        flexDirection="column"
        height="fit-content"
        position="relative"
        top={{ base: "48px", md: "96px" }}
        width={{ base: "90%", md: "420px" }}
      >
        {/* <Link to="/">
          <Image
            alt="Logo"
            src={"/logos/open-rates-icon.png"}
            width="48"
            height="48"
            style={{
              maxWidth: "48px",
              height: "48px",
              borderRadius: "8px",
            }}
          />
        </Link> */}
        <Box height="24px" />
        <Text color="white" marginLeft="16px" variant="30-bold">
          Reset Password
        </Text>
        <Box height="12px" />
        <Text color="gray.400" marginLeft="16px" variant="16-reg">
          Enter your email address below to get started
        </Text>
        <Box height="32px" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void startResetPassword();
          }}
          style={{ width: "100%" }}
        >
          <Stack spacing="20px" width="100%">
            <Input
              autoFocus
              id="email"
              isInvalid={errors.email ? true : false}
              label="Email"
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder="Enter your email"
              size="lg"
              type="email"
              value={email}
              width="100%"
            />
          </Stack>
          <Stack spacing="0" width="100%">
            <Box height="24px" />
            {loading ? (
              <Flex alignItems="center" height="104px" justify="center">
                <Spinner color="blue.500" size="lg" />
              </Flex>
            ) : (
              <>
                <Button
                  isLoading={loading}
                  size="lg"
                  type="submit"
                  variant="primary"
                  width="100%"
                >
                  Send Reset Link
                </Button>
                <Box height="32px" />
                <Text align="center" color="white" variant="14-reg">
                  Remembered?&nbsp;
                  <Link to="/login">
                    <Text as="span" color="#2970FF" variant="14-bold">
                      Login
                    </Text>
                  </Link>
                </Text>
              </>
            )}
          </Stack>
        </form>
      </Flex>
    </Box>
  );
};

export default ForgotPasswordPage;

import type {
  AuthenticationResponse,
  AuthenticationStrategy,
} from "@charry/models";
import { zAuthenticationStrategy } from "@charry/models";

import { API_URL } from "./const";

const CHARRY_JWT = "charry-jwt";

export const setToken = (token: string): void => {
  localStorage.setItem(CHARRY_JWT, token);
};

export const getToken = (): string | null => {
  return localStorage.getItem(CHARRY_JWT) ?? null;
};

export const clearToken = (): void => {
  localStorage.removeItem(CHARRY_JWT);
};

export const isAuthenticated = (): boolean => {
  return Boolean(getToken());
};

/** ******************************************************************************
 *  OAuth
 ****************************************************************************** */

export const CONTEXT_API_URL = API_URL;

export const GOOGLE_PATH = "/google";
export const GOOGLE_CALLBACK_PATH = "/google/callback";

export const LINKEDIN_PATH = "/linkedin";
export const LINKEDIN_CALLBACK_PATH = "/linkedin/callback";

export const GITHUB_PATH = "/github";
export const GITHUB_CALLBACK_PATH = "/github/callback";

export const DISCORD_PATH = "/discord";
export const DISCORD_CALLBACK_PATH = "/discord/callback";

export const FRAME_DATA_SOURCE = "charry";

export const authenticationStrategyOAuthPath = (
  strategy: AuthenticationStrategy,
): string => {
  switch (strategy) {
    // Google
    case zAuthenticationStrategy.Enum.Google:
      return fullPath(GOOGLE_PATH);
    case zAuthenticationStrategy.Enum.Github:
      return fullPath(GITHUB_PATH);
    case zAuthenticationStrategy.Enum.Discord:
      return fullPath(DISCORD_PATH);
    // LinkedIn
    // case zAuthenticationStrategy.Enum.LinkedIn:
    //   return fullPath(LINKEDIN_PATH);

    default:
      return fullPath(GOOGLE_PATH);
  }
};

export const fullPath = (path: string): string =>
  `${CONTEXT_API_URL}/oauth${path}`;

/** ******************************************************************************
 *  OAuth Window Management
 ******************************************************************************* */

let windowObjectReference: Window | null = null;
let previousUrl: string | null = null;

type OAuthMessageData = AuthenticationResponse & { source: string };
type OAuthMessage = MessageEvent<OAuthMessageData>;

const receiveMessage = (event: OAuthMessage): OAuthMessageData | null => {
  if (event.origin !== CONTEXT_API_URL) {
    return null;
  }

  if (event.data.source !== FRAME_DATA_SOURCE) {
    return null;
  }

  return event.data;
};

let previousEventHandler: ((event: OAuthMessage) => void) | null = null;

const openOAuthWindow = (
  url: string,
  name: string,
): Promise<OAuthMessageData> => {
  return new Promise((resolve) => {
    if (previousEventHandler) {
      window.removeEventListener("message", previousEventHandler);
      previousEventHandler = null;
    }

    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference?.focus();
    } else {
      windowObjectReference.focus();
    }

    previousEventHandler = (event: OAuthMessage): void => {
      const data = receiveMessage(event);

      if (data?.user ?? data?.error) {
        windowObjectReference?.close();

        resolve(data);
      }
    };

    window.addEventListener("message", previousEventHandler, false);

    previousUrl = url;
  });
};

const closeOAuthWindow = (): boolean => {
  windowObjectReference?.close();
  const closed = windowObjectReference?.closed ?? true;
  windowObjectReference = null;
  return closed;
};

/** ******************************************************************************
 *  OAuth Flow
 ******************************************************************************* */

async function startOAuthFlow(
  strategy: AuthenticationStrategy,
): Promise<OAuthMessageData> {
  const url = authenticationStrategyOAuthPath(strategy);
  return await openOAuthWindow(url, FRAME_DATA_SOURCE);
}

function cancelOAuthFlow(): boolean {
  return closeOAuthWindow();
}

const AuthUtil = {
  cancelOAuthFlow,
  clearToken,
  getToken,
  isAuthenticated,
  setToken,
  startOAuthFlow,
};

export default AuthUtil;

import { SearchIcon } from "@chakra-ui/icons";
import { Box, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";

import SuperUsersTable from "~/ui/components/super/SuperUsersTable";
import SearchInput from "~/ui/elements/SearchInput";
import useSuper from "~/ui/hooks/useSuper.hook";

const SuperUsersPage = () => {
  const { setSuperListUsersQuery, superListUsers, superListUsersQuery } =
    useSuper();

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginBottom="24px"
        padding="24px 24px 0"
      >
        <Box>
          <Text color="white" variant="24-reg">
            Users ({superListUsers.data?.pages[0]?.total ?? 0})
          </Text>
        </Box>

        <Box>
          <InputGroup>
            <InputLeftElement height="36px" pointerEvents="none">
              <SearchIcon color="gray.500" />
            </InputLeftElement>
            <SearchInput
              borderRadius="6px"
              height="36px"
              onChange={(e) => setSuperListUsersQuery(e.currentTarget.value)}
              placeholder="Search..."
              raw
              size="md"
              type="text"
              value={superListUsersQuery}
              width="300px"
            />
          </InputGroup>
        </Box>
      </Box>

      <Box padding="0px 24px">
        <SuperUsersTable />
      </Box>
    </>
  );
};

export default SuperUsersPage;

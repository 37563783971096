import React, { Suspense } from "react";
import { Box, Circle, Flex, useTheme } from "@chakra-ui/react";
import { FiTool } from "react-icons/fi";

import { api } from "~/lib/trpc";
import { Page } from "~/ui/layouts/Main.layout";
import JoinSocials from "../components/onboarding/JoinSocials";

function MaintenanceMode() {
  const theme = useTheme();
  const [systemStatus] = api.system.get.useSuspenseQuery(undefined, {
    refetchInterval: 1000 * 15,
  });

  if (systemStatus.systemStatus?.allowTraffic === false) {
    return (
      <Page>
        <Flex height="100%" width="100%">
          <Box
            backgroundColor="gray.1100"
            height="100vh"
            overflow="scroll"
            position="relative"
            width="fill-available"
          >
            <Flex
              alignItems="center"
              height="100%"
              justifyContent="center"
              width="100%"
            >
              <Flex
                alignItems="center"
                flexDir="column"
                marginTop="-100px"
                maxWidth="400px"
                textAlign="center"
              >
                <Circle
                  backgroundColor="gray.800"
                  marginBottom="24px"
                  size="100px"
                >
                  <FiTool color={theme.colors.green[400]} size="42px" />
                </Circle>
                <Flex fontSize="2xl" fontWeight="bold">
                  Maintenance Mode
                </Flex>
                <Flex>
                  Kuzco is currently undergoing maintenance. Your account is
                  safe. Please check back later.
                </Flex>
                <Flex height="24px" />
                <JoinSocials />
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Page>
    );
  }
}

type MaintenanceModeLayoutProps = {
  children: React.ReactNode;
};

const MaintenanceModeLayout: React.FC<MaintenanceModeLayoutProps> = ({
  children,
}) => {
  return (
    <>
      <Suspense>
        <MaintenanceMode />
      </Suspense>
      {children}
    </>
  );
};

export default MaintenanceModeLayout;

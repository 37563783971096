import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import type { AuthenticationStrategy } from "@charry/models";
import { zAuthenticationStrategy } from "@charry/models";

import DiscordLogo from "~/assets/discord-button.png";
import GoogleLogo from "~/assets/google-button.png";
import PosthogEvent from "~/constants/PosthogEvent.constants";
import AuthUtil from "~/lib/auth";
import parseMutationError from "~/lib/parseMutationError";
import useTracking from "~/ui/hooks/useTracking.hook";
import useUser from "~/ui/hooks/useUser.hook";

type LoginRegisterModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const LoginRegisterModal: React.FC<LoginRegisterModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, unknown>>({});

  const navigate = useNavigate();
  const toast = useToast();
  const { authenticate, login, register } = useUser();
  const { track } = useTracking();

  const handleSubmit = useCallback(
    async (e?: React.FormEvent, strategy?: AuthenticationStrategy) => {
      e?.preventDefault();
      setLoading(true);
      try {
        let result;
        if (strategy) {
          result = await AuthUtil.startOAuthFlow(strategy);
        } else {
          if (isLogin) {
            result = await login.mutateAsync({
              email,
              password,
            });
          } else {
            if (password !== confirmPassword) {
              setErrors((prev) => ({
                ...prev,
                confirmPassword: {
                  message: "Passwords do not match! Try again.",
                },
              }));
              toast({
                description: "Passwords do not match! Try again.",
                duration: 5000,
                isClosable: true,
                status: "error",
                title: "Error",
              });
              setLoading(false);
              return;
            }

            result = await register.mutateAsync({
              email,
              firstName,
              lastName,
              password,
              registrationMethod: "PASSWORD",
            });
          }
        }

        const { error, token, user } = result;

        if (error) {
          throw error;
        }

        if (!token || !user) {
          throw new Error(isLogin ? "Failed to login" : "Failed to register");
        }

        authenticate({ token, user });
        track({
          event: PosthogEvent.AuthLogin,
        });

        if (user.emailVerificationRequired) {
          navigate("/verify-email");
        } else {
          navigate("/");
        }

        onClose();
      } catch (e: unknown) {
        setLoading(false);
        const error = e as Error;
        try {
          const parsedError = parseMutationError(error);
          setErrors(parsedError);
          toast({
            description:
              "Some of the fields are invalid. Please correct the errors and try again.",
            duration: 5000,
            isClosable: true,
            status: "error",
            title: "Invalid Fields",
          });
        } catch (e2) {
          toast({
            description: error.message,
            duration: 5000,
            isClosable: true,
            status: "error",
            title: isLogin ? "Error Logging In" : "Error Registering",
          });
        }
      }
    },
    [
      isLogin,
      email,
      password,
      firstName,
      lastName,
      confirmPassword,
      login,
      register,
      authenticate,
      navigate,
      onClose,
      toast,
      track,
    ],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="gray.900">
        <form onSubmit={handleSubmit}>
          <ModalHeader>{isLogin ? "Login" : "Register"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              {!isLogin && (
                <>
                  <Input
                    isDisabled={loading}
                    isInvalid={!!errors.firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    value={firstName}
                  />
                  <Input
                    isDisabled={loading}
                    isInvalid={!!errors.lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    value={lastName}
                  />
                </>
              )}
              <Input
                isDisabled={loading}
                isInvalid={!!errors.email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                value={email}
              />
              <Input
                isDisabled={loading}
                isInvalid={!!errors.password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                value={password}
              />
              {!isLogin && (
                <Input
                  isDisabled={loading}
                  isInvalid={!!errors.confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  type="password"
                  value={confirmPassword}
                />
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack spacing={4} width="100%">
              <Button
                isDisabled={loading}
                isLoading={loading}
                onClick={() => handleSubmit()}
                type="submit"
                variant="primary"
                width="100%"
              >
                {isLogin ? "Login" : "Register"}
              </Button>
              <Box
                _hover={{ cursor: "pointer" }}
                onClick={() =>
                  handleSubmit(undefined, zAuthenticationStrategy.Enum.Google)
                }
                width="100%"
              >
                <Image
                  alt="Google Logo"
                  height="46"
                  src={GoogleLogo}
                  style={{ margin: "0 auto" }}
                />
              </Box>
              <Box
                _hover={{ cursor: "pointer" }}
                onClick={() =>
                  handleSubmit(undefined, zAuthenticationStrategy.Enum.Discord)
                }
                width="100%"
              >
                <Image
                  alt="Discord Logo"
                  height="46"
                  src={DiscordLogo}
                  style={{ margin: "0 auto" }}
                />
              </Box>
              {/* <Box
                onClick={() =>
                  handleSubmit(undefined, zAuthenticationStrategy.Enum.Github)
                }
                _hover={{ cursor: "pointer" }}
                width="100%"
              >
                <Image
                  alt="Github Logo"
                  src={GithubLogo}
                  height="46"
                  style={{ margin: "0 auto" }}
                />
              </Box> */}
              <Text
                cursor="pointer"
                onClick={() => setIsLogin(!isLogin)}
                textDecoration="underline"
              >
                {isLogin ? "Need an account?" : "Already have an account?"}
              </Text>
            </VStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default LoginRegisterModal;

import type { ButtonProps } from "@chakra-ui/react";
import { Button, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

type SubNavigationButtonProps = ButtonProps & {
  label: string;
  link: string;
};

export const SubNavigationButton = (props: SubNavigationButtonProps) => {
  const location = useLocation();
  const { label, link, ...buttonProps } = props;

  const active = link ? location.pathname.includes(link) : false;
  const color = active ? "gray.100" : "gray.500";

  return (
    <Link style={{ marginRight: "16px", width: "100%" }} to={link}>
      <Button
        _hover={{
          backgroundColor: "transparent",
          color: "gray.100",
          cursor: "pointer",
        }}
        backgroundColor="transparent"
        color={color}
        justifyContent="start"
        padding="0 4px"
        size="sm"
        width="100%"
        {...buttonProps}
      >
        <Text variant="14-semi">{label}</Text>
      </Button>
    </Link>
  );
};

export default SubNavigationButton;

import type { ErrorInfo } from "react";
import * as Sentry from "@sentry/react";

import type RouteErrorType from "../types/RouteErrorType";
import type SentryConsoleEvent from "../types/SentryConsoleEvent";
import { NODE_ENV } from "../lib/const";

export function captureConsoleException(
  errorEvent: SentryConsoleEvent,
  error: Error | RouteErrorType | string,
  additionalInfo?: ErrorInfo | Record<string, unknown>,
) {
  if (NODE_ENV !== "production") {
    console.error("[Sentry Exception]:");
    console.error(error);
  }

  Sentry.captureException(error, {
    // @ts-expect-error - Not sure why tsc complains here.
    extra: additionalInfo,

    tags: {
      errorEvent,
    },
  });
}

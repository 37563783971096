import React, { Suspense } from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";

import { EpochOneCard } from "~/ui/components/marketing/EpochOne";
import Footer from "~/ui/components/marketing/Footer";
import MarketingNavigation from "~/ui/components/marketing/MarketingNavigation";
import { Page } from "~/ui/layouts/Main.layout";
import { Content } from "~/ui/layouts/Page.layout";

type MarketingLayoutProps = {
  children?: React.ReactNode;
  showEpoch?: boolean;
};

const MarketingLayout: React.FC<MarketingLayoutProps> = ({
  children,
  showEpoch = false,
}) => {
  return (
    <Page>
      <Flex height="100%" width="100%">
        <Box
          backgroundColor="gray.1100"
          height="100vh"
          overflow="scroll"
          position="relative"
          width="fill-available"
        >
          <Suspense>
            <MarketingNavigation />
            <Content
              padding="0px"
              suspenseFallback={
                <Flex
                  alignItems="center"
                  height="600px"
                  justifyContent="center"
                  width="100%"
                >
                  <Spinner />
                </Flex>
              }
              width={{ md: "1000px", sm: "100%" }}
            >
              <Box height="32px" />
              {showEpoch ? (
                <>
                  <EpochOneCard />
                  <Box height="48px" />
                </>
              ) : null}
              {children}
              <Box height="200px" />
            </Content>
            <Footer />
          </Suspense>
        </Box>
      </Flex>
    </Page>
  );
};

export default MarketingLayout;

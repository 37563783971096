import React from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import parseMutationError from "~/lib/parseMutationError";
import useCharacter from "~/ui/hooks/useCharacter.hook";

type UpdateCharacterMenuProps = {
  characterId: string;
};

const UpdateCharacterMenu: React.FC<UpdateCharacterMenuProps> = ({
  characterId,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const { updateCharacter, updateCharacterParams } = useCharacter(characterId);
  const [_errors, setErrors] = React.useState<Record<string, unknown>>({});

  const update = async () => {
    try {
      setLoading(true);
      const { character, error } = await updateCharacter.mutateAsync(
        updateCharacterParams,
      );

      if (!character) {
        throw new Error(`Failed to update character: ${error?.message}`);
      }

      navigate(`/character/${character.id}/`);
    } catch (e) {
      setLoading(false);
      try {
        const parsedError = parseMutationError(e);
        setErrors(parsedError);
        toast({
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Invalid Fields",
        });
      } catch (e) {
        toast({
          description:
            "Something went wrong. Please try again later or contact support.",
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Error Saving Character",
        });
      }
    }
  };

  return (
    <Button
      isDisabled={loading}
      isLoading={loading}
      onClick={() => update()}
      size={"sm"}
      variant="primary"
    >
      Update character
    </Button>
  );
};

export default UpdateCharacterMenu;

/* eslint-disable sort-keys-fix/sort-keys-fix */
import z from "zod";

import { serviceRequest, serviceResponse } from "./BaseService";

export const NOTIFICATION_EVENT_STREAM_NAME_NATS = "notifications";
export const APPLE_NOTIFICATION_EVENT_STREAM_NATS_SUBJECT = `${NOTIFICATION_EVENT_STREAM_NAME_NATS}.apple.app-store-connect`;

export const SUBSCRIPTION_TIERS = ["BASIC", "PREMIUM"] as const;

export type SubscriptionTier = (typeof SUBSCRIPTION_TIERS)[number];

export const SUBSCRIPTION_STATUS = ["ACTIVE", "INACTIVE"] as const;

export type SubscriptionStatus = (typeof SUBSCRIPTION_STATUS)[number];

export const SubscriptionSchema = z.object({
  _id: z.string(),
  userId: z.string(),
  tier: z.enum(SUBSCRIPTION_TIERS),
  status: z.enum(SUBSCRIPTION_STATUS),
  appleOriginalTransactionId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Subscription = z.infer<typeof SubscriptionSchema>;

export const AppleNotificationEventRequestSchema = z.object({
  signedPayload: z.string(),
});

export type AppleNotificationEventRequest = z.infer<
  typeof AppleNotificationEventRequestSchema
>;

export const publishAppleNotificationEventResponse = serviceResponse;

export type PublishAppleNotificationEventResponse = z.infer<
  typeof publishAppleNotificationEventResponse
>;

export type SubscriptionService = {
  update(
    request: UpdateSubscriptionRequest,
  ): Promise<UpdateSubscriptionResponse>;

  publishAppleNotificationEvent(
    request: AppleNotificationEventRequest,
  ): Promise<PublishAppleNotificationEventResponse>;
};

/** ******************************************************************************
 *  Update Subscription
 ******************************************************************************* */

export const updateSubscriptionParams = z.object({
  appAccountToken: z.string(),
  tier: z.enum(SUBSCRIPTION_TIERS),
  status: z.enum(SUBSCRIPTION_STATUS),
  appleOriginalTransactionId: z.string(),
});

export const updateSubscriptionRequest = serviceRequest.merge(
  z.object({
    params: updateSubscriptionParams,
  }),
);

export const updateSubscriptionResponse = serviceResponse;

export type UpdateSubscriptionParams = z.infer<typeof updateSubscriptionParams>;
export type UpdateSubscriptionRequest = z.infer<
  typeof updateSubscriptionRequest
>;
export type UpdateSubscriptionResponse = z.infer<
  typeof updateSubscriptionResponse
>;

import React, { useCallback, useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { CharacterVisibilitySchema } from "@charry/models";

import parseMutationError from "~/lib/parseMutationError";
import useCharacterList from "~/ui/hooks/useCharacterList.hook";
import { AnalyticsEventKeys } from "../../../../constants/PosthogEvent.constants";
import { useTrack } from "../../../../hooks/analytics/useTrack";

const CreateCharacterMenu: React.FC = () => {
  const toast = useToast();
  const track = useTrack();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { createCharacter, createCharacterParams, setCreateCharacterParams } =
    useCharacterList();
  const [_errors, setErrors] = useState<Record<string, unknown>>({});

  const resetForm = useCallback(() => {
    setCreateCharacterParams({
      category: "Other",
      copyable: true,
      definition: "",
      description: "",
      greeting: "",
      imageUrl: "",
      name: "",
      nsfw: false,
      tags: [],
      title: "",
      visibility: CharacterVisibilitySchema.Enum.Public,
    });
  }, [setCreateCharacterParams]);

  const create = useCallback(async () => {
    if (loading) return; // Prevent double submission

    // Client-side validation
    const newErrors: Record<string, string> = {};
    if (!createCharacterParams.name) newErrors.name = "Name is required";
    if (!createCharacterParams.description)
      newErrors.description = "Description is required";
    if (!createCharacterParams.greeting)
      newErrors.greeting = "Greeting is required";
    if (!createCharacterParams.title) newErrors.title = "Title is required";
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!createCharacterParams.category)
      newErrors.category = "Category is required";
    if (!createCharacterParams.tags || createCharacterParams.tags.length === 0)
      newErrors.tags = "At least one tag is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast({
        description:
          "Please fill in all required fields before creating the character.",
        duration: 5000,
        isClosable: true,
        status: "error",
        title: "Missing Required Fields",
      });
      return;
    }

    try {
      setLoading(true);
      const { character, error } = await createCharacter.mutateAsync({
        ...createCharacterParams,
      });

      if (!character) {
        throw new Error(`Failed to create character: ${JSON.stringify(error)}`);
      }

      track({
        name: AnalyticsEventKeys.CharacterCreation.CompletedCharacterCreation,
        properties: {
          characterId: character.id,
          hasImage: !!createCharacterParams.imageUrl,
          visibility: createCharacterParams.visibility,
        },
      });

      // Reset the form after successful creation
      resetForm();

      toast({
        description: "Your character has been successfully created.",
        duration: 5000,
        isClosable: true,
        status: "success",
        title: "Character Created",
      });

      navigate(`/character/${character.id}/`);
    } catch (e) {
      console.error(e);
      try {
        const parsedError = parseMutationError(e);
        setErrors(parsedError);
        toast({
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Invalid Fields",
        });
      } catch (e) {
        toast({
          description:
            (e as Error | undefined)?.message ??
            "Something went wrong. Please try again later or contact support.",
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Error Creating Character",
        });
      }
    } finally {
      setLoading(false);
    }
  }, [
    createCharacter,
    createCharacterParams,
    navigate,
    toast,
    track,
    loading,
    resetForm,
  ]);

  return (
    <Button
      isDisabled={loading}
      isLoading={loading}
      onClick={create}
      size="sm"
      variant="primary"
    >
      Create Character
    </Button>
  );
};

export default CreateCharacterMenu;

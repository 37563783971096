import { z } from "zod";

// Avatar class
export const AvatarSchema_CAI = z.object({
  // avatar: z.lazy(() => ImageSchema_CAI),
  // url: z.string(),
  // type: z.string(),
});

export type Avatar_CAI = z.infer<typeof AvatarSchema_CAI>;

// QueryChar
export const QueryCharSchema_CAI = z.object({
  avatar_file_name: z.string(),
  document_id: z.string(),
  external_id: z.string(),
  greeting: z.string(),
  participant__name: z.string(),
  participant__num_interactions: z.number(),
  priority: z.number(),
  search_score: z.number(),
  title: z.string(),
  upvotes: z.number().optional(),
  user__username: z.string(),
  visibility: z.string(),
});

export type QueryChar_CAI = z.infer<typeof QueryCharSchema_CAI>;

// Image
export const ImageSchema_CAI = z.object({
  icon: z.string().default("user"),
  type: z.string().default("UPLOADED"),
  url: z.string(),
});

export type Image_CAI = z.infer<typeof ImageSchema_CAI> & {
  download: (options?: {
    path?: string;
    width?: number;
    type?: string;
  }) => Promise<void>;
};

// Voice
export const VoiceSchema_CAI = z.object({
  country_code: z.string(),
  id: z.number(),
  lang_code: z.string(),
  name: z.string(),
  voice_id: z.string(),
});

export type Voice_CAI = z.infer<typeof VoiceSchema_CAI>;

// Implementation of the download function for Image
async function downloadImage(
  this: Image_CAI,
  options: { path?: string; width?: number; type?: string } = {},
): Promise<void> {
  const { path, type, width = 400 } = options;

  if (type === "CREATED" || type === "REGENERATE") {
    await new Promise((resolve) => setTimeout(resolve, 3000));
  } else {
    this.url = `https://characterai.io/i/${width}/static/${this.icon}/${this.url}`;
  }

  // Note: The actual implementation of downloading and saving the file
  // would depend on the environment (Node.js or browser) and available APIs.
  // This is a placeholder to show the structure.
  console.log(
    `Downloading image from ${this.url} to ${path ?? "default location"}`,
  );
}

// Factory function to create an Image with the download method
export function createImage(data: z.infer<typeof ImageSchema_CAI>): Image_CAI {
  return {
    ...ImageSchema_CAI.parse(data),
    download: downloadImage,
  };
}

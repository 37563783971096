export default function parseMutationError(
  e: unknown,
): Record<string, unknown> {
  const json = JSON.parse((e as Error).message);
  const result = json.reduce(
    (cur: Record<string, object>, next: unknown) => ({
      ...cur,
      // @ts-expect-error - need better types here.
      [next.path]: next,
    }),
    {},
  );
  return result;
}

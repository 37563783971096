import React, { Suspense, useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import SideNavigation from "~/ui/components/dashboard/SideNavigation";
import { Page } from "~/ui/layouts/Main.layout";
import useUser from "../hooks/useUser.hook";
import SideNavigationSuspense from "../suspense/SideNavigation.suspense";
import MaintenanceModeLayout from "./MaintenanceMode.layout";

/**
 * Disallow unauthenticated and no organization users
 * on dashboard. Redirect accordingly.
 */
function DashboardAuthenticationCheck() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user } = useUser();
  useEffect(() => {
    if (!location.pathname.includes("/dashboard")) {
      return;
    }

    if (!isAuthenticated) {
      navigate("/", { replace: true });
      return;
    }

    if (user?.emailVerificationRequired && user.emailVerifiedAt == null) {
      navigate("/verify-email");
    }

    if (user?.isDisabled) {
      navigate("/paused");
    }
  }, [navigate, location.pathname, isAuthenticated, user]);

  return null;
}

type DashboardLayoutProps = {
  children?: React.ReactNode;
  hideNavigation?: boolean;
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  hideNavigation = false,
}) => {
  return (
    <MaintenanceModeLayout>
      <Suspense>
        <DashboardAuthenticationCheck />
      </Suspense>
      <Page>
        <Flex height="100%" overflow="hidden" width="100%">
          {hideNavigation ? null : (
            <>
              <Suspense fallback={<SideNavigationSuspense />}>
                <SideNavigation />
              </Suspense>
            </>
          )}
          <Box
            backgroundColor="gray.1100"
            height="100dvh"
            overflowY="auto"
            position="relative"
            width="fill-available"
          >
            {children ? children : <Outlet />}
          </Box>
        </Flex>
      </Page>
    </MaintenanceModeLayout>
  );
};

export default DashboardLayout;

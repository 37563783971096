import { z } from "zod";

import { AvatarSchema_CAI } from "./other.cai";

export const AnonymousSchema_CAI = z.object({
  username: z.string().default("ANONYMOUS"),
});

export const GuestSchema_CAI = z.object({
  account: z.any().optional(),
  blocked_users: z.array(z.unknown()),
  email: z.string().optional(),
  hidden_characters: z.array(z.unknown()),
  id: z.number(),
  is_human: z.boolean().default(true),
  is_staff: z.boolean().default(false),
  name: z.string(),
  subscription: z.number().optional(),
  username: z.string(),
});

export const AccountSchema_CAI = z.object({
  bio: z.null(),
  blocked_users: z.array(z.unknown()),
  email: z.string(),
  hidden_characters: z.array(z.unknown()),
  interests: z.null(),
  is_human: z.boolean(),
  name: z.string(),
  needs_to_acknowledge_policy: z.boolean(),
  suspended_until: z.null(),
  user: z.object({
    account: z.object({
      avatar_file_name: z.string(),
      avatar_type: z.string(),
      mobile_onboarding_complete: z.null(),
      name: z.string(),
      onboarding_complete: z.boolean(),
    }),
    first_name: z.string(),
    id: z.number(),
    is_staff: z.boolean(),
    subscription: z.null(),
    username: z.string(),
  }),
});

export type Account_CAI = z.infer<typeof AccountSchema_CAI>;

export const ProfileSchema_CAI = AvatarSchema_CAI.extend({
  avatar_file_name: z.string().nullable(),
  avatar_type: z.string().optional(),
  bio: z.string().nullable(),
  blocked_users: z.array(z.string()),
  email: z.string(),
  first_name: z.string().nullable(),
  hidden_characters: z.array(z.string()),
  id: z.number(),
  is_human: z.boolean(),
  is_staff: z.boolean(),
  mobile_onboarding_complete: z.number().nullable(),
  name: z.string().optional(),
  needs_to_acknowledge_policy: z.boolean(),
  onboarding_complete: z.boolean(),
  subscription: z.record(z.unknown()).optional(),
  suspended_until: z.date().nullable(),
  username: z.string(),
});

export const PersonaSchema_CAI = AvatarSchema_CAI.extend({
  avatar_file_name: z.string(),
  base_img_prompt: z.string(),
  categories: z.array(z.unknown()),
  comments_enabled: z.boolean(),
  copyable: z.boolean(),
  default_voice_id: z.string(),
  definition: z.string(),
  description: z.string(),
  external_id: z.string(),
  greeting: z.string(),
  identifier: z.string(),
  img_gen_enabled: z.boolean(),
  img_prompt_regex: z.string(),
  name: z.string(),
  num_interactions: z.number(),
  participant__name: z.string(),
  participant__user__username: z.string(),
  songs: z.array(z.unknown()),
  starter_prompts: z.any(),
  strip_img_prompt_from_msg: z.boolean(),
  title: z.string(),
  user__username: z.string(),
  visibility: z.string(),
  voice_id: z.string(),
});

export const PersonaShortSchema_CAI = AvatarSchema_CAI.extend({
  avatar_file_name: z.string().nullable(),
  copyable: z.boolean(),
  default_voice_id: z.string().optional(),
  definition: z.string(),
  description: z.string().optional(),
  external_id: z.string(),
  greeting: z.string(),
  img_gen_enabled: z.boolean(),
  is_persona: z.boolean(),
  participant__name: z.string(),
  participant__num_interactions: z.number(),
  title: z.string().optional(),
  user__id: z.number(),
  user__username: z.string(),
  visibility: z.string(),
});

export type Anonymous_CAI = z.infer<typeof AnonymousSchema_CAI>;
export type Guest_CAI = z.infer<typeof GuestSchema_CAI>;
export type Profile_CAI = z.infer<typeof ProfileSchema_CAI>;
export type Persona_CAI = z.infer<typeof PersonaSchema_CAI>;
export type PersonaShort_CAI = z.infer<typeof PersonaShortSchema_CAI>;

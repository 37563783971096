import { Heading, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import AppStoreDownloadIcon from "~/assets/ios-download-icon.svg";

function LoginVideoComponent({ isFullScreen = false }) {
  return (
    <div
      style={{
        height: "100vh",
        left: 0,
        position: "fixed",
        top: 0,
        width: isFullScreen ? "100%" : "50%",
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          height: "100%",
          objectFit: "cover",
          width: "100%",
        }}
      >
        <source
          src="https://r2.charry.ai/static-assets/landing-page.mp4?bust=cache"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

type ContentOverlayProps = {
  isMobile?: boolean;
};

function ContentOverlay({ isMobile = false }: ContentOverlayProps) {
  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.65)",
        display: "flex",
        flexDirection: "column",
        gap: 32,
        height: "100vh",
        justifyContent: "center",
        position: "relative",
        textAlign: "center",
        width: "100%",
        zIndex: 1,
      }}
    >
      <Heading
        as="h1"
        color="white"
        size={isMobile ? "xl" : "lg"}
        width={isMobile ? "60%" : "100%"}
      >
        Charry is now available on iOS
      </Heading>
      <Link
        target="_blank"
        to="https://apps.apple.com/us/app/charry/id6737364618"
      >
        <Image
          alt="App Store Download Icon"
          height={14}
          src={AppStoreDownloadIcon}
        />
      </Link>
      <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
        <Link to="/privacy-policy">
          <Text
            _hover={{ textDecoration: "underline" }}
            color="gray.200"
            fontSize="sm"
          >
            Privacy Policy
          </Text>
        </Link>
        <Link to="/terms">
          <Text
            _hover={{ textDecoration: "underline" }}
            color="gray.200"
            fontSize="sm"
          >
            Terms of Service
          </Text>
        </Link>
      </div>
    </div>
  );
}

export default function MobileAppLandingPage() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return (
      <div style={{ position: "relative" }}>
        <LoginVideoComponent isFullScreen />
        <ContentOverlay isMobile />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <LoginVideoComponent />
      <div
        style={{
          marginLeft: "50%",
          width: "50%",
        }}
      >
        <ContentOverlay />
      </div>
    </div>
  );
}

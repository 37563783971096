import type { IconBaseProps } from "react-icons/lib";
import { Link as ChakraLink, Flex, Text, useTheme } from "@chakra-ui/react";

type HelpButtonProps = {
  text: string;
  Icon: React.FC<IconBaseProps>;
  link?: string;
  onClick?: () => void;
};

export default function HelpButton({
  Icon,
  link,
  onClick,
  text,
}: HelpButtonProps) {
  const theme = useTheme();
  const button = (
    <Flex
      _hover={{ color: theme.colors.gray[300], cursor: "pointer" }}
      alignItems="center"
      color="gray.400"
      marginBottom="12px"
      onClick={onClick ? onClick : undefined}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        marginRight="12px"
        width="16px"
      >
        <Icon color="inherit" fontSize="16px" />
      </Flex>
      <Text color="inherit" variant="14-reg">
        {text}
      </Text>
    </Flex>
  );

  if (!link) {
    return button;
  }

  return (
    <ChakraLink href={link} target="_blank">
      {button}
    </ChakraLink>
  );
}

import { useParams } from "react-router-dom";

import EditCharacter from "~/ui/components/character/create/EditCharacter";

type CharacterSettingsPageProps = unknown;

const CharacterSettingsPage: React.FC<CharacterSettingsPageProps> = () => {
  const { characterId } = useParams();

  return <EditCharacter characterId={characterId ?? ""} />;
};

export default CharacterSettingsPage;

import React, { Suspense, useState } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";

import type { Chat } from "@charry/models";

import RecentChatCard from "~/ui/components/chat/RecentChatCard";
import useCharacter from "~/ui/hooks/useCharacter.hook";
import useChat from "~/ui/hooks/useChat.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import RecentChatsPageSuspense from "~/ui/suspense/RecentChatsPage.suspense";

const RecentChatsLayoutComponent: React.FC = () => {
  const { chatList: chatList } = useChat();
  const [displayCount, setDisplayCount] = useState(5);

  const sortedChats = React.useMemo(() => {
    return [...chatList].sort((a, b) => {
      const aLastTurn = a.turns[a.turns.length - 1];
      const bLastTurn = b.turns[b.turns.length - 1];
      return (
        new Date(bLastTurn?.updatedAt ?? b.updatedAt).getTime() -
        new Date(aLastTurn?.updatedAt ?? a.updatedAt).getTime()
      );
    });
  }, [chatList]);

  const displayedChats = sortedChats.slice(0, displayCount);

  // useEffect(() => {
  //   refreshChatList.mutate();
  // }, [refreshChatList]);

  const handleLoadMore = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  return (
    <>
      <Header>
        <Title>Chat History</Title>
      </Header>
      <Content mb="4" mt="4">
        {displayedChats.length > 0 ? (
          <>
            <Flex flexDirection="column" gap={4}>
              {displayedChats.map((chat) => (
                <RecentChatWithCharacter chat={chat} key={chat.id} />
              ))}
            </Flex>
            {displayCount < sortedChats.length && (
              <Flex justifyContent="center" mt={4}>
                <Button onClick={handleLoadMore} variant="outline">
                  Load More
                </Button>
              </Flex>
            )}
          </>
        ) : (
          <Text>No recent chats found.</Text>
        )}
      </Content>
    </>
  );
};

const RecentChatWithCharacter: React.FC<{ chat: Chat }> = ({ chat }) => {
  const { character } = useCharacter(chat.characterId);
  if (character === null) {
    return null;
  }

  return <RecentChatCard character={character} chat={chat} />;
};

export default function RecentChatsPage() {
  return (
    <Suspense fallback={<RecentChatsPageSuspense />}>
      <RecentChatsLayoutComponent />
    </Suspense>
  );
}

import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Link as ChakraLink,
  Circle,
  Flex,
  IconButton,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import {
  FiChevronDown,
  FiMessageCircle,
  FiRefreshCw,
  FiSend,
  FiUser,
} from "react-icons/fi";
import ReactMarkdown from "react-markdown";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import CharacterAvatar from "~/ui/components/character/CharacterAvatar";
import useCharacter from "~/ui/hooks/useCharacter.hook";
import useChat from "~/ui/hooks/useChat.hook";
import useIsAuthenticated from "~/ui/hooks/useIsAuthenticated.hook";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useUser from "~/ui/hooks/useUser.hook";
import { Header } from "~/ui/layouts/Page.layout";
import ChatDetailsPageSuspense from "~/ui/suspense/ChatDetailsPage.suspense";

const ChatLayoutComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { isAuthenticated } = useIsAuthenticated();
  const { chatId } = useParams();
  const isMobile = useIsMobile();

  const [searchParams] = useSearchParams();
  const icebreaker = searchParams.get("icebreaker");

  const { chat, createChat, createTurns, regenerateTurn, streamTurn } = useChat(
    chatId ?? "",
  );

  const characterId = chat?.characterId;

  const { character } = useCharacter(characterId ?? "");

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [_, setMessageVersions] = useState<Record<string, string[]>>({});
  const [currentVersions, setCurrentVersions] = React.useState<
    Record<string, number>
  >({});

  const [message, setMessage] = useState("");
  const [textareaHeight, setTextareaHeight] = useState("50px");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [showScrollButton, setShowScrollButton] = useState(true);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const [regeneratingMessageId, setRegeneratingMessageId] = React.useState<
    string | null
  >(null);

  React.useEffect(() => {
    if (icebreaker) {
      setMessage(decodeURIComponent(icebreaker));
    }
  }, [icebreaker]);

  const handleNewChat = useCallback(async () => {
    try {
      const response = await createChat.mutateAsync({
        characterId: characterId ?? "",
      });
      if (response.chat) {
        navigate(`/chat/${response.chat.id}`);
      }
    } catch (error) {
      console.error("Failed to create new chat:", error);
    }
  }, [characterId, createChat, navigate]);

  const scrollToMessage = useCallback((id: string) => {
    const messageElement = document.getElementById(id);
    if (messageElement) {
      messageElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  const handleRegenerateMessage = useCallback(
    async (turnId: string) => {
      if (!chat) return;

      setMessageVersions((prev) => ({
        ...prev,
        [turnId]: [...(prev[turnId] ?? []), ""],
      }));

      setCurrentVersions((prev) => ({
        ...prev,
        [turnId]: (prev[turnId] ?? 0) + 1,
      }));

      setRegeneratingMessageId(turnId);

      try {
        await regenerateTurn(turnId);
        setTimeout(() => scrollToMessage(turnId), 100);
      } catch (error) {
        console.error("Error regenerating message:", error);
      } finally {
        setRegeneratingMessageId(null);
      }
    },
    [chat, regenerateTurn, scrollToMessage],
  );

  const handleScroll = useCallback(() => {
    if (chatContainerRef.current) {
      const { clientHeight, scrollHeight, scrollTop } =
        chatContainerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 20;
      setShowScrollButton(!isNearBottom);

      const chatContainer = chatContainerRef.current;
      chatContainer.addEventListener("scroll", handleScroll);
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      handleScroll();
    }
  }, [handleScroll]);

  const handleSubmit = useCallback(async () => {
    if (message.trim() === "") return;
    const { turns } = await createTurns.mutateAsync({
      turns: [
        {
          characterId: chat?.characterId ?? "",
          chatId: chat?.id ?? "",
          content: message,
          role: "user",
          state: "COMPLETED",
          versions: [],
        },
        {
          characterId: chat?.characterId ?? "",
          chatId: chat?.id ?? "",
          content: null,
          role: "assistant",
          state: "CREATED",
          versions: [],
        },
      ],
    });
    setMessage("");
    setTextareaHeight("50px");
    if (textareaRef.current) {
      textareaRef.current.style.height = "50px";
    }
    scrollToBottom();

    if (!turns || turns.length === 0) return;
    const lastTurn = turns[turns.length - 1];
    if (!lastTurn) return;

    streamTurn(lastTurn.id);
    scrollToBottom();
    setTimeout(scrollToBottom, 100);
  }, [message, createTurns, chat, scrollToBottom, streamTurn]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const handleScrollEvent = () => {
        handleScroll();
      };
      chatContainer.addEventListener("scroll", handleScrollEvent);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (chat?.turns[chat.turns.length - 1]?.state === "CREATED") {
      scrollToBottom();
    }
  }, [chat, scrollToBottom]);

  useEffect(() => {
    scrollToBottom();
  }, [chat, scrollToBottom]);

  if (!isAuthenticated) {
    return (
      <Flex
        alignItems="center"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <Text>You must be logged in to chat with characters.</Text>
      </Flex>
    );
  }

  if (!chat) {
    return (
      <Flex
        alignItems="center"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <Spinner color="blue.500" size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      height="100dvh"
      minHeight="-webkit-fill-available"
      overflow="hidden"
      width="fill-available"
    >
      <Header
        backgroundColor="gray.1100"
        borderBottom={isMobile ? "1px solid" : "0px"}
        height={isMobile ? "64px" : "96px"}
        padding={{ base: "0 16px", md: "0 80px" }}
        position="sticky"
        top="0"
        zIndex="3"
      >
        <Box alignItems="center" display="flex">
          <ChakraLink as={RouterLink} to={`/character/${characterId}`}>
            <CharacterAvatar
              character={character}
              size={isMobile ? "sm" : "md"}
            />
          </ChakraLink>
          <Box width="12px" />
          <Box>
            <ChakraLink
              _hover={{ textDecoration: "none" }}
              as={RouterLink}
              to={`/character/${characterId}`}
            >
              <Text variant="16-med">{character?.name}</Text>
              <Text color="gray.400" variant="14-reg">
                {/* {chat.id} */}
                {isMobile ? "" : character?.title}
              </Text>
            </ChakraLink>
          </Box>
        </Box>
        <Box>
          <Button
            isLoading={createChat.isPending}
            leftIcon={<FiMessageCircle />}
            onClick={handleNewChat}
            size="sm"
            variant="primary"
            width="100%"
          >
            {isMobile ? "+" : "New Chat"}
          </Button>
        </Box>
      </Header>
      <Flex
        flex="1"
        flexDirection="column"
        overflowY="auto"
        position="relative"
        ref={chatContainerRef}
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.600",
            borderRadius: "60px",
          },
          "&::-webkit-scrollbar-track": {
            background: "gray.800",
            width: "8px",
          },
          scrollbarColor:
            "var(--chakra-colors-gray-800) var(--chakra-colors-gray-900)",
          scrollbarWidth: "thin",
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          marginTop="32px"
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <ChakraLink as={RouterLink} to={`/character/${characterId}`}>
              <CharacterAvatar character={character} size="lg" />
            </ChakraLink>
            <Box height="16px" width="12px" />
            <ChakraLink
              _hover={{ textDecoration: "none" }}
              as={RouterLink}
              textAlign="center"
              to={`/character/${characterId}`}
            >
              <Text variant="16-med">{character?.name}</Text>
              <Text color="gray.400" variant="14-reg">
                {character?.title}
              </Text>
            </ChakraLink>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          margin="0 auto"
          maxWidth="704px"
          padding={{ base: "16px", md: "32px" }}
          width="100%"
        >
          {chat.turns
            .filter(({ role }) => role !== "system")
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime(),
            )
            .map(({ content, id, role, state, versions }) => {
              const isUser = role === "user";
              const isRegenerating = state === "REGENERATING";
              const isRegenerated = state === "REGENERATED";
              const isRegenerate = state === "REGENERATE";
              const isFailed =
                state === "REGENERATION_FAILED" || state === "FAILED";
              const isCreated = state === "CREATED";
              const isStreaming =
                state === "IN_PROGRESS" ||
                (state === "COMPLETED" && content === null);

              const userIcon = (
                <Circle bg="purple.300" size="28px">
                  <FiUser color="white" size="14px" />
                </Circle>
              );

              const versionArray = versions;
              const currentVersion =
                currentVersions[id] ?? versionArray.length - 1;

              // Skip rendering for turns with null content (except for the last turn which might be streaming)
              if (
                content === null &&
                !isStreaming &&
                (isRegenerating || isRegenerate) &&
                versionArray.length === 0
              )
                return null;

              return (
                <Flex
                  flexDirection="column"
                  id={id}
                  key={id}
                  padding="8px 4px"
                  width="100%"
                >
                  <Flex
                    justifyContent={isUser ? "flex-end" : "flex-start"}
                    width="100%"
                  >
                    <Flex
                      flexDirection="column"
                      justifyContent={isUser ? "flex-end" : "flex-start"}
                    >
                      <Flex justifyContent={isUser ? "flex-end" : "flex-start"}>
                        {role === "assistant" ? (
                          <CharacterAvatar
                            character={character}
                            fontSize="10px"
                            size="sm"
                          />
                        ) : null}
                        <Text
                          alignSelf={isUser ? "flex-end" : "flex-start"}
                          margin="4px 6px"
                          variant="14-semi"
                        >
                          {isUser ? user?.firstName : character?.name}
                        </Text>
                        {isUser ? userIcon : null}
                      </Flex>
                      <Box
                        bg={isUser ? "gray.800" : "gray.900"}
                        borderRadius="16px"
                        color="gray.200"
                        margin="0 28px"
                        maxWidth="36rem"
                        my={1}
                        p={4}
                      >
                        {isRegenerating ? (
                          <Flex alignItems="center" justifyContent="center">
                            <Text lineHeight="1.6" variant="16-reg">
                              {content ? (
                                content
                              ) : (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Spinner size="md" />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                        ) : isStreaming ? (
                          <Flex alignItems="center" justifyContent="center">
                            {content ?? ""}
                          </Flex>
                        ) : isFailed ||
                          (!content && !isCreated && !isRegenerate) ? (
                          <Text
                            color="red.400"
                            lineHeight="1.6"
                            variant="16-reg"
                          >
                            Failed to generate response, try again.
                          </Text>
                        ) : (
                          <Text lineHeight="1.6" variant="16-reg">
                            <ReactMarkdown>
                              {versionArray[currentVersion] ?? content ?? ""}
                            </ReactMarkdown>
                          </Text>
                        )}
                        {isCreated && (
                          <Flex alignItems="center" justifyContent="center">
                            <Spinner size="md" />
                          </Flex>
                        )}
                        {isRegenerate && (
                          <Flex alignItems="center" justifyContent="center">
                            <Spinner size="md" />
                          </Flex>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                  {!isUser && (
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      ml="28px"
                      mt="2"
                    >
                      <Button
                        isLoading={regeneratingMessageId === id}
                        minW="14px"
                        onClick={() => handleRegenerateMessage(id)}
                        p={2}
                        size="xs"
                        variant="ghost"
                      >
                        <FiRefreshCw size="12px" />
                      </Button>
                      {isRegenerated && versionArray.length > 1 && (
                        <>
                          <Button
                            ml={1}
                            onClick={() =>
                              setCurrentVersions((prev) => ({
                                ...prev,
                                [id]:
                                  (currentVersion - 1 + versionArray.length) %
                                  versionArray.length,
                              }))
                            }
                            size="xs"
                            variant="ghost"
                          >
                            ←
                          </Button>
                          <Text fontSize="xs" ml={2}>
                            {currentVersion + 1}/{versionArray.length}
                          </Text>
                          <Button
                            ml={1}
                            onClick={() =>
                              setCurrentVersions((prev) => ({
                                ...prev,
                                [id]:
                                  (currentVersion + 1) % versionArray.length,
                              }))
                            }
                            size="xs"
                            variant="ghost"
                          >
                            →
                          </Button>
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
              );
            })}
          <div ref={messagesEndRef} />
          {showScrollButton && (
            <IconButton
              aria-label="Scroll to bottom"
              bottom="110px"
              icon={<FiChevronDown />}
              isRound
              onClick={scrollToBottom}
              position="fixed"
              right="10vw"
              size="md"
              transform="translateX(50%)"
              zIndex="3"
            />
          )}
        </Box>
      </Flex>
      <Box
        backgroundColor="gray.1100"
        bottom="0"
        display="flex"
        justifyContent="center"
        padding={{ base: "16px", md: "32px" }}
        position="sticky"
        width="100%"
      >
        <Box maxWidth="660px" position="relative" width="100%">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit();
            }}
            style={{ width: "100%" }}
          >
            <Textarea
              autoFocus
              backgroundColor="gray.900"
              border="1px solid"
              borderColor="gray.800"
              borderRadius="24px"
              color="white"
              fontSize="16px"
              fontWeight="400"
              height={textareaHeight}
              letterSpacing="0.01em"
              lineHeight="24px"
              maxWidth="100%"
              minHeight="50px"
              onChange={(e) => {
                setMessage(e.target.value);
                const textarea = e.target as HTMLTextAreaElement;
                textarea.style.height = "50px"; // Reset to initial height
                const newHeight = Math.max(textarea.scrollHeight, 50);
                textarea.style.height = `${newHeight}px`;
                setTextareaHeight(`${newHeight}px`);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey && !isMobile) {
                  e.preventDefault();
                  void handleSubmit();
                  const textarea = e.target as HTMLTextAreaElement;
                  textarea.style.height = "50px";
                }
              }}
              overflow="hidden"
              padding="12px 16px"
              placeholder="Type your message here..."
              ref={textareaRef}
              resize="none"
              size="lg"
              value={message}
              width="100%"
            />
            <Button
              border="1px solid"
              borderColor="gray.800"
              borderRadius="50%"
              bottom={"30px"}
              height="40px"
              isLoading={createTurns.isPending}
              onClick={(e) => {
                e.preventDefault();
                void handleSubmit();
              }}
              position="absolute"
              right="5px"
              size="sm"
              top={"auto"}
              type="submit"
              variant="primary"
              width="40px"
              zIndex="100"
            >
              <FiSend />
            </Button>
          </form>
          <Text color="gray.400" mt={2} textAlign="center" variant="12-reg">
            Remember: Everything characters say is made up!
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default function ChatPage() {
  return (
    <Suspense fallback={<ChatDetailsPageSuspense />}>
      <ChatLayoutComponent />
    </Suspense>
  );
}

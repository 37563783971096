import * as React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FiAlertCircle,
  FiAperture,
  FiChevronLeft,
  FiChevronRight,
  FiHome,
  FiLogIn,
  FiMessageSquare,
  FiPlus,
  FiSettings,
  FiTwitter,
  FiUser,
} from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import type { ApiResponseUserWithSubscription } from "@charry/models";
import { isSuperUser } from "@charry/utils";

import CharryLogo from "~/assets/charry.svg";
import { DISCORD_INVITE_LINK, TWITTER_LINK } from "~/lib/const";
import useCharacterList from "~/ui/hooks/useCharacterList.hook";
import useChat from "~/ui/hooks/useChat.hook";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useUser from "~/ui/hooks/useUser.hook";
import AuthMenu from "../AuthMenu";
import LoginRegisterModal from "./LoginRegisterModal";
import { NavButton } from "./NavButton";
import RecentChatNavButton from "./RecentChatNavButton";

const OrganizationDetails = ({
  user,
}: {
  user?: ApiResponseUserWithSubscription;
}) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Box
        alignItems="center"
        border="1px solid"
        borderColor="gray.800"
        borderRadius="50%"
        display="flex"
        height="40px"
        justifyContent="space-between"
        padding="8px 12px"
        position="relative"
        width="40px"
      >
        <Box
          alignItems="center"
          borderRadius="50%"
          color="white"
          display="flex"
          fontSize="16px"
          fontWeight="bold"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          {user?.username?.charAt(0)}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      border="1px solid"
      borderColor="gray.800"
      borderRadius="8px"
      display="flex"
      justifyContent="space-between"
      padding="8px 12px"
    >
      <Box alignItems="center" display="flex">
        <Link to="/">
          <Image
            alt="Charry Logo"
            height="20"
            src={CharryLogo}
            style={{
              height: "20px",
              maxWidth: "20px",
            }}
            width="20"
          />
        </Link>
        <Box width="8px" />
        <Text
          color="white"
          maxWidth="128px"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          variant="14-bold"
        >
          {user?.username}
        </Text>
      </Box>
      <ChevronDownIcon color="gray.600" fontSize="24px" />
    </Box>
  );
};

const SideNavigation: React.FC = () => {
  const isMobile = useIsMobile();
  const { isAuthenticated, user } = useUser();
  const navigate = useNavigate();
  const [isCollapsed, _setIsCollapsed] = React.useState(
    useBreakpointValue({ base: false, md: true }),
  );

  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleNavClick = (path: string) => {
    if (isAuthenticated) {
      navigate(path);
    } else {
      onOpen();
    }
  };

  const { characterList } = useCharacterList();

  const { chatList } = useChat();

  const ToggleButton = ({
    isCollapsed,
    onClick,
  }: {
    onClick: () => void;
    isCollapsed: boolean;
  }) => (
    <Button
      aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      bg="gray.1100"
      borderRadius="full"
      bottom="100px"
      left="10px"
      onClick={onClick}
      padding="0px"
      position="absolute"
      size="sm"
      variant="outline"
      zIndex="5"
    >
      {isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
    </Button>
  );

  const recentChats = React.useMemo(() => {
    if (!isAuthenticated) return [];

    const uniqueChats = chatList.reduce(
      (acc, chat) => {
        const character = characterList.find((c) => c.id === chat.characterId);
        if (!character) return acc;

        const existingChat = acc.find(
          (c) => c.characterName === character.name,
        );
        if (!existingChat) {
          acc.push({
            characterId: chat.characterId,
            characterImageUrl: character.imageUrl ?? "",
            characterName: character.name,
            id: chat.id,
            name:
              chat.turns[chat.turns.length - 1]?.content?.slice(0, 15) +
                "..." || "New Chat",
          });
        }
        return acc;
      },
      [] as {
        id: string;
        name: string;
        characterId: string;
        characterName: string;
        characterImageUrl: string;
      }[],
    );

    return uniqueChats.slice(0, 5);
  }, [characterList, chatList, isAuthenticated]);

  return (
    <Flex
      as="section"
      bg="gray.1100"
      borderColor="gray.800"
      borderRight={
        isSidebarCollapsed ? "0" : "1px solid var(--chakra-colors-gray-800)"
      }
      flexShrink={0}
      height="100dvh"
      minHeight="-webkit-fill-available"
      padding={isMobile ? (isSidebarCollapsed ? "0px" : "5px") : "16px"}
      transition="width 0.3s"
      width={isMobile ? (isSidebarCollapsed ? "0px" : "50px") : "240px"}
    >
      {isMobile && (
        <ToggleButton
          isCollapsed={isSidebarCollapsed}
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        />
      )}
      <Flex
        direction="column"
        display={isMobile && isSidebarCollapsed ? "none" : "flex"}
        flex="1"
        justifyContent="space-between"
      >
        <Box flex="1" position="relative" width="fill-available">
          {isMobile ? <Box height="8px" /> : null}
          {isAuthenticated ? (
            <AuthMenu>
              <OrganizationDetails user={user ?? undefined} />
            </AuthMenu>
          ) : (
            <>
              <Button
                onClick={onOpen}
                size="sm"
                variant="secondary"
                width="100%"
              >
                {isMobile ? <FiLogIn /> : "Login / Register"}
              </Button>
              <LoginRegisterModal isOpen={isOpen} onClose={onClose} />
            </>
          )}
          <Box height="24px" />
          {isAuthenticated ? (
            <Box flex="1">
              <Button
                mb={4}
                onClick={() => handleNavClick("/characters/create")}
                size="sm"
                variant="outline"
                width="100%"
              >
                {!isMobile ? (
                  <Flex alignItems="center">
                    <FiPlus />
                    &nbsp;Create
                  </Flex>
                ) : (
                  <FiPlus />
                )}
              </Button>
            </Box>
          ) : (
            <Box flex="1">
              <Button
                mb={4}
                onClick={onOpen}
                size="sm"
                variant="outline"
                width="100%"
              >
                {!isMobile ? (
                  <Flex alignItems="center">
                    <FiPlus />
                    &nbsp;Create
                  </Flex>
                ) : (
                  <FiPlus />
                )}
              </Button>
            </Box>
          )}
          <Box flex="1">
            <NavButton
              icon={FiHome}
              isCollapsed={isCollapsed}
              label="Explore"
              link="/"
            />
            <NavButton
              icon={FiMessageSquare}
              isCollapsed={isCollapsed}
              label="Chat History"
              onClick={() => handleNavClick("/recent")}
            />
            <NavButton
              icon={FiAperture}
              isCollapsed={isCollapsed}
              label="My Characters"
              onClick={() => handleNavClick("/characters")}
            />
            <NavButton
              icon={FiSettings}
              isCollapsed={isCollapsed}
              label="Settings"
              onClick={() => handleNavClick("/settings")}
            />
            {user != null && user.username == null && (
              <NavButton
                icon={FiUser}
                isCollapsed={isCollapsed}
                label="Set Username"
                link="/set-username"
              />
            )}
            {isSuperUser(user?.email ?? "") && (
              <NavButton
                icon={FiAlertCircle}
                isActiveLink={(path) => path.includes("/super")}
                isCollapsed={isCollapsed}
                label="Super Admin"
                link="/super/users"
              />
            )}
            {!isMobile && isAuthenticated && recentChats.length > 0 && (
              <>
                <Box height="24px" />
                <Text color="gray.500" fontSize="sm" mb={2} ml={2}>
                  Recent Chats
                </Text>
                {recentChats.map((chat) => (
                  <RecentChatNavButton
                    characterImageUrl={chat.characterImageUrl}
                    characterName={chat.characterName}
                    isCollapsed={isCollapsed ?? false}
                    key={chat.id}
                    link={`/chat/${chat.id}`}
                  />
                ))}
              </>
            )}
            {isMobile && isAuthenticated && recentChats.length > 0 && (
              <>
                <Box height="24px" />
                {recentChats.map((chat) => (
                  <RecentChatNavButton
                    characterImageUrl={chat.characterImageUrl}
                    characterName={chat.characterName}
                    isCollapsed={true}
                    key={chat.id}
                    link={`/chat/${chat.id}`}
                  />
                ))}
              </>
            )}
            <Box height="24px" />
            <Box bottom="0px" position="absolute">
              <NavButton
                icon={FiMessageSquare}
                isCollapsed={isCollapsed}
                label="Discord"
                link={DISCORD_INVITE_LINK}
              />
              <NavButton
                icon={FiTwitter}
                isCollapsed={isCollapsed}
                label="Twitter"
                link={TWITTER_LINK}
              />
            </Box>
          </Box>
        </Box>
        <LoginRegisterModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Flex>
  );
};

export default SideNavigation;

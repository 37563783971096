import React, { useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";

import PosthogEvent from "~/constants/PosthogEvent.constants";
import parseMutationError from "~/lib/parseMutationError";
import { api } from "~/lib/trpc";
import PasswordInput from "~/ui/components/PasswordInput";
import useTracking from "~/ui/hooks/useTracking.hook";

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { track } = useTracking();
  const [password, setPassword] = React.useState<string>("");
  const [errors, setErrors] = React.useState<Record<string, unknown>>({});
  const [loading, setLoading] = React.useState(false);
  const [searchParams] = useSearchParams();

  const finishResetUserPassword = api.user.finishReset.useMutation();

  const finishResetPassword = useCallback(async () => {
    setLoading(true);
    track({
      event: PosthogEvent.AuthRequestedPasswordReset,
    });
    try {
      track;
      const res = await finishResetUserPassword.mutateAsync({
        password,
        resetToken: searchParams.get("token") ?? "",
      });

      if (res.success) {
        toast({
          description: "Please login with your new password to continue.",
          duration: 5000,
          isClosable: true,
          status: "success",
          title: "Password Reset",
        });

        navigate("/login");
      }
    } catch (e1) {
      setLoading(false);
      try {
        const parsedError = parseMutationError(e1);
        setErrors(parsedError);
        toast({
          description:
            "Some of the fields above are invalid. Please correct the errors and try again.",
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Invalid Fields",
        });
      } catch (e2) {
        toast({
          description: (e1 as Error).message,
          duration: 5000,
          isClosable: true,
          status: "error",
          title: "Error Logging In",
        });
      }
    }
  }, [
    track,
    password,
    searchParams,
    finishResetUserPassword,
    navigate,
    toast,
    setErrors,
  ]);

  return (
    <Box
      backgroundColor="gray.1000"
      display="flex"
      height="100vh"
      justifyContent="center"
      width="100vw"
    >
      <Flex
        alignItems="center"
        borderRadius="8px"
        boxSizing="border-box"
        flexDirection="column"
        height="fit-content"
        position="relative"
        top={{ base: "0", md: "96px" }}
        width={{ base: "100%", md: "420px" }}
      >
        {/* <Link to="/">
          <Image
            alt="Logo"
            src={"/logos/open-rates-icon.png"}
            width="48"
            height="48"
            style={{
              maxWidth: "48px",
              height: "48px",
              borderRadius: "8px",
            }}
          />
        </Link> */}
        <Box height="24px" />
        <Text color="white" marginLeft="16px" variant="30-bold">
          Reset Password
        </Text>
        <Box height="12px" />
        <Text color="gray.400" marginLeft="16px" variant="16-reg">
          Enter a new password for your account.
        </Text>
        <Box height="32px" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void finishResetPassword();
          }}
          style={{ width: "100%" }}
        >
          <Stack spacing="20px" width="100%">
            <PasswordInput
              id="password"
              isInvalid={errors.password ? true : false}
              label="Password"
              onChange={(e) => setPassword(e.currentTarget.value)}
              placeholder="••••••••"
              size="lg"
              value={password}
              width="100%"
            />
          </Stack>
          <Stack spacing="0" width="100%">
            <Box height="24px" />
            {loading ? (
              <Flex alignItems="center" height="104px" justify="center">
                <Spinner color="blue.500" size="lg" />
              </Flex>
            ) : (
              <>
                <Button
                  isLoading={loading}
                  size="lg"
                  type="submit"
                  variant="primary"
                  width="100%"
                >
                  Set New Password
                </Button>
              </>
            )}
          </Stack>
        </form>
      </Flex>
    </Box>
  );
};

export default ResetPasswordPage;

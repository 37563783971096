import { Box, Skeleton } from "@chakra-ui/react";

import { Content } from "../layouts/Page.layout";
import PageLayoutSuspense from "./PageLayout.suspense";

export default function RecentChatsPageSuspense() {
  return (
    <>
      <PageLayoutSuspense pageTitle="Characters">
        <Content>
          <Box height="16px" />
          <Skeleton borderRadius="xl" height="110px" width="100%" />
          <Box height="1rem" />
          <Skeleton borderRadius="xl" height="110px" width="100%" />
          <Box height="1rem" />
          <Skeleton borderRadius="xl" height="110px" width="100%" />
          <Box height="1rem" />
          <Skeleton borderRadius="xl" height="110px" width="100%" />
          <Box height="1rem" />
          <Skeleton borderRadius="xl" height="110px" width="100%" />
        </Content>
      </PageLayoutSuspense>
    </>
  );
}

import { useCallback } from "react";
import posthog from "posthog-js";

export function useTrack() {
  const track = useCallback(
    (event: { name: string; properties: Record<string, unknown> }) => {
      posthog.capture(event.name, event.properties);
    },
    [],
  );

  return track;
}

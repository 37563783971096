import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Skeleton,
} from "@chakra-ui/react";
import { FiMessageCircle } from "react-icons/fi";

import PageLayoutSuspense from "./PageLayout.suspense";

export default function CharactersDetailContentSuspense() {
  return (
    <PageLayoutSuspense pageTitle="Character Details">
      <Box ml={{ base: 4, md: 8 }} mt={8}>
        <Container maxW="1368px" px={{ base: 4, md: 8 }}>
          <Grid gap={6} templateColumns={{ base: "1fr", md: "300px 1fr" }}>
            <GridItem>
              <Flex align="center" direction="column">
                <Skeleton
                  borderRadius="full"
                  height="150px"
                  mb={4}
                  width="150px"
                />
                <Skeleton height="24px" mb={2} width="80%" />
                <Skeleton height="20px" mb={4} width="60%" />
                <Skeleton height="20px" mb={4} width="40%" />
                <Button
                  isLoading
                  leftIcon={<FiMessageCircle />}
                  size="lg"
                  variant="primary"
                  width="100%"
                >
                  New Chat
                </Button>
              </Flex>
            </GridItem>
            <GridItem>
              <Box mb={6}>
                <Skeleton height="24px" mb={2} width="120px" />
                <Skeleton height="60px" width="100%" />
              </Box>
              <Box mb={6}>
                <Skeleton height="24px" mb={2} width="150px" />
                <Skeleton height="40px" width="100%" />
              </Box>
              <Box mb={6}>
                <Skeleton height="24px" mb={2} width="140px" />
                <Skeleton height="100px" width="100%" />
              </Box>
              <Box mb={6}>
                <Skeleton height="24px" mb={2} width="120px" />
                <HStack spacing={2} wrap="wrap">
                  {[...Array(5)].map((_, i) => (
                    <Skeleton
                      borderRadius="full"
                      height="24px"
                      key={i}
                      width="80px"
                    />
                  ))}
                </HStack>
              </Box>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </PageLayoutSuspense>
  );
}

import type { ButtonProps } from "@chakra-ui/react";
import type { ElementType } from "react";
import React from "react";
import {
  Button,
  Link as ChakraLink,
  HStack,
  Icon,
  Image,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

type NavButtonProps = ButtonProps & {
  icon?: ElementType;
  image?: string;
  label: string;
  link?: string;
  isActiveLink?: (path: string) => boolean;
  isExternal?: boolean;
  isCollapsed?: boolean;
  leftElement?: React.ReactNode;
  tag?: string;
};

export const NavButton = (props: NavButtonProps) => {
  const location = useLocation();
  const {
    icon,
    image,
    isActiveLink,
    isCollapsed,
    isExternal,
    label,
    leftElement,
    link,
    ...buttonProps
  } = props;

  const active = link
    ? (isActiveLink?.(location.pathname) ?? location.pathname === link)
    : false;

  const button = (
    <Button
      _hover={{ backgroundColor: "gray.900", cursor: "pointer" }}
      backgroundColor={active ? "gray.900" : "transparent"}
      justifyContent="start"
      margin="0 0 4px"
      size="sm"
      transition="none"
      width="100%"
      {...buttonProps}
    >
      <HStack spacing="3">
        {leftElement ? (
          leftElement
        ) : image ? (
          <Image height="16px" src={image} width="16px" />
        ) : icon ? (
          <Icon as={icon} boxSize="4" color="white" />
        ) : null}
        {!isCollapsed && label && (
          <Text
            color="white"
            display={{
              base: "none",
              lg: "inline-flex",
              md: "none",
              mlg: "inline-flex",
              sm: "none",
            }}
            variant="14-semi"
          >
            {label}
          </Text>
        )}
        {props.tag && !isCollapsed ? (
          <Tag
            bg="gray.800"
            color="gray.500"
            display={{
              base: "none",
              lg: "inline-flex",
              md: "none",
              mlg: "inline-flex",
              sm: "none",
            }}
            size="sm"
          >
            {props.tag}
          </Tag>
        ) : null}
      </HStack>
    </Button>
  );

  if (!link) return button;

  if (isExternal) {
    return (
      <ChakraLink href={link} style={{ width: "100%" }} target="_blank">
        {button}
      </ChakraLink>
    );
  }

  return (
    <Link style={{ width: "100%" }} to={link}>
      {button}
    </Link>
  );
};

import { useAtom } from "jotai";

import { api } from "~/lib/trpc";
import { CharacterState } from "../jotai/character.jotai";
import useCharacterList from "./useCharacterList.hook";

export default function useCharacter(characterId: string) {
  const { setCharacterList } = useCharacterList();

  const [{ character }] = api.character.get.useSuspenseQuery(
    {
      id: characterId,
    },
    {
      refetchInterval: 60_000,
      /**
       * todo: figure out how to better handle
       * suspense in react query
       * https://github.com/TanStack/query/discussions/6361
       * stupid hack
       */
      staleTime: 0,
    },
  );

  const updateCharacter = api.character.update.useMutation({
    onSuccess: ({ character }) => {
      if (!character) return;

      setCharacterList((current) => {
        return current.map((c) => {
          if (c.id === character.id) {
            return character;
          }
          return c;
        });
      });
    },
  });

  const [updateCharacterParams, setUpdateCharacterParams] = useAtom(
    CharacterState.updateCharacterParams(characterId),
  );

  const archiveCharacter = api.character.archive.useMutation({
    onSuccess: ({ character }) => {
      if (!character) return;

      setCharacterList((current) => {
        return current.map((c) => {
          if (c.id === character.id) {
            return character;
          }
          return c;
        });
      });
    },
  });

  return {
    archiveCharacter,
    character,
    setUpdateCharacterParams,
    updateCharacter,
    updateCharacterParams,
  };
}

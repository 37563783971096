import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";

import type { ModalConfig } from "../components/modal/Modal";

const modals = atomWithDefault<ModalConfig[]>((get) => get(atom([])));

export const AppState = {
  modals,
};

import type { InputProps as ChakraInputProps } from "@chakra-ui/react";
import React from "react";
import { Input as ChakraInput, FormControl } from "@chakra-ui/react";

import Label from "./Label";

export type SearchInputProps = ChakraInputProps & {
  label?: string;
  raw?: boolean;
};

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => {
    const { label, raw, ...rest } = props;
    const input = (
      <ChakraInput
        _focusVisible={{
          borderColor: "gray.500",
        }}
        _hover={{
          borderColor: "gray.600",
        }}
        _placeholder={{
          color: "gray.600",
        }}
        backgroundColor="gray.900"
        border="0px"
        borderRadius="6px"
        color="white"
        paddingLeft="40px"
        ref={ref}
        {...rest}
      />
    );

    if (raw) {
      return <>{input}</>;
    }

    return (
      <FormControl width={props.width}>
        {label ? <Label>{label}</Label> : null}
        {input}
      </FormControl>
    );
  },
);

SearchInput.displayName = "SearchInput";

export default SearchInput;

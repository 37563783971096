import "./index.css";

import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { createStore } from "jotai";
import { Provider } from "jotai/react";
import posthog from "posthog-js";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ReactTooltip from "react-tooltip";

import type { AppConfig } from "./lib/types";
import {
  ACTIVATE_MOBILE_LANDING_PAGE,
  NODE_ENV,
  POSTHOG_API_KEY,
  POSTHOG_API_URL,
  SENTRY_DSN,
  SENTRY_SAMPLING_RATE,
} from "~/lib/const";
import { TRPCReactProvider } from "./lib/trpc";
import AccountSettingsPage from "./pages/dashboard/AccountSettings.page";
import CharacterDetailsPage from "./pages/dashboard/characters/CharacterDetails.page";
import CharacterListPage from "./pages/dashboard/characters/CharacterList.page";
import CharacterSettingsPage from "./pages/dashboard/characters/CharacterSettings.page";
import CreateCharacterPage from "./pages/dashboard/characters/CreateCharacter.page";
import ChatPage from "./pages/dashboard/Chat.page";
import ExplorePage from "./pages/dashboard/Explore.page";
import RecentChatsPage from "./pages/dashboard/RecentChats.page";
import SuperUsersPage from "./pages/dashboard/super/SuperUsers.page";
import DisabledUserPage from "./pages/DisabledUser.page";
import ErrorPage from "./pages/Error.page";
import ForgotPasswordPage from "./pages/ForgotPassword.page";
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicy.page";
import TermsPage from "./pages/legal/Terms.page";
import LoginPage from "./pages/Login.page";
import MobileAppLandingPage from "./pages/MobileAppLanding.page";
import RegisterPage from "./pages/Register.page";
import ResetPasswordPage from "./pages/ResetPassword.page";
import SetUsernamePage from "./pages/SetUsername.page";
import SupportPage from "./pages/support/Support.page";
import VerifyEmailConfirmationPage from "./pages/VerifyEmailConfirmation.page";
import VerifyEmailPage from "./pages/VerifyEmailPage.page";
import GlobalErrorBoundary from "./ui/components/GlobalErrorBoundary";
import { ModalProvider } from "./ui/components/modal/Modal";
import { AppConfigContext } from "./ui/contexts/AppConfig.context";
import CharacterLayout from "./ui/layouts/Character.layout";
import DashboardLayout from "./ui/layouts/Dashboard.layout";
import SuperLayout from "./ui/layouts/Super.layout";
import { theme } from "./ui/style/theme";

const AppContainer = () => {
  const location = useLocation();
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  return <Outlet />;
};

const getRouter = (_config: AppConfig) => {
  if (ACTIVATE_MOBILE_LANDING_PAGE) {
    return createBrowserRouter([
      {
        element: <MobileAppLandingPage />,
        index: true,
        path: "/",
      },
      {
        element: <PrivacyPolicyPage hideLayout />,
        path: "/privacy-policy",
      },
      {
        element: <TermsPage hideLayout />,
        path: "/terms",
      },
      {
        element: <Navigate replace to="/" />,
        path: "*",
      },
    ]);
  }

  return createBrowserRouter([
    {
      children: [
        {
          children: [
            {
              element: <ExplorePage />,
              index: true,
            },
            {
              element: <AccountSettingsPage />,
              path: "/settings",
            },
            {
              element: <CharacterListPage />,
              path: "/characters",
            },
            {
              element: <CreateCharacterPage />,
              path: "/characters/create",
            },
            {
              children: [
                {
                  element: <CharacterDetailsPage />,
                  path: "/characters/details/:characterId",
                },
                {
                  element: <CharacterSettingsPage />,
                  path: "/characters/settings/:characterId",
                },
              ],
              element: <CharacterLayout />,
              path: "/characters",
            },
            {
              element: <ChatPage />,
              path: "/chat/:chatId",
            },
            {
              element: <RecentChatsPage />,
              path: "/recent",
            },
            {
              element: <CharacterDetailsPage />,
              path: "/character/:characterId",
            },
            {
              children: [
                {
                  element: <SuperUsersPage />,
                  path: "/super/users",
                },
              ],
              element: <SuperLayout />,
              path: "/super",
            },
          ],
          element: <DashboardLayout />,
          path: "/",
        },
        {
          element: <SupportPage />,
          path: "/support",
        },
        {
          element: <PrivacyPolicyPage />,
          path: "/privacy-policy",
        },
        {
          element: <TermsPage />,
          path: "/terms",
        },
        {
          element: <SetUsernamePage />,
          path: "/set-username",
        },
        {
          element: <LoginPage />,
          path: "/login",
        },
        {
          element: <RegisterPage />,
          path: "/register",
        },
        {
          element: <ForgotPasswordPage />,
          path: "/forgot-password",
        },
        {
          element: <ResetPasswordPage />,
          path: "/reset-password",
        },
        {
          element: <VerifyEmailPage />,
          path: "/verify-email",
        },
        {
          element: <VerifyEmailConfirmationPage />,
          path: "/verify-email-confirmation/:emailVerificationToken",
        },
        {
          element: <DisabledUserPage />,
          path: "/paused",
        },
        {
          element: <Navigate replace to="/" />,
          path: "*",
        },
      ],
      element: <AppContainer />,
      errorElement: <ErrorPage />,
      path: "/",
    },
  ]);
};

const jotaiStore = createStore();

export const App: React.FC<{ appConfig: AppConfig }> = ({ appConfig }) => {
  useEffect(() => {
    try {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: NODE_ENV,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: SENTRY_SAMPLING_RATE,
        //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/*\.charry\.ai/],
        // Performance Monitoring
        tracesSampleRate: SENTRY_SAMPLING_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        // "st" is short for "Sentry Tunnel"
        tunnel: "/st/",
      });
    } catch (error) {
      /* empty */
    }

    try {
      posthog.init(POSTHOG_API_KEY, {
        api_host: POSTHOG_API_URL,
        autocapture: false,
        enable_recording_console_log: false,
        session_recording: {
          maskAllInputs: false,
        },
      });
    } catch (error) {
      /* empty */
    }
  });

  return (
    <TRPCReactProvider>
      <AppConfigContext.Provider value={appConfig}>
        <Provider store={jotaiStore}>
          <ChakraProvider theme={theme}>
            <GlobalErrorBoundary>
              <ModalProvider />
              <ReactTooltip />
              <RouterProvider router={getRouter(appConfig)} />
            </GlobalErrorBoundary>
          </ChakraProvider>
        </Provider>
      </AppConfigContext.Provider>
    </TRPCReactProvider>
  );
};

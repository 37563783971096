import {
  Button,
  Circle,
  Flex,
  Heading,
  Text,
  theme,
  VStack,
} from "@chakra-ui/react";
import { FiAlertCircle, FiRotateCw } from "react-icons/fi";

export default function ErrorPageContent() {
  return (
    <Flex
      alignItems="center"
      flexDir="column"
      gridGap={"32px"}
      maxWidth="600px"
    >
      <VStack>
        <Circle backgroundColor="gray.800" size="50px">
          <FiAlertCircle color={theme.colors.red[400]} size="22px" />
        </Circle>
        <Heading size="md">Something went wrong</Heading>
        <Text textAlign={"center"}>
          If this issue persists, please contact support at{" "}
          <a href="mailto:support@charry.ai" target="_blank">
            support@charry.ai
          </a>
        </Text>
      </VStack>
      <VStack>
        <Button
          onClick={() => window.location.reload()}
          size="lg"
          variant="primary"
          width="200px"
        >
          <FiRotateCw color={theme.colors.white} size="16px" />
          <Text color="white" marginLeft="8px" variant="14-med">
            Refresh
          </Text>
        </Button>
        <Text maxW="30ch">Refreshing may resolve the issue.</Text>
      </VStack>
    </Flex>
  );
}

import type { CreateTRPCClientOptions } from "@trpc/client";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCClient } from "@trpc/client";
import {
  createTRPCReact,
  httpBatchLink,
  loggerLink,
  splitLink,
  unstable_httpSubscriptionLink,
} from "@trpc/react-query";
import { EventSourcePolyfill } from "event-source-polyfill";
import superjson from "superjson";

import type { ServerRouter } from "@charry/api";

import { clearToken, getToken, isAuthenticated } from "~/lib/auth";
import { API_URL } from "~/lib/const";

// polyfill EventSource
if (typeof window !== "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).EventSource = EventSourcePolyfill;
}

const trpcOps: CreateTRPCClientOptions<ServerRouter> = {
  links: [
    loggerLink({
      enabled: (op) =>
        process.env.NODE_ENV === "development" ||
        (op.direction === "down" && op.result instanceof Error),
    }),
    splitLink({
      condition: (op) => op.type === "subscription",
      false: httpBatchLink({
        headers() {
          const headers: Record<string, string> = {};
          if (isAuthenticated()) {
            headers.Authorization = `Bearer ${getToken()}`;
          }

          return headers;
        },
        transformer: superjson,
        url: `${API_URL}/api/trpc/`,
      }),
      true: unstable_httpSubscriptionLink({
        eventSourceOptions: () => {
          const headers: Record<string, string> = {};
          if (isAuthenticated()) {
            headers.Authorization = `Bearer ${getToken()}`;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return { headers } as any;
        },
        transformer: superjson,
        url: `${API_URL}/api/trpc/`,
      }),
    }),
  ],
};

export const api = createTRPCReact<ServerRouter>();
export const trpc = createTRPCClient<ServerRouter>(trpcOps);

export function TRPCReactProvider(props: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
              try {
                const isTokenInvalidError =
                  (error as Error).message === "Invalid token.";
                if (isTokenInvalidError) {
                  console.warn(
                    "Invalid user authentication — user will be logged out.",
                  );
                  clearToken();
                  return;
                }
              } catch {
                // No action.
              }
              console.error(error);
            },

            // TODO: figure out why the onError is not typed correctly
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any,
        },
      }),
  );

  const [trpcClient] = useState(() => api.createClient(trpcOps));

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {props.children}
      </QueryClientProvider>
    </api.Provider>
  );
}

export type RouterInput = inferRouterInputs<ServerRouter>;
export type RouterOutput = inferRouterOutputs<ServerRouter>;

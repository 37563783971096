import z from "zod";

import type { LogManager } from "../log/LogManager";
import { ConsoleLogManager } from "../log/LogManager";

export enum StatusCodes {
  UNKNOWN_CODE = "0",
  OK = "200",
  BAD_REQUEST = "400",
  UNAUTHORIZED = "401",
  PAYMENT_REQUIRED = "402",
  FORBIDDEN = "403",
  NOT_FOUND = "404",
  UNPROCESSABLE_ENTITY = "422",
  INTERNAL_SERVER_ERROR = "500",
  GATEWAY_TIMEOUT = "504",
}

/** ******************************************************************************
 * Error Handling
 ******************************************************************************* */

export const serviceError = z.object({
  fields: z.record(z.string(), z.string()).optional(),
  message: z.string(),
});

export type ServiceError = z.infer<typeof serviceError>;

export const toError = (error: unknown): ServiceError => {
  if (error instanceof Error) {
    return {
      message: error.message,
    };
  } else if (typeof error === "string") {
    return { message: error };
  } else if (
    error instanceof Object &&
    Object.keys(error).includes("message")
  ) {
    return {
      message: (
        error as {
          message: string;
        }
      ).message,
    };
  } else {
    return {
      message: "Unknown error",
    };
  }
};

/** ******************************************************************************
 *  Authentication
 ******************************************************************************* */

export const authToken = z.string();

export const authData = z.object({
  apiKey: z.string().optional(),
  email: z.string().optional(),
  organizationId: z.string().optional(),
  token: authToken.optional(),
  userId: z.string().optional(),
});

export type AuthToken = z.infer<typeof authToken>;
export type AuthData = z.infer<typeof authData>;

/** ******************************************************************************
 *  Request/Response
 ******************************************************************************* */

export const serviceRequest = z.object({});

export const authedServiceRequest = z.object({
  auth: authData,
});

const statuses = Object.keys(StatusCodes) as StatusCodes[];

export const serviceResponse = z.object({
  error: serviceError.optional(),
  status: z.enum([statuses[0] ?? "", ...statuses]),
});

export type ServiceRequest = z.infer<typeof serviceRequest>;
export type AuthedServiceRequest = z.infer<typeof authedServiceRequest>;
export type ServiceResponse = z.infer<typeof serviceResponse>;

export type BaseServiceParams = {
  serviceName: string;
};

export class BaseService {
  public serviceName: string;

  public logger: LogManager;

  constructor(params: BaseServiceParams) {
    this.serviceName = params.serviceName;
    this.logger = new ConsoleLogManager({ name: this.serviceName });
  }

  shutdown = async (): Promise<void> => {
    // Handled by services if necessary.
  };
}

import { z } from "zod";

import {
  authedServiceRequest,
  serviceRequest,
  serviceResponse,
} from "./BaseService";

export const TurnRoleSchema = z.enum(["system", "assistant", "user"]);

export const TurnStateSchema = z.enum([
  "CREATED",
  "IN_PROGRESS",
  "COMPLETED",
  "FAILED",
  "REGENERATE",
  "REGENERATING",
  "REGENERATED",
  "REGENERATION_FAILED",
]);

export const TurnSchema = z.object({
  characterId: z.string().nullable(),
  chatId: z.string(),
  content: z.string().nullable(),
  createdAt: z.date(),
  id: z.string(),
  role: TurnRoleSchema,
  state: TurnStateSchema,
  updatedAt: z.date(),
  userId: z.string(),
  versions: z.array(z.string()).default([]),
});

export type Turn = z.infer<typeof TurnSchema>;

/** ******************************************************************************
 *  Turn Service
 ******************************************************************************* */
export type TurnService = {
  create(
    request: CreateTurnsRequest,
    { requirePaymentCheck }?: { requirePaymentCheck?: boolean },
  ): Promise<CreateTurnsResponse>;
  update(request: UpdateTurnRequest): Promise<UpdateTurnResponse>;
  stream(request: StreamTurnRequest): AsyncGenerator<StreamTurnMessage>;
  get(request: GetTurnRequest): Promise<GetTurnResponse>;
  list(request: ListTurnsRequest): Promise<ListTurnsResponse>;
  count(request: CountTurnsRequest): Promise<CountTurnsResponse>;
};

/** ******************************************************************************
 *  Create Turn
 ******************************************************************************* */

export const createTurnsParams = z.object({
  turns: z.array(
    z.object({
      characterId: z.string(),
      chatId: z.string(),
      content: z.string().nullable(),
      id: z.string().optional(),
      role: TurnRoleSchema,
      state: TurnStateSchema,
      versions: z.array(z.string()).default([]),
    }),
  ),
});

export const createTurnsRequest = authedServiceRequest.merge(
  z.object({
    params: createTurnsParams,
  }),
);

export const createTurnsResponse = serviceResponse.merge(
  z.object({
    turns: z.array(TurnSchema).optional(),
  }),
);

export type CreateTurnsParams = z.infer<typeof createTurnsParams>;
export type CreateTurnsRequest = z.infer<typeof createTurnsRequest>;
export type CreateTurnsResponse = z.infer<typeof createTurnsResponse>;

/** ******************************************************************************
 *  Update Turn
 ******************************************************************************* */

export const updateTurnParams = z.object({
  characterId: z.string(),
  chatId: z.string(),
  content: z.string().nullable(),
  id: z.string(),
  role: TurnRoleSchema,
  state: TurnStateSchema,
  versions: z.array(z.string()).default([]),
});

export const updateTurnRequest = authedServiceRequest.merge(
  z.object({
    params: updateTurnParams,
  }),
);

export const updateTurnResponse = serviceResponse.merge(
  z.object({
    turn: TurnSchema.optional(),
  }),
);

export type UpdateTurnParams = z.infer<typeof updateTurnParams>;
export type UpdateTurnRequest = z.infer<typeof updateTurnRequest>;
export type UpdateTurnResponse = z.infer<typeof updateTurnResponse>;

/** ******************************************************************************
 *  Stream Turn
 ******************************************************************************* */

export const streamTurnParams = z.object({
  id: z.string(),
});

export const streamTurnRequest = authedServiceRequest.merge(
  z.object({
    params: streamTurnParams,
  }),
);

export const streamTurnMessage = z.object({
  turn: TurnSchema.optional(),
});

export type StreamTurnParams = z.infer<typeof streamTurnParams>;
export type StreamTurnRequest = z.infer<typeof streamTurnRequest>;
export type StreamTurnMessage = z.infer<typeof streamTurnMessage>;

/** ******************************************************************************
 *  Get Turn
 ******************************************************************************* */

export const getTurnParams = z.object({
  id: z.string(),
});

export const getTurnRequest = authedServiceRequest.merge(
  z.object({
    params: getTurnParams,
  }),
);

export const getTurnResponse = serviceResponse.merge(
  z.object({
    turn: TurnSchema.optional(),
  }),
);

export type GetTurnParams = z.infer<typeof getTurnParams>;
export type GetTurnRequest = z.infer<typeof getTurnRequest>;
export type GetTurnResponse = z.infer<typeof getTurnResponse>;

/** ******************************************************************************
 *  List Turns
 ******************************************************************************* */

export const listTurnsParams = z.object({
  chatId: z.string(),
});

export const listTurnsRequest = authedServiceRequest.merge(
  z.object({
    params: listTurnsParams,
  }),
);

export const listTurnsResponse = serviceResponse.merge(
  z.object({
    turns: z.array(TurnSchema).optional(),
  }),
);

export type ListTurnsParams = z.infer<typeof listTurnsParams>;
export type ListTurnsRequest = z.infer<typeof listTurnsRequest>;
export type ListTurnsResponse = z.infer<typeof listTurnsResponse>;

/** ******************************************************************************
 *  Count Turns
 ******************************************************************************* */

export const countTurnsParams = z.object({
  characterId: z.string(),
});

export const countTurnsRequest = serviceRequest.merge(
  z.object({
    params: countTurnsParams,
  }),
);

export const countTurnsResponse = serviceResponse.merge(
  z.object({
    count: z.number().optional(),
  }),
);

export type CountTurnsParams = z.infer<typeof countTurnsParams>;
export type CountTurnsRequest = z.infer<typeof countTurnsRequest>;
export type CountTurnsResponse = z.infer<typeof countTurnsResponse>;

import React, { useCallback, useState } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";

type Params = CreateCharacterParams | UpdateCharacterParams;

type CharacterTagsProps = {
  params: Params;
  setParams:
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"]
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"];
  errors: { tags?: string };
};

const MAX_TAGS = 5;

const CharacterTags: React.FC<CharacterTagsProps> = ({
  errors,
  params,
  setParams,
}) => {
  const [inputValue, setInputValue] = useState("");

  const addTag = useCallback(
    (tag: string) => {
      if (
        tag &&
        !params.tags?.includes(tag) &&
        (params.tags?.length ?? 0) < MAX_TAGS
      ) {
        // @ts-expect-error - need better types here.
        void setParams((prev) => ({
          ...prev,
          tags: prev.tags ? [...prev.tags, tag] : [tag],
        }));
      }
    },
    [params.tags, setParams],
  );

  const removeTag = useCallback(
    (tagToRemove: string) => {
      // @ts-expect-error - need better types here.
      void setParams((prev: { tags?: string[] }) => ({
        ...prev,
        tags: prev.tags?.filter((tag) => tag !== tagToRemove) ?? [],
      }));
    },
    [setParams],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === ",") {
        e.preventDefault();
        const tag = inputValue.trim();
        if (tag && (params.tags?.length ?? 0) < MAX_TAGS) {
          addTag(tag);
          setInputValue("");
        }
      }
    },
    [addTag, inputValue, params.tags?.length],
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    const tag = inputValue.trim();
    if (tag && (params.tags?.length ?? 0) < MAX_TAGS) {
      addTag(tag);
      setInputValue("");
    }
  }, [addTag, inputValue, params.tags?.length]);

  return (
    <Flex direction="column">
      <FormControl isInvalid={!!errors.tags}>
        <FormLabel>Tags (max 5)</FormLabel>
        <Input
          isDisabled={(params.tags?.length ?? 0) >= MAX_TAGS}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Add tags and press Enter or comma"
          type="text"
          value={inputValue}
        />
        <Wrap mt={2}>
          {params.tags?.map((tag, index) => (
            <WrapItem key={index}>
              <Tag
                borderRadius="full"
                colorScheme="blue"
                size="md"
                variant="solid"
              >
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton onClick={() => removeTag(tag)} />
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
        {(params.tags?.length ?? 0) >= MAX_TAGS && (
          <Text color="red.500" fontSize="sm" mt={2}>
            Maximum number of tags reached (5)
          </Text>
        )}
        <FormErrorMessage>{errors.tags}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};

export default CharacterTags;

import { api } from "~/lib/trpc";

export function useUsername(userId: string) {
  return api.user.getUsername.useQuery(
    { userId },
    {
      enabled: !!userId,
    },
  );
}

import { useEffect, useState } from "react";

import { api } from "~/lib/trpc";
import useIsAuthenticated from "./useIsAuthenticated.hook";
import useUser from "./useUser.hook";

export default function useCharacterLike(characterId: string) {
  const [isLiked, setIsLiked] = useState(false);
  const { isAuthenticated } = useIsAuthenticated();
  const { user } = useUser();
  const { data: character, refetch } = api.character.get.useQuery({
    id: characterId,
  });

  const likeCharacterMutation = api.character.like.useMutation({
    onSuccess: () => {
      void refetch();
    },
  });

  useEffect(() => {
    if (character && isAuthenticated && user) {
      setIsLiked(character.character?.likes?.includes(user._id) ?? false);
    }
  }, [character, isAuthenticated, user]);

  const toggleLike = () => {
    if (isAuthenticated) {
      likeCharacterMutation.mutate({ id: characterId });
    }
  };

  return {
    isLiked,
    isLoading: likeCharacterMutation.isPending,
    likesCount: character?.character?.upvotes ?? 0,
    toggleLike,
  };
}

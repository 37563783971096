import { Box, Spacer, Text } from "@chakra-ui/react";

import { withSuspense } from "~/ui/hoc/withSuspense";
import MarketingLayout from "~/ui/layouts/Marketing.layout";

type PrivacyPolicyPageProps = {
  hideLayout?: boolean;
};

const H2 = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize="xl" fontWeight="bold" margin="32px 0 16px">
    {children}
  </Text>
);

const PrivacyPage = ({ hideLayout = false }: PrivacyPolicyPageProps) => {
  const Content = (
    <Box pb={8}>
      <Spacer h={2} />
      <Text fontSize="2xl" fontWeight="bold" margin="32px 0 16px">
        Privacy Policy
      </Text>

      <Text marginBottom="16px">
        Welcome to Charry! This Privacy Policy outlines how Use Open Rates,
        LLC., sometimes doing business as Charry, and its affiliated entities
        and subsidiaries (collectively "Open Rates," "Charry" "we", "us" or
        "our") collects, uses, discloses, and otherwise processes personal data
        (as defined below) in connection with our website https://charry.ai (the
        "Site"), and related content, services, products, and other
        functionalities offered on or through our services (collectively, the
        "Services"). This Privacy Policy does not create a contractual
        relationship or any other legal obligations. Further, this Privacy
        Policy does not address our internal privacy practices relating to
        Charry or its employees, contractors or other personnel.
      </Text>

      <Text marginBottom="16px">
        In certain situations, Charry may provide certain services to other
        businesses and may collect your personal data on behalf of our business
        customers who collect data from you "Customer Data". In the course of
        providing services for other businesses, we may collect and process
        Customer Data on behalf of our business customers subject to
        restrictions set forth in our agreements with those businesses. This
        Privacy Policy does not apply to such processing, and we recommend you
        read the Privacy Policy of the respective customer if their processing
        concerns your personal data.
      </Text>

      <H2>1. WHAT IS PERSONAL DATA?</H2>
      <Text marginBottom="16px">
        When we use the term "personal data" in this Privacy Policy, we mean any
        data or information that identifies, relates to, describes, is capable
        of being associated with, or could reasonably be linked, directly or
        indirectly, with a particular natural person or household or any other
        data or information that constitutes "personal data", "personal
        information," or "personally identifiable information."
      </Text>

      <H2>2. HOW WE COLLECT PERSONAL DATA</H2>
      <h3>Personal Data Collected from You</h3>
      <Text marginBottom="16px">
        The categories of information we collect that are submitted to us by
        individuals through the Services can include:
      </Text>

      <Text marginBottom="16px">
        <strong>Account Information</strong>, including first and last name,
        company name, business email address, user ID and password, and any
        other information you provide to us. We use this information to
        administer your account, provide you with the relevant Services and
        information, communicate with you regarding your account and your use of
        the Services, and for customer support purposes. We also utilize
        third-party providers to process payments on our behalf and do not
        accept payment directly through our Services.
      </Text>
      <Text marginBottom="16px">
        <strong>Payment Information.</strong> If you sign up for one of our
        Services that requires you to pay us, we collect the information
        provided in connection with such payment. Please note that we use third
        party payment processors, including Stripe, to process credit card
        payments made to us. As such, we do not retain any personally
        identifiable financial information in connection with credit card
        payments, such as credit card numbers. Rather, all such information is
        provided directly by you to our third-party processor. The payment
        processors' use of your personal data is governed by their own Privacy
        Policy.
      </Text>
      <Text marginBottom="16px">
        <strong>Inquiry and Communications Information</strong>, such as your
        name, company name, or email address, and including information provided
        in custom messages sent through the forms, our "Contact Us" feature, in
        chat messages, to one of our email addresses, or via phone. This also
        includes contact information provided on our Services. We use this
        information to investigate and respond to your inquiries, and to
        communicate with you, to enhance the Services, including debugging, and
        to manage our business operations.
      </Text>
      <Text marginBottom="16px">
        <strong>Newsletter, Marketing Emails, and Blog Information</strong>,
        including email address and applicable interests and communication
        preferences. We use this information to manage our communications with
        you and send you information about products and services we think may be
        of interest to you. If you wish to stop receiving email messages from
        us, simply click the "unsubscribe link" provided at the bottom of the
        email communication. Note that you cannot unsubscribe from certain
        services-related email communications (e.g., account verification,
        confirmations of transactions, technical or legal notices).
      </Text>
      <Text marginBottom="16px">
        <strong>Information Collected Through the Use of the Service</strong>,
        including any files, documents, videos, images, data, or information you
        choose to upload or transmit through your communications with us or your
        use of the Site (collectively, "User Content"). User Content and any
        information contained in the User Content, including personal data you
        may have included, is stored and collected as part of the Service. We
        use the User Content to provide you with the Service.
      </Text>
      <Text marginBottom="16px">
        <strong>Feedback Information.</strong> We may also collect feedback and
        ratings you provide relating to our services or products. We use this
        information to communicate with you, to conduct market research, inform
        our marketing and advertising activities and improve and grow our
        business.
      </Text>
      <Text marginBottom="16px">
        <strong>Business Representative Contact Information.</strong> If you are
        a business representative, we collect your information in connection
        with the performance of the agreement or potential agreement with us.
        This information may include your first name, last name, company contact
        information (e.g. email, phone, address), job title, and any other
        information related to the performance of the agreement with us.
      </Text>
      <Text marginBottom="16px">
        <strong>Employment Application Information</strong>, including your
        contact and demographic information, educational and work history,
        employment interests, information obtained during interviews and any
        other information you choose to provide, if you apply for employment.
      </Text>

      <h3>Personal Data from Third Parties</h3>
      <Text marginBottom="16px">
        We also obtain personal data from third parties; which we often combine
        with personal data we collect either automatically or directly from an
        individual.
      </Text>

      <Text marginBottom="16px">
        We may receive the same categories of personal data as described above
        from the following third parties:
      </Text>

      <Text marginBottom="16px">
        <strong>
          Information we receive from authentication services you connect to our
          Service:
        </strong>{" "}
        Some parts of our Services may allow you to login through a third party
        authentication service such as Google. These services will authenticate
        your identity and provide you the option to share certain personal data
        with us, which could include your [name, email address, address book and
        contacts, or other information]. The data we receive is dependent on
        that third party's policies and your privacy settings on that
        third-party site. We use this information to operate, maintain, and
        provide to you the features and functionality of the Service. We may
        also send you service-related emails or messages (e.g., account
        verification, purchase confirmation, customer support, changes, or
        updates to features of the Site, technical and security notices).
      </Text>
      <Text marginBottom="16px">
        <strong>Your Employer / Company:</strong> If you interact with our
        Services through your employer or company including using the Okta
        Enterprise Login feature, we may receive your information from your
        employer or company, including another representative of your employer
        or company. We use this information to operate, maintain, and provide to
        you the features and functionality of the Service.
      </Text>
      <Text marginBottom="16px">
        <strong>Data Providers:</strong> To provide our direct mail marketing
        services, we may purchase or receive personal data, such as name and
        mailing addresses, from data providers and third parties. We use this
        information to send Charry mailings with information on new products and
        services offered by Charry or our clients. We may combine this
        information with other information we collect from or about you. In
        these cases, our Privacy Policy governs the handling of the combined
        information.
      </Text>
      <Text marginBottom="16px">
        <strong>Service Providers:</strong> Our service providers that perform
        services solely on our behalf, such as survey and marketing providers
        and payment processors, collect personal data and often share some or
        all of this information with us. The information may include contact
        information, demographic information, payment and purchase history
        information, information about your communications and related
        activities, and information about your orders. We may use this
        information to administer and facilitate our services, your orders, and
        our marketing activities.
      </Text>
      <Text marginBottom="16px">
        <strong>Business Partners:</strong> We may receive your information from
        our business partners, such as companies that offer their products
        and/or services on our Services. We may use this information to
        administer and facilitate our services, your orders, and our marketing
        activities.
      </Text>
      <Text marginBottom="16px">
        <strong>Other Sources:</strong> In addition to third-party data
        providers, we may also collect personal data about individuals that we
        do not otherwise have from, for example, publicly available sources, or
        through transactions such as mergers and acquisitions. We use this
        information to operate, maintain, and provide to you the features and
        functionality of the Service, as well as to communicate directly with
        you, such as to send you email messages about products and services that
        may be of interest to you.
      </Text>

      <Text marginBottom="16px">
        Through the provision of our Services, we may also process deidentified
        information that cannot reasonably be used to infer information about,
        or otherwise be linked to, a particular consumer or household. Charry
        will maintain and use the information in deidentified form and will not
        attempt to reidentify the information, except in instances where
        necessary for determining whether the deidentification process used by
        Charry satisfies the requirements under applicable law.
      </Text>

      <H2>3. HOW WE USE PERSONAL DATA</H2>
      <Text marginBottom="16px">We may use personal data we collect to:</Text>

      <Text marginBottom="16px">
        To provide you with products or services that you request or in which
        you express interest;
      </Text>
      <Text marginBottom="16px">To communicate with you, via email;</Text>
      <Text marginBottom="16px">
        To customize the advertising and content you see;
      </Text>
      <Text marginBottom="16px">
        For marketing and advertising purposes, including to market to you or
        offer you through email, direct mail, phone or text message, information
        and updates on products or services we think you may be interested in
        (where applicable, we may send you marketing messages if you have given
        us your consent to do so or where we have relied on the soft opt-in
        rule);
      </Text>
      <Text marginBottom="16px">Process payment for our Services;</Text>
      <Text marginBottom="16px">
        To respond to your emails, comments, questions, or requests for client
        services;
      </Text>
      <Text marginBottom="16px">
        To improve and customize our Services to address the needs and interests
        of our user base and other individuals we interact with;
      </Text>
      <Text marginBottom="16px">
        To test, enhance, update and monitor the Services, or diagnose or fix
        technology problems;
      </Text>
      <Text marginBottom="16px">
        To enforce our Terms of Service, to resolve disputes, to carry out our
        obligations and enforce our rights, and to protect our business
        interests and the interests and rights of third parties;
      </Text>
      <Text marginBottom="16px">
        To prevent, investigate or provide notice of fraud or unlawful or
        criminal activity;
      </Text>
      <Text marginBottom="16px">
        For any other purpose we may describe when you provide the information;
        and
      </Text>
      <Text marginBottom="16px">
        For any other lawful purpose, or other purposes that you consent to.
      </Text>

      <h3>
        How We Use Cookies and Other Tracking Technology To Collect Information
      </h3>
      <Text marginBottom="16px">
        We, and our third-party partners, automatically collect information you
        provide to us and information about how you access and use the Services
        when you visit our services, read our emails, or otherwise engage with
        us. We typically collect this information through a variety of tracking
        technologies, including (i) cookies or small data files that are stored
        on an individual's computer and (ii) other, related technologies, such
        as web beacons, pixels, embedded scripts, mobile SDKs,
        location-identifying technologies and logging technologies
        (collectively, "tracking technologies") and we may use third-party
        partners or technologies to collect this information. Information we
        collect automatically about you may be combined with other personal data
        we collect directly from you or receive from other sources.
      </Text>

      <Text marginBottom="16px">
        We, and our third-party partners, use tracking technologies to
        automatically collect usage and device information, such as:
      </Text>

      <Text marginBottom="16px">
        Information the computer, tablet, smartphone or other device you use,
        such as your IP address, browser type, Internet service provider, device
        type/model/manufacturer, operating system, date and time stamp, and a
        unique ID that allows us to uniquely identify your browser, mobile
        device, or your account (including, for example, a persistent device
        identifier or an Ad ID), and other such information. We may also work
        with third-party partners to employ technologies, including the
        application of statistical modeling tools, which permit us to recognize
        and contact you across multiple devices.
      </Text>
      <Text marginBottom="16px">
        Information about the way you access and use our services, for example,
        the site from which you came and the site to which you are going when
        you leave our services, how frequently you access the Service, whether
        you open emails or click the links contained in emails, whether you
        access the services from multiple devices, and other browsing behavior
        and actions you take on the Sites.
      </Text>
      <Text marginBottom="16px">
        Information about how you use the Services, such as the pages you visit,
        the links you click, the ads you view and click on, videos you watch,
        and other similar actions. We may also use third-party tools to collect
        information you provide to us or information about how you use the
        Services and may record your mouse movements, scrolling, clicks and
        keystroke activity on the Services and other browsing, search or
        purchasing behavior.
      </Text>
      <Text marginBottom="16px">
        Information about your location, such as general geographic location
        that we or our third-party providers may derive from your IP address.
      </Text>
      <Text marginBottom="16px">
        Analytics information. We may collect analytics data or use third-party
        analytics tools such as Google Analytics to help us measure traffic and
        usage trends for the services and to understand more about the
        demographics of our users. You can learn more about Google's practices
        at http://www.google.com/policies/privacy/partners and view its opt-out
        options at https://tools.google.com/dlpage/gaoptout.
      </Text>

      <Text marginBottom="16px">
        All of the information collected automatically through these tools
        allows us to improve your customer experience. For example, we may use
        this information to enhance and personalize your user experience, to
        monitor and improve our Sites and Services, and to improve the
        effectiveness of our Service, offers, advertising, communications and
        customer service. We may also use the data collected through tracking
        technologies to: (a) remember information so that you will not have to
        re-enter it during your visit or the next time you visit the site; (b)
        provide custom, personalized content and information, including targeted
        content and advertising; (c) identify you across multiple devices; (d)
        provide and monitor the effectiveness of our services; (e) monitor
        aggregate metrics such as total number of visitors, traffic, usage, and
        demographic patterns on our website; (f) diagnose or fix technology
        problems; and (g) otherwise to plan for and enhance our services.
      </Text>

      <Text marginBottom="16px">
        If you would prefer not to accept cookies, most browsers will allow you
        to: (i) change your browser settings to notify you when you receive a
        cookie, which lets you choose whether or not to accept it; (ii) disable
        existing cookies; or (iii) set your browser to automatically reject
        cookies; however, doing so may negatively impact your experience using
        the services, as some features and services may not work properly. You
        may also set your email options to prevent the automatic downloading of
        images that may contain technologies that would allow us to know whether
        you have accessed our email and performed certain functions with it.
      </Text>

      <Text marginBottom="16px">
        We and our third-party partners may also use cookies and tracking
        technologies for advertising purposes. For more information about
        tracking technologies, please see Third-Party Data Collection and Online
        Advertising below.
      </Text>

      <H2>4. OUR DISCLOSURE OF PERSONAL DATA</H2>
      <Text marginBottom="16px">
        We do not share or sell your data in any form. We do not use your data
        to train AI models. We only use your data to provide our AI inference
        services and to debug issues that may arise from this process.
      </Text>

      <Text marginBottom="16px">
        We may also share, transmit, disclose, grant access to, make available,
        and provide personal data with and to third parties, as follows:
      </Text>

      <Text marginBottom="16px">
        <strong>Charry Entities:</strong> We may share personal data with other
        companies owned or controlled by Charry, and other companies owned by or
        under common ownership as Charry, which also includes our subsidiaries
        (i.e., any organization we own or control) or our ultimate holding
        company (i.e., any organization that owns or controls us) and any
        subsidiaries it owns, particularly when we collaborate in providing the
        Services.
      </Text>
      <Text marginBottom="16px">
        <strong>Your Employer / Company:</strong> If you interact with our
        Services through your employer or company, we may disclose your
        information to your employer or company, including another
        representative of your employer or company.
      </Text>
      <Text marginBottom="16px">
        <strong>Customer Service and Communication Providers:</strong> We share
        personal data with third parties who assist us in providing our customer
        services and facilitating our communications with individuals that
        submit inquiries.
      </Text>
      <Text marginBottom="16px">
        <strong>Other Service Providers:</strong> In addition to the third
        parties identified above, we engage other third-party service providers
        that perform business or operational services for us or on our behalf,
        such as website hosting, infrastructure provisioning, IT services,
        analytics services, employment application-related services, payment
        processing services, and administrative services.
      </Text>
      <Text marginBottom="16px">
        <strong>Ad Networks and Advertising Partners:</strong> We work with
        third-party ad networks and advertising partners to deliver advertising
        and personalized content on our Services, on other websites and
        services, and across other devices. These parties may collect
        information directly from a browser or device when an individual visits
        our Services through cookies or other data collection technologies. This
        information is used to provide and inform targeted advertising, as well
        as to provide advertising-related services such as reporting,
        attribution, analytics and market research.
      </Text>
      <Text marginBottom="16px">
        <strong>Business Partners:</strong> From time to time, we may share
        personal data with our business partners at your direction or we may
        allow our business partners to collect your personal data. Our business
        partners will use your information for their own business and commercial
        purposes, including to send you any information about their products or
        services that we believe will be of interest to you.
      </Text>
      <Text marginBottom="16px">
        <strong>Business Transaction or Reorganization:</strong> We may take
        part in or be involved with a corporate business transaction, such as a
        merger, acquisition, joint venture, or financing or sale of company
        assets. We may disclose personal data to a third-party during
        negotiation of, in connection with or as an asset in such a corporate
        business transaction. Personal information may also be disclosed in the
        event of insolvency, bankruptcy or receivership.
      </Text>
      <Text marginBottom="16px">
        <strong>Legal Obligations and Rights:</strong> We may disclose personal
        data to third parties, such as legal advisors and law enforcement:
        <Text marginBottom="16px">
          in connection with the establishment, exercise, or defense of legal
          claims;
        </Text>
        <Text marginBottom="16px">
          to comply with laws or to respond to lawful requests and legal
          process;
        </Text>
        <Text marginBottom="16px">
          to protect our rights and property and the rights and property of
          others, including to enforce our agreements and policies;
        </Text>
        <Text marginBottom="16px">to detect, suppress, or prevent fraud;</Text>
        <Text marginBottom="16px">
          to protect the health and safety of us and others; or
        </Text>
        <Text marginBottom="16px">
          as otherwise required by applicable law.
        </Text>
      </Text>
      <Text marginBottom="16px">
        <strong>With Your Consent:</strong> We may disclose personal data about
        an individual to certain other third parties or publicly with their
        consent or direction. For example, with an individual's consent or
        direction we may post their testimonial on our Sites or service-related
        publications.
      </Text>

      <H2>5. THIRD-PARTY DATA COLLECTION AND ONLINE ADVERTISING</H2>
      <Text marginBottom="16px">
        We may participate in interest-based advertising and use third party
        advertising companies to serve you targeted advertisements based on your
        browsing history. We permit third party online advertising networks,
        social media companies and other third-party services, to collect
        information about your use of our online services over time so that they
        may play or display ads on our Services, on other websites or services
        you may use, and on other devices you may use. Typically, though not
        always, the information used for interest-based advertising is collected
        through tracking technologies, such as cookies, web beacons, embedded
        scripts, location-identifying technologies, and similar technology,
        which recognize the device you are using and collect information,
        including click stream information, browser type, time and date you
        visited the Sites, AdID, precise geolocation and other information. We
        may share a common account identifier (such as a hashed email address or
        user ID) with our third-party advertising partners to help identify you
        across devices. We and our third-party partners use this information to
        make the advertisements you see online more relevant to your interests,
        as well as to provide advertising-related services such as reporting,
        attribution, analytics and market research. We may also use services
        provided by third parties (such as social media platforms) to serve
        targeted ads to you and others on such platforms. We may do this by
        providing a hashed version of your email address or other information to
        the platform provider.
      </Text>

      <Text marginBottom="16px">We may engage in the following:</Text>

      <Text marginBottom="16px">
        <strong>Social Media Platforms.</strong> We may display targeted
        advertising to you through social media platforms, such as X, Instagram,
        LinkedIn, Facebook, TikTok, and other social media forums. These
        companies have interest-based advertising programs that allow us to
        direct advertisements to users who have shown interest in our services
        while those users are on the social media platform, or to groups of
        other users who share similar traits, such as likely commercial
        interests and demographics. We may share a unique identifier, such as a
        user ID or hashed email address, with these platform providers or they
        may collect information from our website visitors through a first-party
        pixel, in order to direct targeted advertising to you or to a custom
        audience on the social media platform. These advertisements are governed
        by the privacy policies of those social media companies that provide
        them. If you do not want to receive targeted ads on your social
        networks, you may be able to adjust your advertising preferences through
        your settings on those networks.
      </Text>
      <Text marginBottom="16px">
        <strong>Third Party Partners.</strong> We work with a variety of
        third-party partners to provide advertising services. For example, we
        may also utilize certain forms of display advertising and other advanced
        features through Google Analytics. These features enable us to use
        first-party cookies (such as the Google Analytics cookie) and
        third-party cookies (such as the DoubleClick advertising cookie) or
        other third-party cookies together to inform, optimize, and display ads
        based on your past visits to the Services. You may control your
        advertising preferences or opt-out of certain Google advertising
        products by visiting the Google Ads Preferences Manager, currently
        available at https://google.com/ads/preferences, or by visiting NAI's
        online resources at http://www.networkadvertising.org/choices.
      </Text>

      <Text marginBottom="16px">
        Please see the Online Ads section of the Control Over Your Information
        section below to learn how you can opt out of interest-based
        advertising.
      </Text>

      <H2>6. CONTROL OVER YOUR INFORMATION</H2>
      <Text marginBottom="16px">
        You may control your information in the following ways:
      </Text>

      <Text marginBottom="16px">
        <strong>Email Communications Preferences.</strong> From time to time, we
        may also send you emails regarding updates to our Service, products or
        services, notices about our organization, or information about
        products/services that we think may be of interest to you. If you wish
        to unsubscribe from such emails, simply click the "unsubscribe link"
        provided at the bottom of the email communication. Note that you cannot
        unsubscribe from certain services-related email communications (e.g.,
        account verification, confirmations of transactions, technical or legal
        notices).
      </Text>
      <Text marginBottom="16px">
        <strong>Modifying or Deleting Your Information.</strong> If you have any
        questions about reviewing, modifying, or deleting your information, you
        can contact us directly using the information provided in the Contact Us
        section, below. We will review your request and may require you to
        provide additional information to identify yourself. We may not be able
        to modify or delete your information in all circumstances.
      </Text>
      <Text marginBottom="16px">
        <strong>Online Ads.</strong> To learn more about interest-based
        advertising and how you may be able to opt-out of some of this
        advertising, you may wish to visit the Digital Advertising Alliance's
        (DAA) resources and/or the Network Advertising Initiative's (NAI) online
        resources, at www.aboutads.info/choices or
        http://www.networkadvertising.org/choices. You may also be able to limit
        interest-based advertising through the settings menu on your mobile
        device by selecting "limit ad tracking" (iOS) or "opt-out of
        interest-based ads" (Android). You may also be able to opt-out of some,
        but not all, interest-based advertising served by mobile ad networks by
        visiting http://youradchoices.com/appchoices and downloading the mobile
        AppChoices app.
      </Text>

      <Text marginBottom="16px">
        Please note that when you opt out of receiving interest-based
        advertisements, this does not mean you will no longer see advertisements
        from us or on our online services. It means that the online ads that you
        do see from DAA program participants should not be based on your
        interests. We are not responsible for the effectiveness of, or
        compliance with, any third-parties' opt-out options or programs or the
        accuracy of their statements regarding their programs. In addition,
        third parties may still use cookies to collect information about your
        use of our online services, including for analytics and fraud prevention
        as well as any other purpose permitted under the DAA's Principles.
      </Text>

      <H2>7. CHILDREN'S PERSONAL DATA</H2>
      <Text marginBottom="16px">
        Our Services are not directed to, and we do not intend to, or knowingly,
        collect or solicit personal data from children under the age of 13. If
        an individual is under the age of 13, they should not use our Services
        or otherwise provide us with any personal data either directly or by
        other means. If a child under the age of 13 has provided personal data
        to us, we encourage the child's parent or guardian to contact us to
        request that we remove the personal data from our systems.
      </Text>

      <Text marginBottom="16px">
        If we learn that any personal data we have collected has been provided
        by a child under the age of 16, we will promptly delete that personal
        data.
      </Text>

      <H2>8. LINKS TO THIRD-PARTY WEBSITES AND SERVICES</H2>
      <Text marginBottom="16px">
        Our Services may include links to third-party websites, plug-ins and
        applications that we do not own or operate. We are not responsible for
        the practices employed by any websites or services linked to or from the
        services, including the information or content contained within them.
        Our Privacy Policy does not apply to your browsing or other interactions
        on any other website or service. Activities on third party websites or
        services are subject to that applicable third party's rules and
        policies, not ours. If you are using a third-party website or service,
        you do so at your own risk. We encourage you to review the privacy
        policies of any site or service before providing any personal data.
      </Text>

      <H2>9. CHANGES TO THIS PRIVACY POLICY</H2>
      <Text marginBottom="16px">
        We reserve the right to change this Privacy Policy from time to time at
        our sole discretion. We will notify you about material changes in the
        way we treat personal data by sending a notice to the primary email
        address specified in your Charry account and/or by placing a prominent
        notice on our website. It is your responsibility to review this Privacy
        Policy periodically. When we do change the Privacy Policy, we will also
        revise the "Last Updated" date.
      </Text>

      <H2>10. CONTACT US</H2>
      <Text marginBottom="16px">
        If you have any questions regarding this Privacy Policy, or any of our
        services, please send an email to help@charry.ai.
      </Text>

      <Text marginBottom="16px">Last Modified: 11/13/2024</Text>
    </Box>
  );

  if (hideLayout) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        overflow="auto"
        width="100%"
      >
        <Box
          maxWidth="100%"
          paddingBottom={24}
          paddingLeft={12}
          paddingRight={12}
          width={{ md: "1000px", sm: "100%" }}
        >
          {Content}
        </Box>
      </Box>
    );
  }

  return (
    <MarketingLayout>
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        overflow="auto"
        width="100%"
      >
        <Box
          maxWidth="100%"
          paddingBottom={32}
          px={4}
          width={{ md: "1000px", sm: "100%" }}
        >
          {Content}
        </Box>
      </Box>
    </MarketingLayout>
  );
};

export default withSuspense(PrivacyPage);

import type { UseMutationResult } from "@tanstack/react-query";
import * as React from "react";
import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

export enum ConfirmModalTypes {
  Success = "success",
  Warning = "warning",
  Danger = "danger",
}

export type ConfirmModalProps = {
  title: string;
  message: React.ReactNode | string;
  confirmText?: string;
  cancelText?: string;
  confirm: (() => Promise<void>) | (() => void);
  cancel: () => void;
  type: ConfirmModalTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation?: UseMutationResult<any, any, any>;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  cancel,
  cancelText = " Cancel",
  confirm,
  confirmText = " Confirm",
  message,
  mutation,
  title,
}) => {
  return (
    <ModalContent backgroundColor="gray.1000">
      <ModalHeader>
        <Flex align="center" justify="space-between">
          <Text>{title}</Text>
          <ModalCloseButton />
        </Flex>
      </ModalHeader>
      <ModalBody>
        <Stack spacing="8">
          <Box
            bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
            borderRadius={{ base: "none", sm: "xl" }}
            px={{ base: "4", sm: "0" }}
            py={{ base: "0", sm: "4" }}
          >
            <Stack spacing="6">
              {typeof message === "string" ? (
                <Text color="white" variant="16-reg">
                  {message}
                </Text>
              ) : (
                message
              )}
              {mutation?.error && (
                <Text align="center" color="red.500">
                  {mutation.error?.message}
                </Text>
              )}
              <Stack direction="row" justifyContent="flex-end" spacing="2">
                <Button
                  onClick={() => {
                    cancel();
                  }}
                  size="sm"
                  variant="secondary"
                >
                  {cancelText}
                </Button>
                <Button
                  isLoading={mutation?.isPending}
                  onClick={async () => {
                    await confirm();
                  }}
                  size="sm"
                  variant="primary"
                >
                  {confirmText}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
};

import React from "react";
import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import CharryLogo from "~/assets/charry.svg";
import { DISCORD_INVITE_LINK, TWITTER_LINK } from "~/lib/const";

type FooterProps = unknown;

const Footer: React.FC<FooterProps> = () => {
  const categories = [
    {
      links: [
        {
          link: DISCORD_INVITE_LINK,
          title: "Discord",
        },
        {
          link: TWITTER_LINK,
          title: "Twitter",
        },
      ],
      title: "Connect",
    },
    {
      links: [
        {
          link: "/login",
          title: "Login",
        },
        {
          link: "/register",
          title: "Register",
        },
        {
          link: "/privacy-policy",
          title: "Privacy Policy",
        },
        {
          link: "/terms",
          title: "Terms of Service",
        },
      ],
      title: "Product",
    },
  ];

  return (
    <Flex
      backgroundColor="gray.1000"
      borderColor="gray.800"
      borderTop="1px solid"
      justifyContent="center"
      position="relative"
      width="100%"
    >
      <Box padding="48px 16px" position="relative" width="1000px">
        <Grid gap={8} templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Link to="/">
              <Image alt="Charry Logo" src={CharryLogo} width="48px" />
            </Link>
          </GridItem>
          {categories.map((category, index) => {
            return (
              <GridItem key={index}>
                <Text variant="16-semi">{category.title}</Text>
                <Box height="16px" />
                {category.links.map(({ link, title }, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Link to={link}>
                        <Text
                          _hover={{
                            color: "white",
                            cursor: "pointer",
                            transition: "0.1s",
                          }}
                          color="gray.300"
                          key={index}
                          variant="14-reg"
                        >
                          {title}
                        </Text>
                      </Link>
                      <Box height="16px" />
                    </React.Fragment>
                  );
                })}
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    </Flex>
  );
};

export default Footer;

import { z } from "zod";

import { authedServiceRequest, serviceResponse } from "./BaseService";
import { TurnSchema } from "./turn.models";

export const ChatVisibilitySchema = z.enum(["Default", "Archived"]);

export const ChatSchema = z.object({
  characterId: z.string(),
  createdAt: z.date(),
  id: z.string(),
  turns: z.array(TurnSchema),
  updatedAt: z.date(),
  userId: z.string(),
  visibility: ChatVisibilitySchema,
});

export type Chat = z.infer<typeof ChatSchema>;

/** ******************************************************************************
 *  Chat Service
 ******************************************************************************* */
export type ChatService = {
  create(request: CreateChatRequest): Promise<CreateChatResponse>;
  get(request: GetChatRequest): Promise<GetChatResponse>;
  latest(request: LatestChatRequest): Promise<LatestChatResponse>;
  list(request: ListChatsRequest): Promise<ListChatsResponse>;
  archive(request: ArchiveChatRequest): Promise<ArchiveChatResponse>;
  count(request: CountChatsRequest): Promise<CountChatsResponse>;
};

/** ******************************************************************************
 *  Create Chat
 ******************************************************************************* */

export const createChatParams = z.object({
  characterId: z.string(),
});

export const createChatRequest = authedServiceRequest.merge(
  z.object({
    params: createChatParams,
  }),
);

export const createChatResponse = serviceResponse.merge(
  z.object({
    chat: ChatSchema.optional(),
  }),
);

export type CreateChatParams = z.infer<typeof createChatParams>;
export type CreateChatRequest = z.infer<typeof createChatRequest>;
export type CreateChatResponse = z.infer<typeof createChatResponse>;

/** ******************************************************************************
 *  Get Chat
 ******************************************************************************* */

export const getChatParams = z.object({
  id: z.string(),
});

export const getChatRequest = authedServiceRequest.merge(
  z.object({
    params: getChatParams,
  }),
);

export const getChatResponse = serviceResponse.merge(
  z.object({
    chat: ChatSchema.nullable().optional(),
  }),
);

export type GetChatParams = z.infer<typeof getChatParams>;
export type GetChatRequest = z.infer<typeof getChatRequest>;
export type GetChatResponse = z.infer<typeof getChatResponse>;

/** ******************************************************************************
 *  Latest Chat
 ******************************************************************************* */

export const latestChatParams = z.object({
  characterId: z.string(),
});

export const latestChatRequest = authedServiceRequest.merge(
  z.object({
    params: latestChatParams,
  }),
);

export const latestChatResponse = serviceResponse.merge(
  z.object({
    chat: ChatSchema.nullable().optional(),
  }),
);

export type LatestChatParams = z.infer<typeof latestChatParams>;
export type LatestChatRequest = z.infer<typeof latestChatRequest>;
export type LatestChatResponse = z.infer<typeof latestChatResponse>;

/** ******************************************************************************
 *  List Chats
 ******************************************************************************* */

export const listChatsParams = z.undefined();

export const listChatsRequest = authedServiceRequest.merge(
  z.object({
    params: listChatsParams,
  }),
);

export const listChatsResponse = serviceResponse.merge(
  z.object({
    chats: z.array(ChatSchema).optional(),
  }),
);

export type ListChatsParams = z.infer<typeof listChatsParams>;
export type ListChatsRequest = z.infer<typeof listChatsRequest>;
export type ListChatsResponse = z.infer<typeof listChatsResponse>;

/** ******************************************************************************
 *  Count Chats
 ******************************************************************************* */

export const countChatsParams = z.object({
  characterId: z.string(),
});

export const countChatsRequest = authedServiceRequest.merge(
  z.object({
    params: countChatsParams,
  }),
);

export const countChatsResponse = serviceResponse.merge(
  z.object({
    count: z.number().optional(),
  }),
);

export type CountChatsParams = z.infer<typeof countChatsParams>;
export type CountChatsRequest = z.infer<typeof countChatsRequest>;
export type CountChatsResponse = z.infer<typeof countChatsResponse>;

/** ******************************************************************************
 *  Archive Chat
 ******************************************************************************* */

export const archiveChatParams = z.object({
  id: z.string(),
});

export const archiveChatRequest = authedServiceRequest.merge(
  z.object({
    params: archiveChatParams,
  }),
);

export const archiveChatResponse = serviceResponse.merge(
  z.object({
    chat: ChatSchema.optional(),
  }),
);

export type ArchiveChatParams = z.infer<typeof archiveChatParams>;
export type ArchiveChatRequest = z.infer<typeof archiveChatRequest>;
export type ArchiveChatResponse = z.infer<typeof archiveChatResponse>;

import { z } from "zod";

import {
  CharacterSchema_CAI,
  ChatDataSchemaV2_CAI,
  HistorySchemaV2_CAI,
} from "../character-ai";
import {
  authedServiceRequest,
  serviceRequest,
  serviceResponse,
} from "./BaseService";

export const PromptsSchema = z.object({
  phrases: z.array(z.unknown()),
});

export const CharacterVisibilitySchema = z.enum([
  "Public",
  "Unlisted",
  "Private",
  "Archived",
]);

export const CharacterCategoryEnum = z.enum([
  "Helpers",
  "Anime Game Characters",
  "Games",
  "Anime",
  "Game Characters",
  "Books",
  "Comedy",
  "Image Generating",
  "Celebrities",
  "Vtuber",
  "Language Learning",
  "Discussion",
  "History",
  "Religion",
  "Animals",
  "Philosophy",
  "Politics",
  "Other",
]);

export const CharacterSchema = z.object({
  category: CharacterCategoryEnum.nullable(),
  copyable: z.boolean(),
  createdAt: z.date(),
  definition: z.string().nullable(),
  description: z.string().nullable(),
  greeting: z.string(),
  id: z.string(),
  imageUrl: z.string().nullable(),
  likes: z.array(z.string()).optional(),
  name: z.string(),
  nsfw: z.boolean(),
  tags: z.array(z.string()).nullable(),
  title: z.string().nullable(),
  turnCount: z.number().default(0),
  updatedAt: z.date(),
  upvotes: z.number(),
  userId: z.string(),
  visibility: CharacterVisibilitySchema,
});

export const MAX_CHARACTERS = 30;

export type Character = z.infer<typeof CharacterSchema>;
export type CharacterVisibility = z.infer<typeof CharacterVisibilitySchema>;

/** ******************************************************************************
 *  Character Service
 ******************************************************************************* */
export type CharacterService = {
  create(request: CreateCharacterRequest): Promise<CreateCharacterResponse>;
  update(request: UpdateCharacterRequest): Promise<UpdateCharacterResponse>;
  like(request: LikeCharacterRequest): Promise<LikeCharacterResponse>;
  get(request: GetCharacterRequest): Promise<GetCharacterResponse>;
  list(request: ListCharactersRequest): Promise<ListCharactersResponse>;
  incrementTurnCount(
    request: IncrementTurnCountRequest,
  ): Promise<IncrementTurnCountResponse>;
  explore(
    request: ExploreCharactersRequest,
  ): Promise<ExploreCharactersResponse>;
  count(request: CountCharactersRequest): Promise<CountCharactersResponse>;
  archive(request: ArchiveCharacterRequest): Promise<ArchiveCharacterResponse>;
  backfill(
    request: BackfillCharacterRequest,
  ): Promise<BackfillCharacterResponse>;
};

/** ******************************************************************************
 *  Create Character
 ******************************************************************************* */

export const createCharacterParams = z.object({
  category: CharacterCategoryEnum,
  copyable: z.boolean(),
  definition: z.string().nullable(),
  description: z.string().nullable(),
  greeting: z.string(),
  imageUrl: z.string().nullable(),
  name: z.string(),
  nsfw: z.boolean(),
  tags: z.array(z.string()).nullable(),
  title: z.string().nullable(),
  visibility: CharacterVisibilitySchema,
});

export const createCharacterRequest = authedServiceRequest.merge(
  z.object({
    params: createCharacterParams,
  }),
);

export const createCharacterResponse = serviceResponse.merge(
  z.object({
    character: CharacterSchema.optional(),
  }),
);

export type CreateCharacterParams = z.infer<typeof createCharacterParams>;
export type CreateCharacterRequest = z.infer<typeof createCharacterRequest>;
export type CreateCharacterResponse = z.infer<typeof createCharacterResponse>;

/** ******************************************************************************
 *  Update Character
 ******************************************************************************* */

export const updateCharacterParams = z.object({
  copyable: z.boolean(),
  definition: z.string().nullable(),
  description: z.string().nullable(),
  greeting: z.string(),
  id: z.string(),
  imageUrl: z.string().nullable(),
  name: z.string(),
  nsfw: z.boolean().optional(),
  tags: z.array(z.string()).nullable(),
  title: z.string().nullable(),
  visibility: CharacterVisibilitySchema,
});

export const updateCharacterRequest = authedServiceRequest.merge(
  z.object({
    params: updateCharacterParams,
  }),
);

export const updateCharacterResponse = serviceResponse.merge(
  z.object({
    character: CharacterSchema.nullable().optional(),
  }),
);

export type UpdateCharacterParams = z.infer<typeof updateCharacterParams>;
export type UpdateCharacterRequest = z.infer<typeof updateCharacterRequest>;
export type UpdateCharacterResponse = z.infer<typeof updateCharacterResponse>;

/** ******************************************************************************
 *  Like Character
 ******************************************************************************* */

export const likeCharacterParams = z.object({
  id: z.string(),
});

export const likeCharacterRequest = authedServiceRequest.merge(
  z.object({
    params: likeCharacterParams,
  }),
);

export const likeCharacterResponse = serviceResponse.merge(
  z.object({
    character: CharacterSchema.optional(),
  }),
);

export type LikeCharacterParams = z.infer<typeof likeCharacterParams>;
export type LikeCharacterRequest = z.infer<typeof likeCharacterRequest>;
export type LikeCharacterResponse = z.infer<typeof likeCharacterResponse>;

/** ******************************************************************************


/** ******************************************************************************
 *  Get Character
 ******************************************************************************* */

export const getCharacterParams = z.object({
  id: z.string().optional(),
});
export const getCharacterRequest = serviceRequest.merge(
  z.object({
    params: getCharacterParams,
  }),
);

export const getCharacterResponse = serviceResponse.merge(
  z.object({
    character: CharacterSchema.nullable().optional(),
  }),
);

export type GetCharacterParams = z.infer<typeof getCharacterParams>;
export type GetCharacterRequest = z.infer<typeof getCharacterRequest>;
export type GetCharacterResponse = z.infer<typeof getCharacterResponse>;

/** ******************************************************************************
 *  Explore Characters
 ******************************************************************************* */

export const exploreCharactersParams = z.object({}).optional();

export const exploreCharactersRequest = serviceRequest.merge(
  z.object({
    params: exploreCharactersParams,
  }),
);

export const exploreCharactersResponse = serviceResponse.merge(
  z.object({
    characters: z.array(CharacterSchema).optional(),
  }),
);

export type ExploreCharactersParams = z.infer<typeof exploreCharactersParams>;
export type ExploreCharactersRequest = z.infer<typeof exploreCharactersRequest>;
export type ExploreCharactersResponse = z.infer<
  typeof exploreCharactersResponse
>;

/** ******************************************************************************
 *  List Characters
 ******************************************************************************* */

export const listCharactersParams = z.object({
  userId: z.string().optional(),
});

export const listCharactersRequest = authedServiceRequest.merge(
  z.object({
    params: listCharactersParams,
  }),
);

export const listCharactersResponse = serviceResponse.merge(
  z.object({
    characters: z.array(CharacterSchema).optional(),
  }),
);

export type ListCharactersParams = z.infer<typeof listCharactersParams>;
export type ListCharactersRequest = z.infer<typeof listCharactersRequest>;
export type ListCharactersResponse = z.infer<typeof listCharactersResponse>;

/** ******************************************************************************
 *  Count Characters
 ******************************************************************************* */

export const countCharactersParams = z.undefined();

export const countCharactersRequest = authedServiceRequest.merge(
  z.object({
    params: countCharactersParams,
  }),
);

export const countCharactersResponse = serviceResponse.merge(
  z.object({
    count: z.number().optional(),
  }),
);

export type CountCharactersParams = z.infer<typeof countCharactersParams>;
export type CountCharactersRequest = z.infer<typeof countCharactersRequest>;
export type CountCharactersResponse = z.infer<typeof countCharactersResponse>;

/** ******************************************************************************
 *  Archive Character
 ******************************************************************************* */

export const archiveCharacterParams = z.object({
  id: z.string(),
});

export const archiveCharacterRequest = authedServiceRequest.merge(
  z.object({
    params: archiveCharacterParams,
  }),
);

export const archiveCharacterResponse = serviceResponse.merge(
  z.object({
    character: CharacterSchema.optional(),
  }),
);

export type ArchiveCharacterParams = z.infer<typeof archiveCharacterParams>;
export type ArchiveCharacterRequest = z.infer<typeof archiveCharacterRequest>;
export type ArchiveCharacterResponse = z.infer<typeof archiveCharacterResponse>;

/** ******************************************************************************
 *  Create Character
 ******************************************************************************* */

export const backfillCharacterParams = z.object({
  data: z.object({
    character: CharacterSchema_CAI,
    chats: z.array(
      z.object({
        chat: ChatDataSchemaV2_CAI,
        history: HistorySchemaV2_CAI,
      }),
    ),
  }),
});

export const backfillCharacterRequest = authedServiceRequest.merge(
  z.object({
    params: backfillCharacterParams,
  }),
);

export const backfillCharacterResponse = serviceResponse.merge(
  z.object({
    character: CharacterSchema.optional(),
  }),
);

export type BackfillCharacterParams = z.infer<typeof backfillCharacterParams>;
export type BackfillCharacterRequest = z.infer<typeof backfillCharacterRequest>;
export type BackfillCharacterResponse = z.infer<
  typeof backfillCharacterResponse
>;

/** ******************************************************************************
 *  Increment Turn Count
 ******************************************************************************* */

export const incrementTurnCountParams = z.object({
  characterId: z.string(),
  increment: z.number(),
});

export const incrementTurnCountRequest = authedServiceRequest.merge(
  z.object({
    params: incrementTurnCountParams,
  }),
);

export const incrementTurnCountResponse = serviceResponse.merge(z.object({}));

export type IncrementTurnCountParams = z.infer<typeof incrementTurnCountParams>;
export type IncrementTurnCountRequest = z.infer<
  typeof incrementTurnCountRequest
>;
export type IncrementTurnCountResponse = z.infer<
  typeof incrementTurnCountResponse
>;

import React from "react";

export const withSuspense = <Props extends object>(
  WrappedComponent: React.FC<Props>,
  FallbackComponent: React.ReactNode | null = null,
) => {
  return (props: Props) => {
    return (
      <React.Suspense fallback={FallbackComponent}>
        <WrappedComponent {...props} />
      </React.Suspense>
    );
  };
};

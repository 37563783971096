import React, { useCallback } from "react";
import { Box, Button, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import useCharacter from "~/ui/hooks/useCharacter.hook";
import useModal from "~/ui/hooks/useModal.hook";
import { ConfirmModalTypes } from "../../modal/Confirm.modal";
import { ModalTypes } from "../../modal/Modal";
import CharacterDefinition from "./CharacterDefinition";
import CharacterDescription from "./CharacterDescription";
import CharacterGreeting from "./CharacterGreeting";
import CharacterImage from "./CharacterImage";
import CharacterName from "./CharacterName";
import CharacterTitle from "./CharacterTitle";
import CharacterVisibility from "./CharacterVisibility";
import UpdateCharacterMenu from "./UpdateCharacterMenu";

type EditCharacterProps = {
  characterId: string;
};

const EditCharacter: React.FC<EditCharacterProps> = ({ characterId }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { pop, push } = useModal();
  const { archiveCharacter, setUpdateCharacterParams, updateCharacterParams } =
    useCharacter(characterId);
  const [errors] = React.useState<Record<string, object>>({});
  const [showMore, setShowMore] = React.useState(false);

  const archive = useCallback(async () => {
    try {
      await archiveCharacter.mutateAsync({
        id: characterId,
      });

      navigate(`/dashboard/characters`);
    } catch (e) {
      console.error(e);
      toast({
        description:
          "Something went wrong. Please try again later or contact support.",
        duration: 5000,
        isClosable: true,
        status: "error",
        title: "Error Archiving Character",
      });
    }
  }, [archiveCharacter, characterId, navigate, toast]);

  /** Render */
  return (
    <Box display="flex" justifyContent="center" paddingTop="24px">
      <Box width="600px">
        <CharacterName
          errors={errors}
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
        />
        <Flex height="24px" />
        <CharacterImage
          errors={errors}
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
        />
        <Flex height="24px" />
        <CharacterTitle
          errors={errors}
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
        />
        <Flex height="24px" />
        <CharacterDescription
          errors={errors}
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
        />
        <Flex height="24px" />
        <CharacterGreeting
          errors={errors}
          params={updateCharacterParams}
          setParams={setUpdateCharacterParams}
        />
        {showMore ? (
          <>
            <Flex height="24px" />
            <CharacterDefinition
              errors={errors}
              params={updateCharacterParams}
              setParams={setUpdateCharacterParams}
            />
            <Flex height="24px" />
            <CharacterVisibility
              errors={errors}
              params={updateCharacterParams}
              setParams={setUpdateCharacterParams}
            />
          </>
        ) : (
          <>
            <Flex height="16px" />
            <Button
              onClick={() => setShowMore(true)}
              rightIcon={<FiChevronDown />}
              size="xs"
              variant="secondary"
            >
              Show more options
            </Button>
          </>
        )}
        <Flex height="24px" />
        <UpdateCharacterMenu characterId={characterId} />

        <Box margin="32px 0">
          <Divider backgroundColor="gray.800" height="1px" />
        </Box>
        <Text color="white" variant="18-reg">
          Archive Character
        </Text>
        <Box height="8px" />
        <Text color="gray.400" variant="14-reg">
          If you wish to archive this character, you can do so by clicking the
          button below. This action can only be reversed by contacting support.
        </Text>
        <Box height="16px" />
        <Button
          isLoading={archiveCharacter.isPending}
          onClick={() => {
            push({
              props: {
                cancel: () => {
                  pop();
                },
                confirm: async () => {
                  await archive();
                  pop();
                },
                message: `You are about to archive this character. Are you sure you want to continue?`,
                title: `Archive Character`,
                type: ConfirmModalTypes.Warning,
              },
              type: ModalTypes.Confirm,
            });
          }}
          size="sm"
          variant="error"
          width="fit-content"
        >
          Archive this character
        </Button>
        <Box height="300px" />
      </Box>
    </Box>
  );
};

export default EditCharacter;

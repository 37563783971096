import type { TextareaProps as ChakraTextareaProps } from "@chakra-ui/react";
import React from "react";
import { Textarea as ChakraTextarea, FormControl } from "@chakra-ui/react";

import Label from "./Label";

export type TextareaProps = ChakraTextareaProps & {
  label?: string;
  height?: string;
  raw?: boolean;
  optional?: boolean;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { label, optional = false, raw, ...rest } = props;
    const Textarea = (
      <ChakraTextarea
        _focusVisible={{
          borderColor: "gray.500",
        }}
        _hover={{
          borderColor: "gray.600",
        }}
        _placeholder={{
          color: "gray.600",
        }}
        backgroundColor="gray.1100"
        border="1px solid"
        borderColor="gray.700"
        color="white"
        ref={ref}
        {...rest}
      />
    );

    if (raw) {
      return <>{Textarea}</>;
    }

    return (
      <FormControl width={props.width}>
        {label ? <Label optional={optional}>{label}</Label> : null}
        {Textarea}
      </FormControl>
    );
  },
);

Textarea.displayName = "Textarea";

export default Textarea;
